export function fGetPromoSetting(wh, item) {
  let promo = [];
  switch(wh) {
    case 'Outlet Bundusan - PPSB':
      promo = {
        "start": item.promo_start_bundusan,
        "end": item.promo_end_bundusan,
        "rate": item.promo_rate_bundusan,
        "type": item.rate_bundusan_is_currency,
        "point": item.bundusan_no_point,
        "apply": item.apply_member_rate_for_bundusan
      }
      return promo;

    case 'Outlet Luyang - PPSB':
      promo = {
        "start": item.promo_start_luyang,
        "end": item.promo_end_luyang,
        "rate": item.promo_rate_luyang,
        "type": item.rate_luyang_is_currency,
        "point": item.luyang_no_point,
        "apply": item.apply_member_rate_for_luyang
      }
      return promo;

    case 'Outlet Keningau - PK9F':
      promo = {
        "start": item.promo_start_keningau,
        "end": item.promo_end_keningau,
        "rate": item.promo_rate_keningau,
        "type": item.rate_keningau_is_currency,
        "point": item.keningau_no_point,
        "apply": item.apply_member_rate_for_keningau
      }
      return promo;

    case 'Outlet Kudat - PK9F':
      promo = {
        "start": item.promo_start_kudat,
        "end": item.promo_end_kudat,
        "rate": item.promo_rate_kudat,
        "type": item.rate_kudat_is_currency,
        "point": item.kudat_no_point,
        "apply": item.apply_member_rate_for_kudat
      }
      return promo;

    case 'Outlet Tawau - PK9F':
      promo = {
        "start": item.promo_start_tawau,
        "end": item.promo_end_tawau,
        "rate": item.promo_rate_tawau,
        "type": item.rate_tawau_is_currency,
        "point": item.tawau_no_point,
        "apply": item.apply_member_rate_for_tawau
      }
      return promo;

    default:
      promo = {
        "start": null,
        "end": null,
        "rate": 0,
        "type": 0,
        "point": 0,
        "apply": 1
      }
      return promo;
  }
}

export function fGetStore(outlet) {
  switch(outlet) {
    case 'Bundusan': return 'Outlet Bundusan - PPSB';
    case 'Luyang': return 'Outlet Luyang - PPSB';
    case 'Kudat': return 'Outlet Kudat - PK9F';
    case 'Tawau': return 'Outlet Taway - PK9F';
    case 'Keningau': return 'Outlet Keningau - PK9F';
    default: return 'Outlet Bundusan - PPSB';
  }
}
