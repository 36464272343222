/**
* @NOTE useful functions to be used acrosss app
*/

export function fAppAPI() {
	return 'https://erpnext.paragonpetsgroup.net/';
	/*
  let url = window.location.href;
	switch(url) {
			case 'http://localhost:3000/': return 'http://localhost:8080/';
			case 'http://pos.paragonpetsgroup.com/': return 'https://erpnext.tapblet.app/';
			case 'http://paragonpets.tapblet.com/': return 'https://erpnext.tapblet.app/';
			default: return 'http://localhost:8080/';
	}
	*/
}

export function fPosNew() {
	return 'http://pos.paragonpetsgroup.com';
	//return 'https://erpnext.paragonpetsgroup.net/';
	/*
  let url = window.location.href;
	switch(url) {
			case 'http://localhost:3000/': return 'http://localhost:3000/';
			case 'http://pos.tapblet.com/': return 'http://pos.tapblet.com/';
			case 'http://paragonpets.tapblet.com/': return 'http://paragonpets.tapblet.com/';
			default: return 'http://localhost:3000/';
	}
	*/
}

export function fRandCode(){
  var code = '';
  var seed = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (var i = 0; i < 8; i++)
    code += seed.charAt(Math.floor(Math.random() * seed.length));
    //console.log('appfunction @ fRandCode -> ' + code);
    return code;
}
