const appAPI = ''

export function fGetReport(route) {
	let api = route;
	console.log('calling api', api)
	return fetch(api, {
		method:'GET',
		credentials:'include'
	})
	.then((resp) => resp.json())
	.then((resj) => {
		return resj.data
	})
	.catch((err) => {
		console.log('api error')
		console.log(err)
		return -1
	})
}
