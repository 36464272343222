import React, { Component } from 'react';
import { webAPI } from './library/appconst.js';
import './App.css';

//------------------------------------------------------------------------------

export default class StockReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appFlow: 'loading',
			outlet: null,
      items: [],
			bin: [],
			report: [],
			product: null,
      stockcard: [],
      filtered:true,
      instock:0,
      outstock:0
    }
  }

	componentWillReceiveProps() {
		let product = this.props.product
    let outlet = this.props.outlet
		this.setState({
			product: product,
      outlet: outlet
		},function(){
			console.log('prop received');
			console.log(this.state.product);
		})
	}

  componentDidMount() {
    this.setState({
      items: this.props.items,
			bin: this.props.bin,
			product: this.props.product,
			outlet: this.props.outlet
    },function(){
			console.log('outlet is: ' + this.state.outlet);
			this.generateReport()
		})
  }

  setFiltered() {
    if(this.state.filtered === true) {
      this.setState({filtered:false},function(){
        document.getElementById('filterCheckbox').checked=false;
      })
    }
    else {
      this.setState({filtered:true},function(){
        document.getElementById('filterCheckbox').checked=true;
      })
    }
  }

	getRate(outlet, index) {
		let bin = this.state.bin;
		let item = this.state.items;
		switch(outlet) {
			case 'Bundusan': return item[index].outlet_bundusan;
			case 'Luyang': return item[index].outlet_luyang;
			case 'Keningau': return item[index].outlet_keningau;
			case 'Kudat': return item[index].outlet_kudat;
			case 'Tawau': return item[index].outlet_tawau;
			default: return null;
		}
	}

	getItemRate(code) {
		let item = this.state.items;
		let outlet = this.state.outlet;
		let index = item.findIndex(p => p.name === code);
		if(index !== -1) {
			switch(outlet) {
				case 'Bundusan': return item[index].outlet_bundusan;
				case 'Luyang': return item[index].outlet_luyang;
				case 'Keningau': return item[index].outlet_keningau;
				case 'Kudat': return item[index].outlet_kudat;
				case 'Tawau': return item[index].outlet_tawau;
				default: return null;
			}
		}
	}

	generateReport() {
		let data = [];
		let items = this.state.items;
		let bin = this.state.bin;
		let product = this.state.product;
		let outlet = this.state.outlet;

		if(product !== undefined && product !== null) {
			let i = bin.findIndex(b => b.item_code === product.value);
			let rate = this.getItemRate(product.value);
			if(rate !== undefined) {
				rate = rate.toFixed(2);
			}
			else {
				rate = 0.00;
			}
			this.setState({
				report: [{
						code: bin[i].item_code,
						name: product.label,
						rate: rate,
						qty: bin[i].actual_qty,
				}]
			},function(){
        /*
				this.setState({
					appFlow: 'default'
				})
        */
        this.fetchStockCard()
			})
		}
		else {
			for(let b = 0; b<bin.length; b++) {
				for(let i = 0; i<items.length; i++) {
					if(items[i].name === bin[b].item_code) {
						let rate = this.getRate(outlet, i);
						if(rate !== undefined) {
							rate = rate.toFixed(2);
						}
						else {
							rate = 0.00;
						}
						data.push({
							code: bin[b].item_code,
							name: items[i].item_name,
							rate: rate,
							qty: bin[b].actual_qty
						})
					}
				}
			}
			this.setState({
				report: data,
				appFlow: 'default'
			})
		}
	}

  mapItems() {
    if(this.state.appFlow === 'default') {
			if(this.state.report.length > 0) {
				return this.state.report.map( (item, index) => {
	        return (
	          <tr key={index}>
	            <td>{index + 1}</td>
	            <td>{item.code}</td>
							<td>{item.name}</td>
							<td align="right">{item.rate}</td>
							<td align="right">{item.qty}</td>
	          </tr>
	        )
	      })
			}
			else {
				return (
					<tr>
						<td colSpan="4">No Products Found</td>
					</tr>
				)
			}
    }
  }

  //----------------------------------------------------------------------------

  fetchStockCard() {
    let api = webAPI + 'method=stockcard&name='+this.state.product.value
    return fetch(api)
    .then((resp) => resp.json())
    .then((resj) => {
      this.setState({
        stockcard:resj.data
      },function(){
        this.setState({
          appFlow:'default'
        },function(){
          document.getElementById("filterCheckbox").checked = true;
        })
      })
    })
  }

  renderStockMovement() {
    let stockcard = this.state.stockcard;
    let outlet = this.state.outlet;
    let instock = this.state.instock;
    let outstock = this.state.outstock;

    console.log('outlet is: '+this.state.outlet);
    console.log('filter is: '+this.state.filtered)

    if(stockcard.length > 0) {
      return stockcard.map((item, index) => {
        let datetime = item.date + ' ' + item.time;
        if(this.state.filtered === true) {
          if(item.outlet === this.state.outlet) {
            return (
              <tr key={index}>
                <td>{index+1}</td>
                <td>{datetime}</td>
                <td>{item.type}</td>
                <td>{item.purpose}</td>
                <td>{item.invoice}</td>
                <td>{item.qty}</td>
                <td>{item.outlet}</td>
              </tr>
            );
          }
        }
        else {
          return (
            <tr key={index}>
              <td>{index+1}</td>
              <td>{datetime}</td>
              <td>{item.type}</td>
              <td>{item.purpose}</td>
              <td>{item.invoice}</td>
              <td>{item.qty}</td>
              <td>{item.outlet}</td>
            </tr>
          );
        }
      })
    }
    else {
      return (
        <tr>
          <td colSpan="6">
            No stock movement found.
          </td>
        </tr>
      );
    }
  }

  renderStockCard() {
    let product = this.state.product;
    if(product !== undefined && product !== null) {
      return (
        <div>
          <h4>Stock Card</h4>
          <input
            id="filterCheckbox"
            type="checkbox"
            onClick={()=>this.setFiltered()}
            /> Show stock movement for this outlet only: <strong>{this.state.outlet}</strong>
          <hr/>
          <table className="table table-striped">
          <thead>
          <tr>
            <td><label>#</label></td>
            <td><label>Date Time</label></td>
            <td><label>Type</label></td>
            <td><label>Purpose</label></td>
            <td><label>Invoice</label></td>
            <td><label>Qty</label></td>
            <td><label>Outlet</label></td>
          </tr>
          </thead>
          <tbody>
          {this.renderStockMovement()}
          </tbody>
          </table>
          <div>
            Total In: <strong>{this.state.in}</strong><br/>
            Total Out: <strong>{this.state.out}</strong><br/>
          </div>
        </div>
      );
    }
  }

	//----------------------------------------------------------------------------

  render() {
    switch(this.state.appFlow) {
      case 'default':
        return (
          <div id="app-pos-modal">
            <div className="receipt">

              <table className="table table-striped">
              <thead>
              <tr>
                <td><label>#</label></td>
                <td><label>Code</label></td>
								<td><label>Name</label></td>
								<td align="right"><label>Rate</label></td>
                <td align="right"><label>Qty</label></td>
              </tr>
              </thead>
              <tbody>
              	{this.mapItems()}
							</tbody>
							</table>
              {this.renderStockCard()}
            </div>
          </div>
      	)

	    case 'loading':
	      return (
	        <div id="app-pos-modal">
	          <div align="center">
	            Getting Stock Balances...
	            <i className="fa fa-circle-o-notch fa-spin fa-fw app-spinner-color"></i>
	          </div>
	        </div>
	      )

	    default:
	      return (
	        <div id="app-pos-modal">
	          <div align="center">
	            ERROR: Unable to generate report. Please check your internet connection.
	          </div>
	        </div>
	      )
    }
  }
}
