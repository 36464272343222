import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import moment from 'moment';
import { fAppAPI } from './library/appfunction.js';
import './App.css';

//------------------------------------------------------------------------------

export default class PrintReceipt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appFlow: 'loading',
      items: [],
      payments: [],
      dataInv: [],
      returnInv: [],
      invoice: '',
      fetched: false,
      company: '',
      address: '',
      paid: 0.00,
      total: 0.00,
      change: 0.00,
      date: '',
      time: '',
      cashier: '',
			salesperson_name:'',
			customer: '',
      outlet: '',
      taxrate: 0,
      taxcharge: 0.00,
      isReturn: false,
      returned: false,

      // credit card, cheque, e-payment
      cc_acode: undefined,
      cc_holder: undefined,
      cc_type: undefined,
      cc_expiry: undefined,
      cc_no: undefined,
      cc_mcode: undefined,
      cc_mid: undefined,
      chqno: undefined,
      epcode: undefined,
      eptype: undefined,
			returnMsg: '',

      // return
      selectedReturnType:null,
      returntype:null,
      acc_cash:'',
      acc_cc:'',
      acc_ep:''
    }

    this.handleRetTypeSelect = this.handleRetTypeSelect.bind(this);
  }

  handleRetTypeSelect = (selectedReturnType) => {
    if(selectedReturnType) {
      let type = `${selectedReturnType.value}`;
      this.setState({
        selectedReturnType
      },function(){
        this.setState({
          returntype: type
        },function(){
          console.log('return type')
          console.log(this.state.returntype)
        })
      })
    }
    console.log('account for change amount?')
    console.log(this.state.dataInv.account_for_change_amount)
  }

  getTaxCharge(total, rate) {
    let net = (total/100)*rate;
    return net.toFixed(2);
  }

  componentDidMount() {
    //console.log('@PrintReceipt @componentDidMount exec')
		//let inv = 'SINV-00074';

		let inv = this.props.inv;
    let cashier = this.props.cashier;
		let customer = this.props.customer;
    let outlet = this.props.outlet;
    let taxrate = this.props.tax;
    this.setState({
      isReturn: this.props.isReturn
    })

		//console.log(this.props.customer)

    //console.log('getting transaction history for key: ' + inv)
    //console.log('tax rate: ' + taxrate)

    let param = inv;
    let filter = '';
    let api = fAppAPI() + 'api/resource/Sales Invoice/' + param + filter

    return fetch(api, {
      method: 'GET',
      credentials: 'include'
    })
    .then((res) => res.json())
    .then((resj) => {
      //console.log('data fetched!')
      //console.log('invoice fetched')
      //console.log(resj.data)

			let salesperson = resj.data.sales_person
			if(salesperson === '' || salesperson === null) {
				salesperson = 'Not Available'
			}

      this.setState({
        dataInv: resj.data,
        company: resj.data.company,
        address: this.props.address,
        change: resj.data.change_amount.toFixed(2),
        paid: resj.data.paid_amount.toFixed(2),
        total: resj.data.grand_total.toFixed(2),
        items: resj.data.items,
        payments: resj.data.payments,
        date: resj.data.posting_date,
        creation: resj.data.creation,
        cashier: resj.data.cashier,
				salesperson_name: salesperson,
				customer: resj.data.customer,
        outlet: outlet,
        invoice: inv,
        taxrate: taxrate,
        taxcharge: this.getTaxCharge(resj.data.grand_total, taxrate),
        cc_acode: resj.data.approval_code,
        cc_holder: resj.data.card_holder,
        cc_type: resj.data.card_type,
        cc_expiry: resj.data.expiry_date,
        cc_no: resj.data.credit_card_number,
        cc_mcode: resj.data.merchant_code,
        cc_mid: resj.data.merchant_id,
        chqno: resj.data.cheque_number,
        eptype: resj.data.e_payment_type,
        epcode: resj.data.e_payment_code
      },function() {
				if(this.state.items.length === 1) {
					let returns = this.state.returnInv;
					let items = this.state.items;
					let index = 0;
					let newRetEntry = [
						...returns, {
								name: items[index].name,
								item_name: items[index].item_name,
								item_code: items[index].item_code,
								warehouse: items[index].warehouse,
								cost_center: items[index].cost_center,
								index: index,
								qty: 1,
								stock_qty: 1,
								base_amount: items[index].base_amount,
								base_net_amount: items[index].base_net_amount,
								amount: items[index].amount,
								base_rate: items[index].base_rate,
								base_net_rate: items[index].base_net_rate,
								net_rate: items[index].net_rate,
								rate: items[index].rate
					}];

					this.setState({
						returnInv: newRetEntry
					})
				}
        this.setState({
          fetched: true,
          appFlow: 'default'
        })
    	})
    })
    .catch((error) => {
      this.setState({
        appFlow: 'error'
      })
      console.log('API returned an error')
      console.log(error)
    })
  }

  componentWillReceiveProps(props) {
    const {isReturn} = this.props;
    if(props.isReturn === false) {
			this.setState({
				isReturn: false,
				returnInv: []
			})
			//this.props.toggleUtilBtn;
    }
    else if(props.isReturn === true){
      this.setState({
				isReturn: true,
				returnInv: []
			})
			//this.props.toggleUtilBtn;
    }
    else {
      this.setState({
				isReturn: false,
				returnInv: []
			})
			//this.props.toggleUtilBtn;
    }
  }

  //--------------------------------------------------------------------------

  execReturn() {
    if(this.state.returnInv.length > 0 && this.state.returntype !== null) {
      this.setState({appFlow:'submit'})
      // transform items in returnInv to negative
      // prepare a var for the transform
			let items = this.state.items;
      let reti = [];
      let retp = [];
      let invi = this.state.returnInv;
      let invd = this.state.dataInv;
      let retype = this.state.returntype;
			//console.log('@execReturn -> returnInvi state');
			//console.log(invi);
      //let invp = this.state.payments[0];

      let gt = [];
      let paid = 0.00;

      for(let i=0;i<invi.length;i++) {
        invi[i].qty              = -Math.abs(invi[i].qty);
        invi[i].stock_qty        = -Math.abs(invi[i].stock_qty);

        invi[i].base_net_rate    = Number(invi[i].base_net_rate);
        invi[i].net_rate         = Number(invi[i].net_rate);
        invi[i].base_rate        = Number(invi[i].base_rate);
        invi[i].rate             = Number(invi[i].rate);

        invi[i].base_amount      = -Math.abs(Number(invi[i].rate*invi[i].qty));
        invi[i].base_net_amount  = -Math.abs(Number(invi[i].rate*invi[i].qty));
        invi[i].amount           = -Math.abs(Number(invi[i].rate*invi[i].qty));
        invi[i].net_amount       = -Math.abs(Number(invi[i].rate*invi[i].qty));

        delete invi[i]['index'];
        gt.push(invi[i].amount);
        reti.push(invi[i]);
      }

      if(gt.length > 0) {
        let add = (a,b) => a + b;
        paid = gt.reduce(add);
      }

      if(retype === 'Cash') {
        retp = [
          {
            "mode_of_payment": "Cash",
            "base_amount": Number(paid.toFixed(2)),
            "amount": Number(paid.toFixed(2)),
            "account": invd.account_for_change_amount
          }
        ];
      }
      else if(retype === 'Credit Card') {
        retp = [
          {
            "mode_of_payment": "Credit Card",
            "base_amount": Number(paid.toFixed(2)),
            "amount": Number(paid.toFixed(2)),
            "account": this.props.acc_cc
          }
        ];
      }
      else if(retype === 'E-Payment') {
        retp = [
          {
            "mode_of_payment": "E-Payment",
            "base_amount": Number(paid.toFixed(2)),
            "amount": Number(paid.toFixed(2)),
            "account": this.props.acc_ep
          }
        ];
      }

      // start prep return invoice data
      invd.base_grand_total = paid.toFixed(2);
      invd.base_net_total = paid.toFixed(2);
      invd.base_total = paid.toFixed(2);
      invd.base_paid_amount = paid.toFixed(2);

      invd.grand_total = paid.toFixed(2);
      invd.net_total = paid.toFixed(2);
      invd.total = paid.toFixed(2);
      invd.paid_amount = paid.toFixed(2);

      let data = {
        "docstatus":1,
        "is_pos":1,
        "is_return":1,
        "return_against": this.props.inv,
        "submit_on_creation":1,
        "project": invd.project,
        "update_stock":1,
        "debit_to":invd.debit_to,
        "customer": invd.customer,
        "base_total":Number(invd.base_total),
        "base_grand_total":Number(invd.grand_total),
        "base_paid_amount":Number(invd.base_paid_amount),
        "base_change_amount":0,//Number(invd.base_change_amount),
        "total":Number(invd.total),
        "grand_total":Number(invd.grand_total),
        "paid_amount":Number(invd.paid_amount),
        "change_amount":0,//Number(invd.change_amount),
        "write_off_amount":Number(invd.write_off_amount),
        "items": reti,
        "payments":retp,
        "approval_code": invd.approval_code,
        "merchant_code": invd.merchant_code,
        "merchant_id": invd.merchant_id,
        "card_holder": invd.card_holder,
        "card_type": invd.card_type,
        "credit_card_number": invd.credit_card_number,
        "expiry_date": invd.expiry_date,
        "cheque_number": invd.cheque_number,
        "e_payment_code": invd.e_payment_code,
        "company": invd.company,
        "account_for_change_amount": invd.account_for_change_amount,
        "againts_income_account": invd.against_income_account,
        "posting_date": moment().format('YYYY-MM-DD'),
        "cashier": invd.cashier,
				"sales_person": invd.sales_person
      }

      console.log('return invoice ready');
      console.log(data);
      console.log('submitting...');

			let retData = JSON.stringify(data);

			// @response
			// 500 fatal
			// 407 already returned
			// 200 OK

      let api = fAppAPI() + 'api/resource/Sales%20Invoice/?data=' + retData;
      return fetch( api, {
        method: 'POST',
        credentials: 'include'
      })
			.then((response) => {
				if(response.status === 200) {
					this.updateMemberPointsOnReturn(invd.customer, invd.paid_amount);
          this.setState({
            appFlow: 'success',
						returnInv: []
          })
				}
				else {
					//console.log(response.status)
					//this.execReturnFail(response.status)
					this.setState({
						returnMsg: "ERROR: Unable to return. It may already returned. Check your internet connection too.",
						appFlow: 'fail',
						returnInv: []
					})
				}
			})
			.catch((error) => {
				this.setState({
					returnMsg: "ERROR: Unable to return. It may already returned. Check your internet connection too.",
					appFlow: 'fail',
					returnInv: []
				})
			})
    }
    else {
      alert('Return Type not selected OR no checked item to return is set.');
      console.log('nothing to return');
    }
  }

	updateMemberPointsOnReturn(name, tominus) {
		console.log('@updateMemberPointsOnReturn exec');
		// get the user current points from the localStorage
		let customerPoint = 0;
		let customerGroup = 'None';
		let customers = JSON.parse(localStorage.getItem('erpnext_customer'));
		let customerIndex = customers.findIndex(c => c.name === name)
		if(customerIndex !== -1) {
			if(customers[customerIndex].name !== 'Classic Member' && customers[customerIndex].name !== 'Gold Member' && customers[customerIndex].name !== 'Platinum Member') {
				customerPoint = customers[customerIndex].reward_points;
				customerGroup = customers[customerIndex].customer_group;

				if(customerGroup !== 'Walk In' && customerGroup !== 'Standard' && customerGroup !== 'Dealer') {
					let newPoints = Number(customerPoint) + Number(tominus);
					newPoints = Math.round(Number(newPoints));
					let param = {
						'reward_points': newPoints
					}

					//console.log('updating member: ' + name);
					//console.log('new reward points: ' + newPoints);

					// begin update
					let api = fAppAPI() + 'api/resource/Customer/' + name + '?data=' + JSON.stringify(param);
					fetch( api, {
						method: 'PUT',
						credentials: 'include'
					})
					.then((resp) => resp.json())
					.then((resj) => {
						let i = customerIndex;
						let newEntry = {
              customer_group: resj.data.customer_group,
              customer_name: customers[i].customer_name,
              image: customers[i].image,
              mobile_number: resj.data.mobile_number,
              name: customers[i].name,
              nric: resj.data.nric,
              reward_points: resj.data.reward_points,
              territory: customers[i].territory
						}
						customers.push(newEntry);
						customers.splice(customerIndex, 1);
						localStorage.setItem('erpnext_customer', JSON.stringify(customers));
						//this.setSelectCustomer(this.state.customersearchopt);
					})
					.catch((error) => {
						console.log('error @updateMemberPointsOnReturn');
						console.log(error);
						this.setState({ appFlow: 'fail' })
					})
				}
				else {
					console.log('no points return made');
				}
			}
			else {
				console.log('no points return made');
			}
		}
	}

  //--------------------------------------------------------------------------

  mapItems() {
		//console.log(this.state.items)
    if(this.state.fetched === true) {
      return this.state.items.map( (item, index) => {
        return (
					<React.Fragment key={index}>
          <tr>
            <td className="item-w"><strong>{item.item_code}</strong></td>
            <td className="qty-w">{item.qty}</td>
						<td className="total-w">{item.net_rate.toFixed(2)}</td>
            <td className="total-w">{item.net_amount.toFixed(2)}</td>
          </tr>
					<tr>
						<td colSpan="4" className="item-n">
							<span>{item.item_name}</span>
						</td>
					</tr>
					</React.Fragment>
        )
      })
    }
  }

  setCheckbox(netrate, itemname) {
    if(netrate > 0.00) {
      return (
        <input
          type="checkbox"
          className="ret-checkbox"
          defaultChecked={false}
          onChange={()=>this.onChangeRetItem(itemname)}
        />
      )
    }
  }

  mapItemsForReturn() {
		//console.log(this.state.items)
    if(this.state.fetched === true) {
      return this.state.items.map( (item, index) => {
        //console.log('isReturn state is: ' + this.state.isReturn)
        let cbid = "cb_" + index;
        return (
					<React.Fragment key={index}>
          <tr>
            <td className="item-w">
              {this.setCheckbox(item.net_rate, item.name)}
              {item.item_code}
            </td>
            <td className="qtyr-w">
              <span style={{marginRight:5}}><label>-</label></span>
              <input
                type="text"
                value={this.getRetQty(item.name)}
                className="ret-input"
                onChange={this.onChangeRetQty(item.name)}
              />
              <span className="ret-base"> / {item.qty}</span>
            </td>
						<td className="total-w">{item.net_rate.toFixed(2)}</td>
            <td className="total-w">{item.net_amount.toFixed(2)}</td>
          </tr>
					<tr>
						<td colSpan="4" className="item-n">
							<span>{item.item_name}</span>
						</td>
					</tr>
					</React.Fragment>
        )
      });
    }
  }

  onChangeRetItem(name) {
    let items = this.state.items;
    let returns = this.state.returnInv;
		if(returns.length === 0) {
			let i = items.findIndex(item => item.name === name);
			let newReturnEntry = [
				...returns, {
					name: items[i].name,
					item_name: items[i].item_name,
					item_code: items[i].item_code,
					warehouse: items[i].warehouse,
					cost_center: items[i].cost_center,
					qty: 1,
					stock_qty: 1,
					base_amount: items[i].base_amount,
					base_net_amount: items[i].base_net_amount,
					amount: items[i].amount,
					base_rate: items[i].base_rate,
					base_net_rate: items[i].base_net_rate,
					net_rate: items[i].net_rate,
					rate: items[i].rate
				}
			]
			this.setState({
				returnInv: newReturnEntry
			},function(){
				console.log('new return entry CREATED');
				console.log(this.state.returnInv)
			})
		}
		else {
			let removeIndex = returns.findIndex(r => r.name === name)
			if(removeIndex === -1) {
				let i = items.findIndex(item => item.name === name);
				let addReturnEntry = [
					...returns, {
						name: items[i].name,
						item_name: items[i].item_name,
						item_code: items[i].item_code,
						warehouse: items[i].warehouse,
						cost_center: items[i].cost_center,
						qty: 1,
						stock_qty: 1,
						base_amount: items[i].base_amount,
						base_net_amount: items[i].base_net_amount,
						amount: items[i].amount,
						base_rate: items[i].base_rate,
						base_net_rate: items[i].base_net_rate,
						net_rate: items[i].net_rate,
						rate: items[i].rate
					}
				]
				this.setState({
					returnInv: addReturnEntry
				},function(){
					console.log('ADDED return entry');
					console.log(this.state.returnInv)
				})
			}
			else {
				console.log('REMOVING item index: ' + returns[removeIndex].name + ' removed!');
				returns.splice(removeIndex, 1);
				this.setState({ returnInv: returns }, function(){
					console.log('updated returnInv state')
					console.log(this.state.returnInv)
				});
			}
		}
  }

  onChangeRetQty = name => (event) => {
    //let items = this.state.items;
    let value = event.target.value;
    if(!isNaN(value)) {

      let returns = this.state.returnInv;
			let items = this.state.items;

      let ri = returns.findIndex(r => r.name === name);
			let ii = items.findIndex(i => i.name === name);

      if(ri !== -1) {
				if(value <= items[ii].qty) {
					returns[ri] = {...returns[ri], qty: value, stock_qty: Number(value)}
	        this.setState({
	          returns
	        },function() {
						//console.log('@onChangeRetQty -> returnInv state updated: ')
						//console.log(this.state.returnInv)
					});
				}
				else {
					return items[ii].qty
				}

      }
    }
  }

  getRetQty(name) {
		let returns = this.state.returnInv;
		let i = returns.findIndex(r => r.name === name);
		if(i === -1) {
			return 1;
		}
		else {
			return returns[i].qty;
		}
  }

  getPaymentsData(mode) {
    let payments = this.state.payments;
    //console.log('mode of payments');
    //console.log(payments);

    let i = payments.findIndex( p => p.mode_of_payment === mode )
    if( i !== -1 ) {
      switch(mode) {
        case 'Cash':
          return (
            <tr>
              <td colSpan="3" className="pay-mode-head">
                {mode}<br/>
                Change
              </td>
              <td className="pay-mode-tail">
                {payments[i].amount.toFixed(2)}<br/>
                {this.state.change}
              </td>
            </tr>
          );

        case 'Credit Card':
          return (
            <tr>
              <td colSpan="3" className="pay-mode-head">
                {mode}<br/>
                Approval Code: {this.state.cc_acode}<br/>
                Merchant Code: {this.state.cc_mcode}<br/>
                Merchant ID: {this.state.cc_mid}<br/>
                Card Holder: {this.state.cc_holder}<br/>
                Type: {this.state.cc_type}<br/>
                NO: {this.state.cc_no}<br/>
                Expiry: {this.state.cc_expiry}
              </td>
              <td className="pay-mode-tail">
                {payments[i].amount.toFixed(2)}
              </td>
            </tr>
          );

        case 'Cheque':
          return (
            <tr>
              <td colSpan="3" className="pay-mode-head">
                {mode}<br/>
                NO: {this.state.chqno}
              </td>
              <td className="pay-mode-tail">
                {payments[i].amount.toFixed(2)}<br/>
              </td>
            </tr>
          )

        case 'E-Payment':
          return (
            <tr>
              <td colSpan="3" className="pay-mode-head">
                {mode}<br/>
                Code: {this.state.epcode}
              </td>
              <td className="pay-mode-tail">
                {payments[i].amount.toFixed(2)}<br/>
              </td>
            </tr>
          )

        case 'Boost':
          return (
            <tr>
              <td colSpan="3" className="pay-mode-head">
                {mode}<br/>
                Code: {this.state.epcode}
              </td>
              <td className="pay-mode-tail">
                {payments[i].amount.toFixed(2)}<br/>
              </td>
            </tr>
          )

        case 'Quinpay':
          return (
            <tr>
              <td colSpan="3" className="pay-mode-head">
                {mode}<br/>
                Code: {this.state.epcode}
              </td>
              <td className="pay-mode-tail">
                {payments[i].amount.toFixed(2)}<br/>
              </td>
            </tr>
          )

        case 'Splus':
          return (
            <tr>
              <td colSpan="3" className="pay-mode-head">
                {mode}<br/>
                Code: {this.state.epcode}
              </td>
              <td className="pay-mode-tail">
                {payments[i].amount.toFixed(2)}<br/>
              </td>
            </tr>
          )

        default: return (null);
      }
    }
  }

  renderReceiptContent() {
    if(this.state.isReturn === true) {
      return this.mapItemsForReturn();
    }
    else {
      return this.mapItems();
    }
  }

  renderReturnBtn() {
    const option = [
      {value:'Cash', label:'Cash'},
      {value:'Credit Card', label:'Credit Card'},
      {value:'E-Payment', label:'E-Payment'}
    ];
    const {selectedReturnType, returntype} = this.state;
    if(this.state.isReturn === true) {
      return (
        <tr>
          <td colSpan="2" style={{paddingTop: 10, paddingBottom: 10}}>
            <Select
              name="selectedReturnType"
              value={selectedReturnType}
              onChange={this.handleRetTypeSelect}
              options={option}
              placeholder="Select Return Type"
            />
          </td>
          <td align="right" colSpan="2" style={{paddingTop: 10, paddingBottom: 10}}>
            <Button
              className="btn btn-primary"
              onClick={()=>this.execReturn()}>
              Confirm Return
            </Button>
          </td>
        </tr>
      )
    }
  }

  //--------------------------------------------------------------------------
	execReturnFail(code) {
		switch(code) {
			case 417:
				this.setState({
					returnMsg: "ERROR: This item already returned",
					appFlow: 'fail'
				})
				break;

			case 500:
				this.setState({
					returnMsg: "ERROR: Unknown error. Check network connection",
					appFlow: 'fail'
				})
				break;

			default:
				this.setState({
					returnMsg: "ERROR: Unknown error. Check network connection",
					appFlow: 'fail'
				})
				break;
		}
	}

	htmlMarkup(val) {
		return {__html: val.replace(/\n/g, '<br />')};
	}

	renderCompanyNo() {
		if(this.state.company === 'Paragon Pets Sdn Bhd') {
			return "0000089414T";
		}
	}

	/*
	<div className="app-logo-container">
		<img
			src="/images/paragon-logo.png"
			className="app-img-responsive"
		/>
	</div>
	<div className="float-right">
		<label className="uppercase-label">
			{this.state.company}
		</label><br/>
		{this.renderCompanyNo()}<br/>
		<div dangerouslySetInnerHTML={this.htmlMarkup(this.state.address)}></div>
	</div>
	*/

  render() {
    switch(this.state.appFlow) {
      case 'default':
          return (
              <div id="app-pos-modal">
                  <div className="receipt">
                      <table>
                      <tbody>
                      <tr>
                          <td colSpan="4" align="center" style={{border:'none',paddingBottom:10}}>
														<img
															src="/images/paragon-logo.png"
															className="app-img-responsive"
														/>
                          </td>
                      </tr>
											<tr>
                          <td colSpan="4" align="center" style={{border:'none',paddingBottom:10}}>
														<strong className="uppercase-label">
															PARAGON PETS
														</strong>
														<div dangerouslySetInnerHTML={this.htmlMarkup(this.state.address)}></div>
                          </td>
                      </tr>
                      <tr><td colSpan="4"><hr className="app-hr-thin"/></td></tr>
                      <tr>
                          <td>
                              INVOICE:
															<br/><label>{this.state.invoice}</label><br/>
                              Cashier: {this.state.cashier}<br/>
															Sales Person: {this.state.salesperson_name}<br/>
                              Date: {this.state.date}<br/>
                              Time: {moment(this.state.creation).format('hh:mm A')}<br/><br/>
															<strong>
																{
																	this.state.customer === 'Walk In (Luyang 2)' ?
																	'WALK IN (LIKAS)' :
																	this.state.customer.toUpperCase()
																}
															</strong>
                          </td>
                      </tr>
                      <tr><td colSpan="4"><hr className="app-hr-thin"/></td></tr>
                      <tr>
                          <td><strong>Item</strong></td>
                          <td align="center"><strong>Qty</strong></td>
													<td align="right"><strong>Price</strong></td>
                          <td align="right"><strong>Total</strong></td>
                      </tr>

                      {this.renderReceiptContent()}
                      {this.renderReturnBtn()}

                      <tr><td colSpan="4"><hr className="app-hr-thin"/></td></tr>
                      <tr>
                          <td colSpan="3">Sales</td>
                          <td align="right">{this.state.total}</td>
                      </tr>
                      <tr>
                          <td colSpan="3"><label>Net Total</label></td>
                          <td align="right"><label>{this.state.total}</label></td>
                      </tr>
                      <tr><td colSpan="4"><hr className="app-hr-thin"/></td></tr>

                      {this.getPaymentsData('Cash')}
                      {this.getPaymentsData('Credit Card')}
                      {this.getPaymentsData('E-Payment')}
                      {this.getPaymentsData('Boost')}
                      {this.getPaymentsData('Quinpay')}
                      {this.getPaymentsData('Splus')}
                      {this.getPaymentsData('Cheque')}

											<tr><td colSpan="4"><hr className="app-hr-thin"/></td></tr>
											<tr>
												<td colSpan="4" align="center">
													Goods Sold are not exchangeable and returnable
												</td>
											</tr>
											<tr><td colSpan="4"><hr className="app-hr-thin"/></td></tr>
											<tr>
												<td colSpan="4">
													<div>
														MERCHANDISE EXCHANGE MUST BE MADE WITHIN 3 DAYS
														ACCOMPANIED BY RECEIPT.
													</div>
													<div>
														THANK YOU, PLEASE COME AGAIN.
													</div>
													<div>
														TERIMA KASIH, SILA DATANG LAGI.
													</div>
												</td>
											</tr>
                      </tbody>
                      </table>
                  </div>
              </div>
          )
      case 'loading':
          return (
              <div id="app-pos-modal">
                  <div align="center">
                      Generating Receipt...
                      <i className="fa fa-circle-o-notch fa-spin fa-fw app-spinner-color"></i>
                  </div>
              </div>
          )

      case 'submit':
          return (
              <div id="app-pos-modal">
                  <div align="center">
                      Submitting Return...
                      <i className="fa fa-circle-o-notch fa-spin fa-fw app-spinner-color"></i>
                  </div>
              </div>
          )

      case 'success':
          return (
            <div id="app-pos-modal">
              <div align="center">
                  Return Success
                  <i className="fa fa-check"></i>
              </div>
            </div>
          )

      case 'fail':
        return (
          <div id="app-pos-modal">
            <div align="center">
              {this.state.returnMsg} <i className='fa fa-exclamation-triangle'></i>
            </div>
          </div>
        )

      default:
        return (
          <div id="app-pos-modal">
            <div align="center">
              ERROR: Unable to continue. Please check your internet connection.
            </div>
          </div>
        )
    }
  }
}
