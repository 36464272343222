import React, { Component } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

import {
  Modal,
  Button,
  Grid,
  Col,
  Row,
  FormGroup,
  FormControl,
  ControlLabel
} from 'react-bootstrap';

import {ReportHeader, ReportFooter, ReportLoading, ReportError} from './Component';
import './Reports.css';
import {appColor} from './Constant';
import {fFetchStockReport, fInitApp} from './Function';

//---

export class LazyStock extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appFlow:'loading',
      appBusy:false,
      loadingText:'Please wait...',
      modalVisible:false,
      dateFrom:null,
      dateTo:null,
      outlet:'',
      data:[],
      reportLoaded:false,
      reportLoading:false
    }

    this.setDateFrom = this.setDateFrom.bind(this);
    this.setDateTo = this.setDateTo.bind(this);
  }

  async componentDidMount() {
    await this.initComponent()
  }

  async initComponent() {
    this.setState({ appFlow:'loading' })
    try {
      const flow = await fInitApp()
      this.setState({appFlow:flow})
    }
    catch(err) {
      console.log('app err @initComponent')
      console.log(err)
      this.setState({appFlow:'error'})
    }
  }

  //-- @methods

  setOutlet = (outlet) => {
    if(outlet) {
      this.setState({
        outlet
      })
    }
  }

  setDateFrom(date) {
    let formated = moment(date).format('YYYY-MM-DD')
    this.setState({dateFrom:date})
  }

  setDateTo(date) {
    this.setState({dateTo:date})
  }

  handleModalOnHide() {
    this.setState({
      modalVisible:false,
      appBusy:false,
    })
  }

  reviewData() {
    const {outlet, dateFrom, dateTo} = this.state;
    if(outlet === ''
    || dateFrom === null
    || dateFrom === ''
    || dateTo === null
    || dateTo === '') {
      alert("Criteria was not set properly.")
    }
    else {
      this.setState({
        reportLoading:true,
      },function() {
        let params = {
          'outlet':outlet,
          'dateFrom':dateFrom,
          'dateTo':dateTo,
          'outlet':outlet.value,
          'method':'lazystock',
          'itemcode':''
        }
        this.fetchReport(params)
      })
    }
  }

  async fetchReport(params) {
    try {
      const payload = await fFetchStockReport(params);
      if(payload.success === true) {
        this.setState({
          data:payload.data,
          reportLoading:false,
          reportLoaded:true,
          appFlow:'default'
        })
      }
      else {
        this.setState({
          reportLoading:false,
          appFlow:'error'
        })
      }
    }
    catch(err) {
      console.log('app err @fetchReport')
      console.log(err)
    }
  }

  //-- @render

  renderSelectOutlet() {
    const outlets = [
      {value:'Outlet Bundusan', label:'Outlet Bundusan'},
      {value:'Outlet Luyang', label:'Outlet Luyang'},
      {value:'Outlet Keningau', label:'Outlet Keningau'},
      {value:'Outlet Kudat', label:'Outlet Kudat'},
      {value:'Outlet Tawau', label:'Outlet Tawau'},
    ];
    const {outlet} = this.state;
    return (
      <Select
        name="outlet"
        value={outlet}
        onChange={this.setOutlet}
        options={outlets}
        placeholder="Select Outlet"
        className="select-container-outlet"
      />
    );
  }

  renderForm() {
    return (
      <form>
      <Grid>
        <Row>
        <FormGroup>
          <Col md={3}>
            <ControlLabel>Date From</ControlLabel><br/>
            <DatePicker
              selected={this.state.dateFrom}
              onChange={this.setDateFrom}
              className="datepicker-select-repo"
              calendarClassName="datepicker-calendar"
            />
          </Col>
          <Col md={3}>
            <ControlLabel>Date To</ControlLabel><br/>
            <DatePicker
              selected={this.state.dateTo}
              onChange={this.setDateTo}
              className="datepicker-select-repo"
              calendarClassName="datepicker-calendar"
            />
          </Col>
          <Col md={3}>
            <ControlLabel>Outlet</ControlLabel><br/>
            {this.renderSelectOutlet()}
          </Col>
          <Col md={3}>
            <ControlLabel>Submit</ControlLabel><br/>
            <Button
              bsStyle='primary'
              onClick={()=>this.reviewData()}
            >
              Generate Report
            </Button>
          </Col>
        </FormGroup>
        </Row>
      </Grid>
      </form>
    );
  }

  renderItems() {
    let data = this.state.data;
    let items = data.data;
    if(items.length > 0) {
      return items.map((item, index) => {
        return (
          <tr key={index}>
            <td style={{fontSize:14}}>{index+1}</td>
            <td style={{fontSize:14}}>{item.code}</td>
            <td style={{fontSize:14}}>{item.name}</td>
            <td style={{fontSize:14}} align="right">{parseInt(item.qty)}</td>
          </tr>
        );
      })
    }
    else {
      return (
        <tr>
          <td colSpan="9">
          No data found
          </td>
        </tr>
      );
    }
  }

  renderData() {
    let loading = this.state.reportLoading;
    let loaded = this.state.reportLoaded;

    if(loading === false && loaded === false) {
      return (
        <Grid>
          <h3>No Movement Stock</h3>
          <hr/>
          <ul>
            <li>To get started, provide date range, <i>From</i> and <i>To</i></li>
            <li>Next, choose <i>Outlet</i></li>
            <li>Then, press <i>Submit</i></li>
          </ul>
        </Grid>
      );
    }
    else if(loading === true && loaded === false) {
      return (
        <div align="center" style={{marginTop:100}}>
          <ReportLoading text='Please wait, generating report...'/>
        </div>
      );
    }
    else if(loading === false && loaded === true) {
      let data = this.state.data
      return (
        <div>
          <Grid>
            <h3>No Movement Stock</h3>
            <hr/>
            <div>
              <strong>Total Items:</strong> {data.total}
            </div>
            <br/>
            <table className="table table-striped table-condensed">
            <thead>
            <tr>
              <td><strong>#</strong></td>
              <td><strong>Code</strong></td>
              <td><strong>Name</strong></td>
              <td><strong>Balance</strong></td>
            </tr>
            </thead>
            <tbody>
              {this.renderItems()}
            </tbody>
            </table>
          </Grid>
        </div>
      );
    }
  }

  //-- @output

  render() {
    switch(this.state.appFlow) {
      case 'default':
        return (
          <div>
            <ReportHeader/>
            {this.renderForm()}
            {this.renderData()}
            <ReportFooter/>
          </div>
        );

      case 'loading':
        return (
          <div>
            <ReportHeader/>
            <ReportLoading/>
            <ReportFooter/>
          </div>
        );

      case 'error':
        return (
          <div>
            <ReportHeader/>
            <ReportError/>
            <ReportFooter/>
          </div>
        );

      default:
        return (
          <div>
            <ReportHeader/>
            <Grid>
              <p>Session expired. Please <a href="/reports">Re-Login</a></p>
            </Grid>
            <ReportFooter/>
          </div>
        );
    }
  }
}

//------------------------------------------------------------------------------

export class StockCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appFlow:'loading',
      appBusy:false,
      loadingText:'Please wait...',
      modalVisible:false,
      dateFrom:null,
      dateTo:null,
      outlet:'',
      itemcode:'',
      data:[],
      reportLoaded:false,
      reportLoading:false,
      itemSearchValue:null,
      itemSearchLabel:'',
      selectedItem:null
    }

    this.setDateFrom = this.setDateFrom.bind(this);
    this.setDateTo = this.setDateTo.bind(this);
  }

  async componentDidMount() {
    await this.initComponent()
  }

  async initComponent() {
    this.setState({
      appFlow:'loading',
      dateFrom:moment('2018-11-01')
    })
    try {
      const flow = await fInitApp()
      this.setState({appFlow:flow})
    }
    catch(err) {
      console.log('app err @initComponent')
      console.log(err)
      this.setState({appFlow:'error'})
    }
  }

  //-- @methods

  handleInputChange = (event) => {
    const {target:{name, value}} = event
    this.setState({
      [name]:value
    })
  }

  setOutlet = (outlet) => {
    if(outlet) {
      this.setState({
        outlet
      })
    }
  }

  setDateFrom(date) {
    let formated = moment(date).format('YYYY-MM-DD')
    this.setState({dateFrom:date})
  }

  setDateTo(date) {
    this.setState({dateTo:date})
  }

  handleModalOnHide() {
    this.setState({
      modalVisible:false,
      appBusy:false,
    })
  }

  reviewData() {
    const {itemcode, outlet, dateFrom, dateTo} = this.state;
    if(outlet === ''
    || itemcode === ''
    || dateFrom === null
    || dateFrom === ''
    || dateTo === null
    || dateTo === '') {
      alert("Criteria was not set properly.")
    }
    else {
      this.setState({
        reportLoading:true,
        reportLoaded:false
      },function() {
        let params = {
          'outlet':outlet,
          'dateFrom':dateFrom,
          'dateTo':dateTo,
          'outlet':outlet.value,
          'method':'stockcard',
          'itemcode':itemcode
        }
        this.fetchReport(params)
      })
    }
  }

  async fetchReport(params) {
    try {
      const payload = await fFetchStockReport(params);
      if(payload.success === true) {
        console.log('payload received')
        console.log(payload)

        this.setState({
          data:payload.data,
          reportLoading:false,
          reportLoaded:true,
          appFlow:'default'
        })
      }
      else {
        this.setState({
          reportLoading:false,
          appFlow:'error'
        })
      }
    }
    catch(err) {
      console.log('app err @fetchReport')
      console.log(err)
    }
  }

  //-- @render

  renderSelectOutlet() {
    const outlets = [
      {value:'Outlet Bundusan', label:'Outlet Bundusan'},
      {value:'Outlet Luyang', label:'Outlet Luyang'},
      {value:'Outlet Keningau', label:'Outlet Keningau'},
      {value:'Outlet Kudat', label:'Outlet Kudat'},
      {value:'Outlet Tawau', label:'Outlet Tawau'},
    ];
    const {outlet} = this.state;
    return (
      <Select
        name="outlet"
        value={outlet}
        onChange={this.setOutlet}
        options={outlets}
        placeholder="Select Outlet"
        className="select-container-outlet"
      />
    );
  }

  //-- @async select

  loadItemOption = (inputValue, callback) => {
    setTimeout(() => {
      callback(this.filterItem(inputValue));
    }, 1000);
  }

  filterItem = (inputValue: string) => {
    let found = JSON.parse(sessionStorage.getItem('paragon_item')).filter(i =>
			i.label.toLowerCase().includes(inputValue.toLowerCase())
		)
		return found;
  }

  handleAsyncSelectItem = (newValue: string) => {
		this.setState({
			itemSearchValue: newValue
		},function(){
			return this.state.itemSearchValue
		})
	}

  handleChangeSelectItem = (selectedItem) => {
    this.setState({
      itemcode:`${selectedItem.value}`,
      itemSearchValue:`${selectedItem.label}`,
      selectedItem:selectedItem,
    },function() {
      console.log('selected itemcode:')
      console.log(this.state.itemcode)
      console.log('selected label')
      console.log(this.state.itemSearchValue)
    })
  }

  renderForm() {
    return (
      <form>
      <Grid>
        <FormGroup>
          <Row>
            <Col md={12}>
              <ControlLabel>Item Code</ControlLabel><br/>
              <AsyncSelect
                ref='asyncItem'
                value={this.state.selectedItem}
                loadOptions={this.loadItemOption}
                onInputChange={this.handleAsyncSelectItem}
                onChange={this.handleChangeSelectItem}
                placeholder='Type item code'
                autoFocus={true}
              />
            </Col>
          </Row>
          <br/>
          <Row>
          <Col md={3}>
            <ControlLabel>Date From</ControlLabel><br/>
            <DatePicker
              selected={this.state.dateFrom}
              onChange={this.setDateFrom}
              className="datepicker-select-repo"
              calendarClassName="datepicker-calendar"
            />
          </Col>
          <Col md={3}>
            <ControlLabel>Date To</ControlLabel><br/>
            <DatePicker
              selected={this.state.dateTo}
              onChange={this.setDateTo}
              className="datepicker-select-repo"
              calendarClassName="datepicker-calendar"
            />
          </Col>
          <Col md={3}>
            <ControlLabel>Outlet</ControlLabel><br/>
            {this.renderSelectOutlet()}
          </Col>
          <Col md={3}>
            <ControlLabel>Submit</ControlLabel><br/>
            <Button
              bsStyle='primary'
              onClick={()=>this.reviewData()}
            >
              Generate Report
            </Button>
          </Col>
          </Row>
        </FormGroup>
      </Grid>
      </form>
    );
  }

  renderItems() {
    let data = this.state.data;
    let items = data.data;
    let balance = 0
    let value = 0.00
    let balances = []
    let prevBalEntry = []
    let n = 0
    let stockrate = 0.00

    let inIndex = items.findIndex(s => s.route === 'in')
    if(inIndex !== -1) {
      stockrate = items[inIndex].rate
    }

    items.sort(function(a, b) { return new Date(b.date) - new Date(a.date); });
    items.sort(function(a, b) { return new Date(b.time) - new Date(a.time); });

    console.log('items was sorted by date then time')
    console.log(items)

    let copy = [...items]
    const itemscopy  = copy.reverse()

    //console.log('itemscopy reversed')
    //console.log(itemscopy)

    //console.log('items array status')
    //console.log(items)

    if(itemscopy.length > 0) {
      // we know that the arrays are now sorted by date and time
      //console.log('items array: ')
      //console.log(items)
      for(let i=0;i<itemscopy.length;i++) {
        if(balances.length > 0) {
          n = i-1
          prevBalEntry = balances[n].route;

          //console.log('prevBalEntry index: ' + n)
          //console.log('prevBalEntry route: ' + prevBalEntry)
          //console.log('balances: ')
          //console.log(balances)

          if(itemscopy[i].route === 'out') {
            if(stockrate !== 0.00 && stockrate !== 0) {
              let outqty = parseInt(balances[n].qty) - parseInt(itemscopy[i].qty)
              let outval = parseInt(outqty)*stockrate
              let outbal = {
                route: 'out',
                qty: outqty,
                val: parseFloat(outval).toFixed(2),
                rate:parseFloat(balances[n].rate).toFixed(2)
              }
              balances.push(outbal)
            }
            else {
              let outqty = parseInt(itemscopy[i].qty)
              let outval = parseInt(outqty)*stockrate
              let outbal = {
                route: 'out',
                qty: -Number(outqty),
                val: 0.00,
                rate:parseFloat(balances[n].rate).toFixed(2)
              }
              balances.push(outbal)
            }
          }
          else {
            let inqty = parseInt(balances[n].qty) + parseInt(Math.abs(itemscopy[i].qty))
            let inval = parseInt(inqty)*stockrate
            let inbal = {
              route: 'in',
              qty: inqty,
              val: parseFloat(inval).toFixed(2),
              rate:parseFloat(balances[n].rate).toFixed(2)
            }
            balances.push(inbal)
          }
        }
        else {
          //console.log('balances array is empty, creating new entry')
          if(itemscopy[i].route === 'in') {
            let in_qty = parseInt(itemscopy[i].qty);
            let in_val = parseFloat(itemscopy[i].amount).toFixed(2);

            let instock = {
              route:'in',
              qty:Math.abs(in_qty),
              val:in_val,
              rate:parseFloat(itemscopy[i].rate).toFixed()
            }
            //console.log('entry is IN')
            balances.push(instock)
          }
          else {
            let out_qty = parseInt(itemscopy[i].qty);
            let out_val = parseFloat(itemscopy[i].amount).toFixed(2);

            let outstock = {
              route:'out',
              qty:-Number(out_qty),
              val:0.00,
              rate:0.00
            }
            //console.log('entry is OUT')
            balances.push(outstock)
          }
        }
      }

      balances.reverse()

      return items.map((item, index) => {
        if(item.route === 'in') {
          return (
            <tr key={index}>
              <td style={{fontSize:14, color:'silver'}}>{index+1}</td>
              <td style={{fontSize:14}}>{item.date}</td>
              <td style={{fontSize:14}}>{item.doc}</td>
              <td style={{fontSize:14}}>{item.qty}</td>
              <td style={{fontSize:14}}>{item.rate}</td>
              <td style={{fontSize:14}}>{item.amount}</td>
              <td></td>
              <td></td>
              <td></td>
              <td>{balances[index].qty}</td>
              <td>{balances[index].val}</td>
            </tr>
          );
        }
        else {
          return (
            <tr key={index}>
              <td style={{fontSize:14, color:'silver'}}>{index+1}</td>
              <td style={{fontSize:14}}>{item.date}</td>
              <td style={{fontSize:14}}>{item.doc}</td>
              <td></td>
              <td></td>
              <td></td>
              <td style={{fontSize:14}}>{item.qty}</td>
              <td style={{fontSize:14}}>{item.rate}</td>
              <td style={{fontSize:14}}>{item.amount}</td>
              <td>{balances[index].qty}</td>
              <td>{balances[index].val}</td>
            </tr>
          );
        }
      })
    }
    else {
      return (
        <tr>
          <td colSpan="9">
          No data found
          </td>
        </tr>
      );
    }
  }

  renderData() {
    let loading = this.state.reportLoading;
    let loaded = this.state.reportLoaded;
    let data = this.state.data

    if(loading === false && loaded === false) {
      return (
        <Grid>
          <h3>Stock Card Movement</h3>
          <hr/>
          <ul>
            <li>Provide an <i>Item Code</i></li>
            <li>Pick a <i>From Date</i> to <i>To Date</i></li>
            <li>Select an <i>Outlet</i></li>
            <li>Press <i>Generate Report</i> button.</li>
          </ul>
        </Grid>
      );
    }
    else if(loading === true && loaded === false) {
      return (
        <div align="center" style={{marginTop:100}}>
          <ReportLoading text='Please wait, generating report...'/>
        </div>
      );
    }
    else if(loading === false && loaded === true) {
      return (
        <div>
          <Grid>
          <h3>Stock Card Movement List</h3><hr/>
          <table className="table table-striped table-condensed">
          <thead>
          <tr>
            <td></td>
            <td><strong>Qty</strong></td>
            <td><strong>Value</strong></td>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td><strong>Received</strong></td>
            <td>{data.received.qty}</td>
            <td>{parseFloat(data.received.amount).toFixed(2)}</td>
          </tr>
          <tr>
            <td><strong>Issued</strong></td>
            <td>{data.issued.qty}</td>
            <td>{parseFloat(data.issued.amount).toFixed(2)}</td>
          </tr>
          </tbody>
          </table>
          <br/>
          <table className="table table-striped table-condensed">
          <thead>
          <tr>
            <td colSpan="3"></td>
            <td colSpan="3"><strong>RECEIVED</strong></td>
            <td colSpan="3"><strong>ISSUED</strong></td>
            <td colSpan="2"><strong>BALANCE</strong></td>
          </tr>
          <tr>
            <td><strong>#</strong></td>
            <td><strong>Date</strong></td>
            <td><strong>Ref</strong></td>
            <td><strong>Qty</strong></td>
            <td><strong>Rate</strong></td>
            <td><strong>Value</strong></td>
            <td><strong>Qty</strong></td>
            <td><strong>Rate</strong></td>
            <td><strong>Value</strong></td>
            <td><strong>Qty</strong></td>
            <td><strong>Value</strong></td>
          </tr>
          </thead>
          <tbody>
            {this.renderItems()}
          </tbody>
          </table>
          </Grid>
        </div>
      );
    }
  }

  //-- @output

  render() {
    switch(this.state.appFlow) {
      case 'default':
        return (
          <div>
            <ReportHeader/>
            {this.renderForm()}
            {this.renderData()}
            <ReportFooter/>
          </div>
        );

      case 'loading':
        return (
          <div>
            <ReportHeader/>
            <ReportLoading/>
            <ReportFooter/>
          </div>
        );

      case 'error':
        return (
          <div>
            <ReportHeader/>
            <ReportError/>
            <ReportFooter/>
          </div>
        );

      default:
        return (
          <div>
            <ReportHeader/>
            <Grid>
              <p>Session expired. Please <a href="/reports">Re-Login</a></p>
            </Grid>
            <ReportFooter/>
          </div>
        );
    }
  }
}
