import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment';
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import ReactToPrint from 'react-to-print';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { appMode, webAPI } from './library/appconst.js';
import { fAppAPI, fPosNew } from './library/appfunction.js';
import { fGetReport } from './API.js';
import PrintReport from './PrintReport.js';
import PrintReceipt from './PrintReceipt.js';
import PrintClosing from './PrintClosing.js';
import StockReport from './StockReport.js';
import './App.css';

import {
    fGetPromoSetting,
    fGetStore
} from './library/posfunction.js';

//------------------------------------------------------------------------------

export default class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            appFlow: 'loading',
            loginError: false,
            isLoggedIn: false,
            mu: '',
            mp: '',
            username: '',
            password: '',
            erpuser: [],
            firstname: '',
            returner: 0,
            editer: 0,
            company: null,
            percentagelimit: 0.00,
            currencylimit: 0.00,

            // @mrp
            mrptier: [],
            mrpdata: [],
            mrpenabled: 0,
            mrpmax: 0,
            mrprate: 0,
            mrpinput: 0, // this for testing

            // @modal
            modalVisible: false, // false
            modalAction: null, // null

            // @sync
            syncing: 'yes',
            syncBin: 'yes',
            syncItem: 'yes',
            syncCustomer: 'yes',
            lengthItems: 0,
            lengthBin: 0,
            totalBin: 0,
            totalItems: 0,
            totalCustomers: 0,

            // @bday
            bdaycode: '',
            bdayyear: moment().year(),
            bdaydiscount: 20,
            bdaycheck: false,
            bdayused: null,

            // @redeem
            redemption: [],
            fetchingredeem: true,

            // @accounting
            paymentmodes: [],

            // @customers
            customers: [],

            // @products
            products: [],
            pricerules: [],
            stores: null,
            stock: [],
            taxrate: 0,

            // @sales
            sale: [],
            customer: '',
            customerID: '',
            membership: 'None',
            point: 0,
            ptstominus: 0, // to minus the point above. check @getItemPrice
            nric: '',
            nricopt: false,
            phone: '',
            profileimage: '',
            cart: [],
            item: [], // this for @pay now
            payments: [], // this for @pay now
            unit: [], // product counts for each cart item added
            discount: [], // the user may change the discount
            newdiscount: 0,
            units: 0, // total counts for all products added
            sum: 0.00, // before discount

            // @salesperson
            salesperson: '',
            salesperson_name: 'Not Available',
            selectedSalesPerson: [],

            // @payment
            grandtotal: 0.00, // after discount
            cash: 0.00,
            paid: 0.00,
            change: 0.00,

            creditcard: 0.00,
            approvalcode: undefined,
            merchantcode: undefined,
            merchantid: undefined,
            cardholder: undefined,
            ccno: undefined,
            ccexpiry: undefined,
            cctype: undefined,
            selectedCCType: null,

            cheque: 0.00,
            chequeno: undefined,
            chequebank: undefined,
            epayment: 0.00,
            selectedept: null,
            epaymenttype: undefined,
            epaymentcode: undefined,
            epaymentbank: undefined,

            // @removeitem @cart
            // index, code, price, count
            nameProp: null,
            stockProp: null,
            indexProp: null,
            codeProp: null,
            priceProp: null,
            countProp: null,
            minusProp: 1,
            focProp: false,
            isFOC: false,

            // @search - select item
            itemsearchopt: 'barcode',
            selectedProduct: null,
            productSearchValue: '',
            itemSearchPlaceHolder: 'Scan Product Barcode...',
            hasBarcodeAdded: false,

            customersearchopt: 'name',
            selectedCustomer: null,
            customerSearchValue: '',
            customerSearchPlaceHolder: 'Search By Name...',
            customerRefValue: null, // mlm
            customerRefLabel: null, // mlm

            // @invoice !IMPORTANT
            inv_debit_to: null,
            inv_account_cash: null,
            inv_account_bank: null,
            inv_account_creditcard: null,
            inv_account_boost: null,
            inv_account_epayment: null,
            inv_account_cheque: null,
            inv_warehouse: null,
            inv_company: null,
            inv_project: null,
            inv_costcenter: null,
            inv_address: null,

            // @invoice REPORT
            invoices: [],
            report: [],
            report_item_data: [],
            report_type: 'Product',
            report_type_text: 'Invoice',
            report_date: moment(),
            inv_posting_date: moment().format('YYYY-MM-DD'),
            inv_posting_time: moment().format('h:mm:ss'),
            inv_total: 0.00,
            inv_cash: 0.00,
            inv_creditcard: 0.00,
            inv_boost: 0.00,
            inv_quinpay: 0.00,
            inv_splus: 0.00,
            inv_epayment: 0.00,
            inv_cheque: 0.00,
            isReturn: false,
            inv_salesperson: '',

            // @invoice RETURNS
            returns: [],
            ret_cash: 0.00,
            ret_creditcard: 0.00,
            ret_epayment: 0.00,

            // @print
            inv_name: '',
            inv_data: [],
            inv_fetched: 0,

            // @closing
            cl_cent_1: 0, cl_sum_cent_1: 0,
            cl_cent_5: 0, cl_sum_cent_5: 0,
            cl_cent_10: 0, cl_sum_cent_10: 0,
            cl_cent_20: 0, cl_sum_cent_20: 0,
            cl_cent_50: 0, cl_sum_cent_50: 0,
            cl_cent_rm1: 0, cl_sum_cent_rm1: 0,
            cl_rm_1: 0, cl_sum_rm_1: 0,
            cl_rm_5: 0, cl_sum_rm_5: 0,
            cl_rm_10: 0, cl_sum_rm_10: 0,
            cl_rm_20: 0, cl_sum_rm_20: 0,
            cl_rm_50: 0, cl_sum_rm_50: 0,
            cl_rm_100: 0, cl_sum_rm_100: 0,
            cl_total_cent: 0.00,
            cl_total_rm: 0.00,
            cl_total: 0.00,

            // @member registration
            reg_name: '',
            reg_mobile: '',
            reg_nric: '',
            reg_outlet: '',
            reg_false: false,
            reg_duplicate: false,
            reg_data: [],
            selectedOutlet: null,

            // @mlm reference
            ref_display: false,
            ref_customer: '',
            ref_membership: '',
            ref_points: '',
            ref_nric: '',
            ref_phone: '',
            ref_profileimage: '',

            // @member edit
            new_name: '',
            new_mobile: '',
            new_nric: '',
            newOutlet: null,
            newTier: '',
            selectedNewTier: '',

            // @member upgrade
            eligible: false,
						classic_min:0,
						classic_max:0,
						gold_min:0,
						gold_max:0,
						platinum_min:0,
						platinum_max:0,

            // @bridges
            showUtilBtn: true,

            // @service
            serviceFee: 0.00,

            // @reporting
            reportCode: 'All',
            isReportStock: false,

            mlm_status: null,
            mlm_message: '',
        }
        this.handleChangeNewDiscount = this.handleChangeNewDiscount.bind(this);
        this.handleChangeNewQty = this.handleChangeNewQty.bind(this);
        this.handleChangeU = this.handleChangeU.bind(this);
        this.handleChangeP = this.handleChangeP.bind(this);
        this.handleChangeReportDate = this.handleChangeReportDate.bind(this);
        this.toggleUtilBtn = this.toggleUtilBtn.bind(this);
        this.handleChangeFee = this.handleChangeFee.bind(this);
        this.handleFOCcheckbox = this.handleFOCcheckbox.bind(this);
        this.handleBdayCheckbox = this.handleBdayCheckbox.bind(this);
    }

    //----------------------------------------------------------------------------

    componentWillUnmount() {
        // avoid memory leak when using timer
        clearTimeout(this.timeoutHandle);
    }

    componentDidMount() {
        //localStorage.removeItem('erpnext_bin');
        //localStorage.removeItem('erpnext_item');
        //localStorage.removeItem('erpnext_customer');
        //localStorage.removeItem('select_customer');
        //localStorage.removeItem('select_item');
        this.checkAuthERPNext();
        // this.reloginERPNext();
    }

    //--------------------------------------------------------------------------

    async checkAuthERPNext() {
        // this.setState({ appFlow: 'loading' })
        // this.initERPNext(user)
        // this.reloginERPNext();
        // //console.log('@checkAuthERPNext exec')
        let api = fAppAPI() + 'api/method/frappe.auth.get_logged_user';
        // return fetch(api, {
        //     method: 'GET',
        //     credentials: 'include',
        // })
        try {
            const res = await fetch(api, {
                credentials: 'include',
                mode: 'cors'
            });
            const resJson = await res.json();
            if (resJson.message !== '') {
                console.log('user is logged in, initing...');
                //this.getBdayCode();
                //this.getMRPTier();
                this.initERPNext(resJson.message);
            }
            else {
                console.log('session expired, logging out...');
                this.reloginERPNext();
            }
        } catch (error) {
            console.log('session expired, or something went wrong. logging out...');
            console.log(error);
            this.reloginERPNext();
        }
    }

    authERPNext() {
        if (this.state.username.length > 0 && this.state.password.length > 0) {
            this.setState({ appFlow: 'loading' })
            if (this.state.username === 'api@app.bridge') {
                this.setState({
                    loginError: true,
                    appFlow: 'login'
                })
            }
            else {
                const api = fAppAPI() + 'api/method/login?'
                    + 'usr=' + this.state.username
                    + '&pwd=' + this.state.password

                return fetch(api, {
                    method: 'POST',
                    credentials: 'include',
                    mode: 'cors'
                })
                    .then((r) => {
                        console.log('res:', r)
                        console.log('res header:', r.headers)
                        return r.json()
                    })
                    .then((j) => {
                        if (j && Object.entries(j).length > 0) {
                            console.log('auth success:', j)

                            let user = j.full_name
                            this.checkAuthERPNext(user)
                        }
                        else {
                            console.log('j no object')
                            console.log(j)
                        }
                    })
                    .catch((e) => {
                        console.log('authERPNext ERROR')
                        console.log(e)
                        this.setState({
                            loginError: true,
                            appFlow: 'login'
                        });
                    })
            }
        }
        else {
            console.log('no input provided')
        }
    }

    async initERPNext(user) {
        let fields = 'fields=["first_name", "outlet", "percentage", "currency", "can_return_invoices", "can_edit_customer"]';
        let filters = '&filters=[["name", "=", "' + user + '"]]';
        let api = fAppAPI() + "api/resource/User/?" + fields + filters;

				// get membership points
				try {
						const tierFields = 'fields=["name", "min_points", "max_points"]'
						const tierApi = fAppAPI() + "api/resource/Membership Tier/?" + tierFields
						const res = await fetch(tierApi, {
							method: 'GET',
	            credentials: 'include'
						})
						const resj = await res.json()
						const tierData = resj.data

						console.log('membership tier fetched:')
						console.log(tierData)

						const ci = tierData.findIndex(e => e.name === 'Classic')
						const gi = tierData.findIndex(e => e.name === 'Gold')
						const pi = tierData.findIndex(e => e.name === 'Platinum')

						console.log('Classic: Min ', tierData[ci].min_points, ', Max ', tierData[ci].max_points)
						console.log('Gold: Min ', tierData[gi].min_points, ', Max ', tierData[gi].max_points)
						console.log('Platinum: Min ', tierData[pi].min_points, ', Max ', tierData[pi].max_points)

						this.setState({
								classic_min: tierData[ci].min_points,
								classic_max: tierData[ci].max_points,

								gold_min: tierData[gi].min_points,
								gold_max: tierData[gi].max_points,

								platinum_min: tierData[pi].min_points,
								platinum_max: tierData[pi].max_points,
						})

				} catch (error) {
            console.log('initERPNext failed @get membership tier');
            console.log(error);
            this.setState({
                appFlow: 'login',
                loginError: true,
                isLoggedIn: false,
            });
        }

        try {
            const res = await fetch(api, {
                credentials: 'include',
                mode: 'cors'
            });
            const resj = await res.json();
            this.setState({
                erpuser: resj.data[0],
                firstname: resj.data[0].first_name,
                currencylimit: resj.data[0].currency,
                percentagelimit: resj.data[0].percentage,
                company: resj.data[0].outlet,
                stores: fGetStore(resj.data[0].outlet),
                returner: resj.data[0].can_return_invoices,
                editer: resj.data[0].can_edit_customer
            }, function () {
                console.log('COMPANY IS: ', this.state.company);
                this.setAccountSettings(this.state.company);
                // this.setState({
                //     isLoggedIn: true,
                //     appFlow: 'default'
                // }, function () {
                //     console.log('force syncData');
                //     this.syncData();
                // });
            });
        } catch (error) {
            console.log('initERPNext failed');
            console.log(error);
            this.setState({
                appFlow: 'login',
                loginError: true,
                isLoggedIn: false,
            });
        }

				if(!this.state.loginError) {
						this.setState({
								isLoggedIn: true,
								appFlow: 'default'
						}, function () {
								console.log('force syncData');
								this.syncData();
						});
				}
    }

    async logoutERPNext() {
        localStorage.removeItem('erpnext_bin');
        localStorage.removeItem('erpnext_item');
        localStorage.removeItem('erpnext_customer');
        //localStorage.removeItem('select_customer');
        //localStorage.removeItem('select_item');
        sessionStorage.removeItem('select_customer');
        sessionStorage.removeItem('select_item');
        localStorage.removeItem('popular_item');
        localStorage.removeItem('promotion_item');

        let api = fAppAPI() + 'api/method/logout';

        try {
            const res = await fetch(api, { credentials: 'include' })
            console.log('logging out...')
        }
        catch (e) {
            // ignore
        }

        window.location.reload();
    }

    async reloginERPNext() {
        //localStorage.removeItem('erpnext_customer');
        //localStorage.removeItem('erpnext_item');

        localStorage.removeItem('erpnext_bin');
        localStorage.removeItem('erpnext_item');
        localStorage.removeItem('erpnext_customer');
        //localStorage.removeItem('select_customer');
        //localStorage.removeItem('select_item');
        sessionStorage.removeItem('select_customer');
        sessionStorage.removeItem('select_item');
        localStorage.removeItem('popular_item');
        localStorage.removeItem('promotion_item');

        let api = fAppAPI() + 'api/method/logout';

        try {
            const res = await fetch(api, { credentials: 'include' })
            console.log('logging out...')
        }
        catch (e) {
            // ignore
        }

        this.setState({
            appFlow: 'login'
        })

    }

    //--------------------------------------------------------------------------

    //----------------------------------------------------------------------------
    // @salesperson

    // check if local data exists
    getSalesPerson() {
        this.setState({
            salesperson: '',
            salesperson_name: '',
            selectedSalesPerson: []
        })
        let persons = localStorage.getItem('erpnext_salesperson');
        if (persons === null) {
            console.log('Local sav for salesperson is null. Fetching data')
            let api = 'https://api.paragonpetsgroup.net/salescom.php?method=salesperson';
            return fetch(api)
                .then((resp) => resp.json())
                .then((resj) => {
                    if (resj.success === true) {
                        localStorage.setItem('erpnext_salesperson', JSON.stringify(resj.data));
                        console.log('salesperson saved to local')
                    }
                    else {
                        console.log('get err @getSalesPerson')
                        console.log(resj)
                    }
                })
                .catch((err) => {
                    console.log('app err @getSalesPerson')
                    console.log(err)
                })
        }
    }

    //----------------------------------------------------------------------------
    // @bday

    renderBdayCheckbox() {
        if (this.state.membership !== 'None') {
            let used = this.state.bdayused;
            if (used === false) {
                return (
                    <div id="bday-checkbox">
                        <i className="fa fa-birthday-cake"></i>
                        <span>
                            Birthday Treats
                        </span>
                        <input
                            id="bdayCheckbox"
                            type="checkbox"
                            onClick={this.handleBdayCheckbox}
                        />
                    </div>
                );
            }
            else if (used === true) {
                return (
                    <div id="bday-checkbox">
                        <i className="fa fa-check-circle"></i>
                        <span>
                            Treats Received
                        </span>
                    </div>
                );
            }
            else {
                return (
                    <div id="bday-checkbox">
                        <i className="fa fa-birthday-cake"></i>
                        <small>CHECKING...</small>
                        <i className="fa fa-spinner fa-spin fa-fw app-spinner-color"></i>
                    </div>
                )
            }
        }
    }

    handleBdayCheckbox() {
        if (this.state.cart.length > 0) {
            if (this.state.bdaycheck === false) {
                this.setState({ bdaycheck: true }, function () {
                    console.log('bdaycheck set to TRUE')
                })
            }
            else {
                this.setState({ bdaycheck: false }, function () {
                    console.log('bdaycheck set to FALSE')
                })
            }
        }
        else {
            console.log('cart is empty');
            document.getElementById("bdayCheckbox").checked = false;
        }
    }

    updateBdayRecord(customer, invoice, checked) {
        if (checked === true) {
            let data = {
                "customer": customer,
                "invoice": invoice,
                "code": this.state.bdaycode,
                "year": moment().format('YYYY'),
                "outlet": this.state.inv_project,
                "date_time": moment().format('YYYY-MM-DD hh:mm:ss')
            }

            console.log('bday data ready')
            console.log(data)

            let api = fAppAPI() + 'api/resource/Birthday Treats Records/?data=' + JSON.stringify(data);
            fetch(api, {
                method: 'POST',
                credentials: 'include'
            })
                .then((resp) => resp.json())
                .then((resj) => {
                    console.log('Birthday Treats Records updated...');
                    console.log(resj.data)
                })
                .catch((error) => {
                    console.log('failed to update birthday treats records');
                    console.log(error)
                })
        }
    }

    checkBdayRecord() {
        this.setState({ bdayused: null })
        let customer = this.state.customerID;
        let year = moment().format('YYYY');
        let filter = '?filters=[["year","=","' + year + '"],["customer","=","' + customer + '"]]';
        let api = fAppAPI() + "api/resource/Birthday Treats Records/" + filter;

        //console.log('@checkBdayRecord calling API')
        //console.log(api)

        return fetch(api, {
            method: 'GET',
            credentials: 'include'
        })
            .then((resp) => resp.json())
            .then((resj) => {
                let data = resj.data;
                //console.log('bday check record result')
                //console.log(data)
                if (data.length > 0) {
                    this.setState({
                        bdayused: true
                    })
                }
                else {
                    this.setState({
                        bdayused: false
                    })
                }
            })
            .catch((error) => {
                console.log('@checkBdayRecord error')
                console.log(error);
            })
    }

    //----------------------------------------------------------------------------
    // @redeem

    fetchRedeemHistory() {
        this.setState({ fetchingredeem: true })
        let api = webAPI + 'method=redeemhistory&name=' + this.state.customerID
        return fetch(api)
            .then((resp) => resp.json())
            .then((resj) => {
                this.setState({
                    redemption: resj.data,
                    fetchingredeem: false
                })
            })
            .catch((err) => {
                this.closeModal()
                this.toggleModal('error')
            })
    }

    //----------------------------------------------------------------------------
    // @foc

    handleFOCcheckbox() {
        if (this.state.cart.length > 0) {
            if (this.state.isFOC === false) {
                this.setState({ isFOC: true }, function () {
                    console.log('isFOC set to TRUE')
                })
            }
            else {
                this.setState({ isFOC: false }, function () {
                    console.log('isFOC set to FALSE')
                })
            }
        }
        else {
            console.log('cart is empty');
            document.getElementById("focCheckbox").checked = false;
        }
    }

    renderFOCcheckbox() {
        return (
            <div className="pull-right">
                <input
                    id="focCheckbox"
                    type="checkbox"
                    onClick={this.handleFOCcheckbox}
                />
                <span style={{ marginLeft: 5 }}>
                    Is FOC (Tick before scan/search a product)
                </span>
            </div>
        );
    }

    resetFOCprop() {
        document.getElementById("focCheckbox").checked = false;
        this.setState({ isFOC: false }, function () {
            console.log('RESET: isFOC set to FALSE')
        })
    }

    //----------------------------------------------------------------------------

    toggleUtilBtn(e) {
        e.preventDefault()
        if (this.state.showUtilBtn === true) {
            this.setState({
                showUtilBtn: false
            })
        }
        else {
            this.setState({
                showUtilBtn: true
            })
        }
    }

    //--------------------------------------------------------------------------
    // try async search here
    // @Customer Name

    filterCustomer = (inputValue: string) => {
        let found = JSON.parse(sessionStorage.getItem('select_customer')).filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        return found;
    }

    loadCustomerOptions = (inputValue, callback) => {
        setTimeout(() => {
            callback(this.filterCustomer(inputValue));
        }, 1000);
    }

    filterCustomerOnRegister = (inputValue: string) => {
        let found = JSON.parse(sessionStorage.getItem('select_customer')).filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        return found;
    }

    loadCustomerOptionsOnRegister = (inputValue, callback) => {
        setTimeout(() => {
            callback(this.filterCustomerOnRegister(inputValue));
        }, 1000);
    }

    handleAsyncSelectCustomer = (newValue: string) => {
        //const customerSearchResult = newValue.replace(/\W/g, '');
        //const customerSearchResult = newValue;
        //this.setState({ customerSearchResult })
        //console.log(this.state.customerSearchResult);
        //console.log('selectedCustomer');
        //console.log(this.state.selectedCustomer);
        //console.log(customerSearchResult);
        //return customerSearchResult;
        this.setState({
            customerSearchValue: newValue
        }, function () {
            return this.state.customerSearchValue
        })
    }

    // @Product
    // try async search here
    filterProduct = (inputValue: string) => {
        let found = JSON.parse(sessionStorage.getItem('select_item')).filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        )
        //console.log('search results found is ' + found.length);
        if (found.length === 1 && this.state.itemsearchopt === 'barcode') {
            let code = found[0].value;
            let name = found[0].label;

            if (this.state.hasBarcodeAdded === false) {
                this.refs.asyncProduct.blur();
                //this.setState({ productSearchValue: '', })

                let items = JSON.parse(localStorage.getItem('erpnext_item'));
                let itemIndex = items.findIndex(p => p.name === code);
                name = items[itemIndex].item_name

                this.setState({
                    selectedProduct: {
                        value: code,
                        label: name
                    },
                    productSearchValue: '',
                    hasBarcodeAdded: true
                }, function () {
                    this.updateCart(code, name);
                    this.refs.asyncProduct.focus();
                })
                return null;
            }
        }
        else {
            return found;
        }
    }

    filterProductOnStockReport = (inputValue: string) => {
        let found = JSON.parse(sessionStorage.getItem('select_item')).filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        )
        return found;
    }

    loadProductOptionsOnStockReport = (inputValue, callback) => {
        setTimeout(() => {
            callback(this.filterProductOnStockReport(inputValue));
        }, 1000)
    }

    loadProductOptions = (inputValue, callback) => {
        setTimeout(() => {
            callback(this.filterProduct(inputValue));
        }, 1000)
    }

    handleAsyncSelectProduct = (newValue: string) => {
        //const productNameSearchResult = newValue.replace(/\W/g, '');
        //const productNameSearchResult = newValue;
        //this.setState({ productNameSearchResult: productNameSearchResult })
        //console.log(this.state.productNameSearchResult);
        //console.log('selectedProduct');
        //console.log(this.state.selectedProduct);
        //return productNameSearchResult;
        this.setState({
            productSearchValue: newValue,
            hasBarcodeAdded: false
        }, function () {
            return this.state.productSearchValue
        })
    }

    prepClosing() {
        //console.log('@prepClosing exec')
        this.setState({
            invoices: [],
            inv_posting_date: moment().format('YYYY-MM-DD'),
            inv_total: 0.00,
            inv_cash: 0.00,
            inv_creditcard: 0.00,
            inv_epayment: 0.00,
            inv_cheque: 0.00,
            cl_cent_1: 0, cl_sum_cent_1: 0,
            cl_cent_5: 0, cl_sum_cent_5: 0,
            cl_cent_10: 0, cl_sum_cent_10: 0,
            cl_cent_20: 0, cl_sum_cent_20: 0,
            cl_cent_50: 0, cl_sum_cent_50: 0,
            cl_cent_rm1: 0, cl_sum_cent_rm1: 0,
            cl_rm_1: 0, cl_sum_rm_1: 0,
            cl_rm_5: 0, cl_sum_rm_5: 0,
            cl_rm_10: 0, cl_sum_rm_10: 0,
            cl_rm_20: 0, cl_sum_rm_20: 0,
            cl_rm_50: 0, cl_sum_rm_50: 0,
            cl_rm_100: 0, cl_sum_rm_100: 0,
            cl_total_cent: 0.00,
            cl_total_rm: 0.00,
            cl_total: 0.00,
            returns: [],
            ret_cash: 0.00,
        })
        this.toggleModal('spinner')
        // get all the invoices for today date
        // date format is year-month-date
        // then each total push in array
        // get the array sum

        // 1. get all invoices by today date
        //console.log('get all invoice with posting_date: ' + this.state.inv_posting_date)
        let reportDate = moment().format('YYYY-MM-DD');
        // 1. get all invoices by today date
        //console.log('get all invoice with posting_date: ' + reportDate + ' AND project ' + this.state.inv_project);
        let todayinv_field = 'fields=["name", "status"]';
        let todayinv_filter = '&filters=[["is_pos", "=", "1"],["status", "!=", "Cancelled"],["posting_date", "=", "' + reportDate + '"],["project", "=", "' + this.state.inv_project + '"]]';
        let limit = '&limit_page_length=0';
        let api_todayinv = fAppAPI() + "api/resource/Sales Invoice/?" + todayinv_field + todayinv_filter + limit;
        return fetch(api_todayinv, {
            method: 'GET', credentials: 'include'
        })
            .then((res) => res.json())
            .then((resj) => {
                this.setState({
                    invoices: resj.data,
                })
            })
            .then(() => {
                //console.log('getting the return invoice for today...');
                let todayinv_field = 'fields=["name"]';
                let todayinv_filter = '&filters=[["is_pos", "=", "1"],["is_return", "=", "1"],["status", "!=", "Cancelled"],["posting_date", "=", "' + reportDate + '"],["project", "=", "' + this.state.inv_project + '"]]';
                let limit = '&limit_page_length=0';
                let api_todayinv = fAppAPI() + "api/resource/Sales Invoice/?" + todayinv_field + todayinv_filter + limit;
                return fetch(api_todayinv, {
                    method: 'GET', credentials: 'include'
                })
                    .then((res) => res.json())
                    .then((resj) => {
                        let data = resj.data;
                        if (data.length > 0) {
                            console.log('returns has data, assign to state');
                            this.setState({
                                returns: resj.data
                            }, function () {
                                //console.log('invoices fetched');
                                //console.log(this.state.invoices);
                                this.getTotalReturns();
                                this.getTotalByModeOfPayment(true)
                                //this.mapInvoiceForReport()
                                this.timeoutHandle = setTimeout(() => {
                                    this.updateInvTotal('closing')
                                }, 5000)
                            })
                        }
                        else {
                            console.log('returns has NO data, proceed...')
                            this.getTotalByModeOfPayment(true)
                            //this.mapInvoiceForReport()
                            this.timeoutHandle = setTimeout(() => {
                                //this.closeModal()
                                this.updateInvTotal('closing')
                                //this.toggleModal('closing')
                            }, 5000)
                        }
                    })
            })
            .catch((error) => {
                this.setState({ appFlow: 'error' })
            })
    }

    reloadReport() {
        this.closeModal();
        this.prepReport(this.state.report_date);
    }

    reloadStock() {
        this.closeModal();
        this.prepStock();
    }

    resetStock() {
        this.setState({
            selectedProduct: null
        }, function () {
            this.closeModal();
            this.prepStock();
        })
    }

    async getReport(date) {
        this.toggleModal('spinner');
        console.log('ASYNC @getReport exec');
        console.log('clear all states')

        this.setState({
            invoices: [],
            inv_cash: 0.00,
            inv_creditcard: 0.00,
            inv_epayment: 0.00,
            inv_boost: 0.00,
            inv_quinpay: 0.00,
            inv_splus: 0.00,
            inv_cheque: 0.00,
            inv_total: 0.00,
            returns: [],
            ret_cash: 0.00,
            ret_creditcard: 0.00,
            ret_epayment: 0.00,
            report_type: 'Product',
            report_type_text: 'Invoice',
            report_item_data: [],
            isReturn: false,
            showUtilBtn: true,
        })

        console.log('states cleared')

        let reportDate = '';
        if (date === undefined || date === null) {
            reportDate = this.state.inv_posting_date;
            this.setState({
                report_date: moment()
            })
        }
        else {
            reportDate = moment(date).format('YYYY-MM-DD');
        }

        // 1. get all invoices by today date
        //console.log('get all invoice with posting_date: ' + reportDate + ' AND project ' + this.state.inv_project);
        let todayinv_field = 'fields=["name", "status"]';
        let todayinv_filter = '&filters=[["is_pos", "=", "1"],["status", "!=", "Cancelled"],["posting_date", "=", "' + reportDate + '"],["project", "=", "' + this.state.inv_project + '"]]';
        let limit = '&limit_page_length=0';
        let api_todayinv = fAppAPI() + "api/resource/Sales Invoice/?" + todayinv_field + todayinv_filter + limit;

        console.log('attempt to call api GET', api_todayinv)
        this.getTodayInvs(api_todayinv)
    }

    async getTodayInvs(api) {
        try {
            const datas = await fGetReport(api)
            if (datas !== -1) {
                console.log('datas fetched')
                console.log(datas.length)

                this.setState({
                    invoices: datas
                }, function () {
                    this.updateInvTotal('dailyreport')
                })
            }
        }
        catch (err) {
            this.closeModal()
            this.setState({ appFlow: 'error' })

            console.log('error @getReport')
            console.log(err)
        }
    }

    prepReport(date) {
        //console.log('@prepReport exec');
        this.toggleModal('spinner');
        this.setState({
            invoices: [],
            inv_cash: 0.00,
            inv_creditcard: 0.00,
            inv_epayment: 0.00,
            inv_boost: 0.00,
            inv_quinpay: 0.00,
            inv_splus: 0.00,
            inv_cheque: 0.00,
            inv_total: 0.00,
            returns: [],
            ret_cash: 0.00,
            ret_creditcard: 0.00,
            ret_epayment: 0.00,
            report_type: 'Product',
            report_type_text: 'Invoice',
            report_item_data: [],
            isReturn: false,
            showUtilBtn: true,
        })
        // get all the invoices for today date
        // date format is year-month-date
        // then each total push in array
        // get the array sum
        let reportDate = '';
        if (date === undefined || date === null) {
            reportDate = this.state.inv_posting_date;
            this.setState({
                report_date: moment()
            })
        }
        else {
            reportDate = moment(date).format('YYYY-MM-DD');
        }

        // 1. get all invoices by today date
        //console.log('get all invoice with posting_date: ' + reportDate + ' AND project ' + this.state.inv_project);
        let todayinv_field = 'fields=["name", "status"]';
        let todayinv_filter = '&filters=[["is_pos", "=", "1"],["status", "!=", "Cancelled"],["posting_date", "=", "' + reportDate + '"],["project", "=", "' + this.state.inv_project + '"]]';
        let limit = '&limit_page_length=0';
        let api_todayinv = fAppAPI() + "api/resource/Sales Invoice/?" + todayinv_field + todayinv_filter + limit;

        return fetch(api_todayinv, {
            method: 'GET', credentials: 'include'
        })
            .then((res) => res.json())
            .then((resj) => {
                this.setState({
                    invoices: resj.data,
                })
            })
            .then(() => {
                console.log('getting the return invoice for today...');

                let todayinv_field = 'fields=["name", "return_against", "posting_date"]';
                let todayinv_filter = '&filters=[["is_pos", "=", "1"],["is_return", "=", "1"],["status", "!=", "Cancelled"],["posting_date", "=", "' + reportDate + '"],["project", "=", "' + this.state.inv_project + '"]]';
                let limit = '&limit_page_length=0';
                let api_todayinv = fAppAPI() + "api/resource/Sales Invoice/?" + todayinv_field + todayinv_filter + limit;

                return fetch(api_todayinv, {
                    method: 'GET', credentials: 'include'
                })
                    .then((res) => res.json())
                    .then((resj) => {

                        let data = resj.data;

                        if (data.length > 0) {
                            console.log('returns has data, assign to state');
                            console.log('we need to filter returns against, only get the one that is agains today invoices');

                            let validreturns = [];

                            for (let i = 0; i < data.length; i++) {
                                console.log('checking return aginst invoice: ' + data[i].return_against)

                                let api = fAppAPI() + 'api/resource/Sales Invoice/' + data[i].return_against
                                return fetch(api, {
                                    method: 'GET', credentials: 'include'
                                })
                                    .then((resp) => resp.json())
                                    .then((resj) => {
                                        console.log('include to returns regardless date')
                                        console.log('instructed by kelly and hazel on the 22nd december 2019')

                                        let d = { name: data[i].name }
                                        validreturns.push(d)
                                        /*
                                        if(resj.data.posting_date === data[i].posting_date) {
                                            let d = {name:data[i].name}
                                            validreturns.push(d)
                                        }
                                        else {
                                            console.log(resj.data.name+' is excluded due to date difference')
                                        }
                                        */
                                    })
                                    .then(() => {
                                        this.setState({
                                            returns: validreturns
                                        }, function () {
                                            //console.log('invoices fetched');
                                            //console.log(this.state.invoices);
                                            this.getTotalReturns();
                                            this.getTotalByModeOfPayment(true)
                                            //this.mapInvoiceForReport()
                                            this.timeoutHandle = setTimeout(() => {
                                                //this.closeModal()
                                                this.updateInvTotal('dailyreport')
                                                //this.toggleModal('dailyreport')
                                            }, 5000)
                                        })
                                    })
                            }
                        }
                        else {
                            console.log('returns has NO data, proceed...')
                            this.getTotalByModeOfPayment(true)
                            //this.mapInvoiceForReport()
                            this.timeoutHandle = setTimeout(() => {
                                //this.closeModal()
                                this.updateInvTotal('dailyreport')
                                //this.toggleModal('dailyreport')
                            }, 5000)
                        }
                    })
            })
            .catch((error) => {
                this.setState({ appFlow: 'error' })
            })
    }

    getTotalInvoice(invoice) {
        let report = this.state.report;
        let i = report.findIndex(inv => inv.name === invoice)
        if (i !== -1) {
            return report[i].total.toFixed(2);
        }
        else {
            return 0.00;
        }
    }

    getTotalByModeOfPayment(isreport) {
        this.setState({ report: [] })
        let inv = this.state.invoices;

        let cash = [];
        let cc = [];
        let epayment = [];
        let boost = [];
        let quinpay = [];
        let splus = [];
        let cheque = [];

        for (let i = 0; i < inv.length; i++) {
            let api = fAppAPI() + 'api/resource/Sales Invoice/' + inv[i].name
            fetch(api, {
                method: 'GET', credentials: 'include'
            })
                .then((res) => res.json())
                .then((resj) => {
                    let change = resj.data.change_amount;
                    //console.log('change for invoice: ' + inv[i].name)
                    //console.log(change)

                    let payments = resj.data.payments;
                    //console.log('payments data');
                    //console.log(payments);

                    if (inv[i].status !== 'Draft' && inv[i].is_return != 1) {
                        //console.log('payments data for ' +inv[i].name)
                        //console.log(payments)
                        for (let p = 0; p < payments.length; p++) {
                            if (payments[p].mode_of_payment === 'Cash') {
                                let cashamount = Number(payments[p].amount) - Number(change);

                                //console.log('cashamount for '+inv[i].name)
                                //console.log(cashamount)

                                cash.push(cashamount)
                                let add = (a, b) => a + b;
                                let gt = cash.reduce(add);
                                this.setState({ inv_cash: gt }, function () {
                                    //console.log('cash sales: ' + this.state.inv_cash)
                                    //console.log('cash change is: ' + change)
                                })
                            }
                            else if (payments[p].mode_of_payment === 'Credit Card') {
                                cc.push(Number(payments[p].amount))
                                let add = (a, b) => a + b;
                                let gt = cc.reduce(add);
                                this.setState({ inv_creditcard: gt }, function () {
                                    //console.log('creditcard sales: ' + this.state.inv_creditcard)
                                })
                            }
                            else if (payments[p].mode_of_payment === 'E-Payment') {
                                epayment.push(Number(payments[p].amount))
                                let add = (a, b) => a + b;
                                let gt = epayment.reduce(add);
                                this.setState({ inv_epayment: gt }, function () {
                                    //console.log('epayment sales: ' + this.state.inv_epayment)
                                })
                            }
                            else if (payments[p].mode_of_payment === 'Boost') {
                                boost.push(Number(payments[p].amount))
                                let add = (a, b) => a + b;
                                let gt = boost.reduce(add);
                                this.setState({ inv_boost: gt }, function () {
                                    //console.log('boost sales: ' + this.state.inv_boost)
                                })
                            }
                            else if (payments[p].mode_of_payment === 'Quinpay') {
                                quinpay.push(Number(payments[p].amount))
                                let add = (a, b) => a + b;
                                let gt = quinpay.reduce(add);
                                this.setState({ inv_quinpay: gt }, function () {
                                    console.log('quinpay sales: ' + this.state.inv_quinpay)
                                })
                            }
                            else if (payments[p].mode_of_payment === 'Splus') {
                                splus.push(Number(payments[p].amount))
                                let add = (a, b) => a + b;
                                let gt = splus.reduce(add);
                                this.setState({ inv_splus: gt }, function () {
                                    console.log('splus sales: ' + this.state.inv_splus)
                                })
                            }
                            else if (payments[p].mode_of_payment === 'Cheque') {
                                cheque.push(Number(payments[p].amount))
                                let add = (a, b) => a + b;
                                let gt = cheque.reduce(add);
                                this.setState({ inv_cheque: gt }, function () {
                                    //console.log('cheque sales: ' + this.state.inv_cheque)
                                })
                            }
                        }
                    }

                    if (isreport === true) {
                        if (resj.data.status === 'Draft' && resj.data.net_total > 0) {
                            console.log('invoice: ' + resj.data.name + ' is draft, excluding from report')
                        }
                        else {
                            let report = this.state.report
                            let newReportEntry = [
                                ...report, {
                                    name: resj.data.name,
                                    status: resj.data.status,
                                    customer: resj.data.customer,
                                    total: resj.data.net_total,
                                    date: resj.data.posting_date,
                                    time: resj.data.creation,
                                    payments: resj.data.payments
                                }]
                            this.setState({
                                report: newReportEntry
                            }, function () {
                                //console.log('report entry updated')
                                //console.log(this.state.report)
                            })
                            let items = resj.data.items;
                            //console.log('items for ' + resj.data.name);
                            //console.log(items);

                            for (let i = 0; i < items.length; i++) {
                                let itemdata = this.state.report_item_data;
                                if (items[i].qty > 0) {
                                    let dataIndex = itemdata.findIndex(d => d.code === items[i].item_code);
                                    if (dataIndex === -1) {
                                        let newReportItemDataEntry = [
                                            ...itemdata, {
                                                code: items[i].item_code,
                                                name: items[i].item_name,
                                                qty: items[i].qty,
                                                rate: items[i].rate,
                                                balance: items[i].actual_qty,
                                                invoice: resj.data.name
                                            }];
                                        this.setState({
                                            report_item_data: newReportItemDataEntry
                                        }, function () {
                                            //console.log('new item entry')
                                            //console.log('report_item_data state updated');
                                            //console.log(this.state.report_item_data);
                                        })
                                    }
                                    else {
                                        let curQty = itemdata[dataIndex].qty;
                                        let newQty = curQty + items[i].qty;
                                        itemdata[dataIndex] = { ...itemdata[dataIndex], qty: newQty }
                                        this.setState({ itemdata }, function () {
                                            //console.log('updating item entry')
                                            //console.log('report_item_data state updated');
                                            //console.log(this.state.report_item_data);
                                        })
                                    }
                                }
                                else {
                                    //console.log('item qty is negative')
                                    //console.log(items[i].qty)
                                }
                            }
                        }
                    }
                })
                .catch((error) => {
                    this.setState({ appFlow: 'error' });
                    console.log('@getTotalByModeOfPayment error');
                    console.log(error);
                })
        }
    }

    getTotalReturns() {
        let returns = this.state.returns;
        console.log('calculating returns amount...');
        console.log('returns invoices length: ' + returns.length);

        let cash = [];
        let cc = [];
        let epayment = [];

        for (let i = 0; i < returns.length; i++) {
            let api = fAppAPI() + 'api/resource/Sales Invoice/' + returns[i].name
            console.log('getting invoice data for ' + returns[i].name)
            fetch(api, {
                method: 'GET', credentials: 'include'
            })
                .then((res) => res.json())
                .then((resj) => {
                    let payments = resj.data.payments;
                    //console.log(payments)
                    for (let p = 0; p < payments.length; p++) {
                        if (payments[p].mode_of_payment === 'Cash') {
                            let cashamount = Number(payments[p].amount);
                            cash.push(cashamount)
                            let add = (a, b) => a - b;
                            let gt = cash.reduce(add);
                            this.setState({ ret_cash: gt }, function () {
                                console.log('cash returns: ' + this.state.ret_cash)
                            })
                        }
                        else if (payments[p].mode_of_payment === 'Credit Card') {
                            cc.push(Number(payments[p].amount))
                            let add = (a, b) => a + b;
                            let gt = cc.reduce(add);
                            this.setState({ ret_creditcard: gt }, function () {
                                console.log('creditcard returns: ' + this.state.ret_creditcard)
                            })
                        }
                        else if (payments[p].mode_of_payment === 'E-Payment') {
                            epayment.push(Number(payments[p].amount))
                            let add = (a, b) => a + b;
                            let gt = epayment.reduce(add);
                            this.setState({ ret_epayment: gt }, function () {
                                console.log('epayment returns: ' + this.state.ret_epayment)
                            })
                        }
                    }
                })
                .catch((error) => {
                    this.setState({ appFlow: 'error' });
                    console.log('@getTotalReturns error');
                    console.log(error);
                })
        }
    }

    printInvoice(invoice, ishistory) {
        this.setState({
            inv_name: invoice,
        }, function () {
            this.closeModal();
            if (ishistory === true) {
                this.toggleModal('printhistoryinv');
            }
            else {
                this.toggleModal('printreportinv');
            }
        })
    }

    invStatus(status, total) {
        if (status === 'Draft') {
            if (total < 0) {
                return 'Return'
            }
            else {
                return 'Draft'
            }
        }
        else {
            return status
        }
        /*
        if(status === 'Draft') {
          return 'Draft'
        }
        else {
          return status
        }
        */
    }

    toggleReportType() {
        let type = this.state.report_type;
        if (type === 'Product') {
            this.setState({
                report_type: 'Invoice',
                report_type_text: 'Product'
            })
        }
        else {
            this.setState({
                report_type: 'Product',
                report_type_text: 'Invoice'
            })
        }
    }

    toggleThead() {
        if (this.state.report_type === 'Invoice') {
            return (
                <tr>
                    <th>#</th>
                    <th>Invoice</th>
                    <th>Payment Type</th>
                    <th>Customer</th>
                    <th>Time</th>
                    <th style={{ textAlign: 'right' }}>Total</th>
                </tr>
            )
        }
        else {
            return (
                <tr>
                    <th>#</th>
                    <th>Item</th>
                    <th>Qty</th>
                </tr>
            )
        }
    }

    invPayMode(payments) {
        let modes = [];
        if (payments.length > 0) {
            for (let i = 0; i < payments.length; i++) {
                modes.push(payments[i].mode_of_payment)
            }
            return modes.join(', ');
        }
    }

    sortReport(report) {
        report.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
        });
    }

    renderReport(ishistory) {
        let by = this.state.report_type;
        let report = this.state.report;
        //let invoices = this.state.report
        //console.log(report);

        report = report.sort(function (a, b) {
            return new Date(b.time) - new Date(a.time);
        });

        if (report.length > 0) {
            if (ishistory === true) {
                return report.map((item, index) => {
                    let time = moment(item.time).format('hh:mm A');
                    return (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                                <Button className="app-btn" onClick={() => this.printInvoice(item.name, true)}>
                                    #{item.name} {this.invStatus(item.status, item.total)}
                                </Button>
                            </td>
                            <td>{this.invPayMode(item.payments)}</td>
                            <td>{item.date}</td>
                            <td>{time}</td>
                            <td align="right">{item.total.toFixed(2)}</td>
                        </tr>
                    )
                })
            }
            else if (by === 'Invoice') {
                return report.map((item, index) => {
                    let time = moment(item.time).format('hh:mm A');
                    return (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                                <Button className="app-btn" onClick={() => this.printInvoice(item.name)}>
                                    #{item.name} {this.invStatus(item.status, item.total)}
                                </Button>
                            </td>
                            <td>{this.invPayMode(item.payments)}</td>
                            <td>{item.customer.toUpperCase()}</td>
                            <td>{time}</td>
                            <td>{item.total.toFixed(2)}</td>
                        </tr>
                    )
                })
            }
            else if (by === 'Product') {
                let items = this.state.report_item_data;

                return items.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.code} - {item.name}</td>
                            <td align="center">{item.qty}</td>
                        </tr>
                    )
                })
            }
            else {
                let report = this.state.report;
                return report.map((item, index) => {
                    let time = moment(item.time).format('hh:mm A');
                    return (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                                <Button className="app-btn" onClick={() => this.printInvoice(item.name)}>
                                    #{item.name} {this.invStatus(item.status, item.total)}
                                </Button>
                            </td>
                            <td>{item.customer}</td>
                            <td>{time}</td>
                            <td align="right">{item.total.toFixed(2)}</td>
                        </tr>
                    )
                })
            }
        }
        else {
            if (ishistory === true) {
                return (
                    <tr>
                        <td colSpan="6">
                            No Purchase History For The Selected Customer
                        </td>
                    </tr>
                )
            }
            else if (by === 'Invoice') {
                return (
                    <tr>
                        <td colSpan="6">
                            No Sales For The Selected Date
                        </td>
                    </tr>
                )
            }
            else {
                return (
                    <tr>
                        <td colSpan="4">
                            No Sales For The Selected Date
                        </td>
                    </tr>
                )
            }
        }
    }

    updateInvTotal(modal) {
        //console.log('@updateInvTotal exec')
        let cash = this.state.inv_cash;
        let cc = this.state.inv_creditcard;
        let ep = this.state.inv_epayment;
        let chq = this.state.inv_cheque;
        let bst = this.state.inv_boost;
        let qpay = this.state.inv_quinpay;
        let spls = this.state.inv_splus;
        let val = cash + cc + ep + bst + qpay + spls + chq;

        let retc = this.state.ret_cash;
        let retcc = this.state.ret_creditcard;
        let retep = this.state.ret_epayment;

        let ret = retc + retcc + retep;

        let gt = val;
        //console.log('adding vals -> ' + cash + '+' + cc + '+' + ep + '+' + chq)
        //console.log('new val: ' + val)
        this.setState({
            inv_total: gt.toFixed(2)
        }, function () {
            console.log('invoice total')
            console.log(this.state.inv_total)
            this.toggleModal(modal)
        })
    }

    //--------------------------------------------------------------------------
    // @config @account
    // @new payment method
    // 1. In backend, set the payment method under Account
    // 2. Then ensure all states with that new payment is set in here
    setAccountSettings(outlet) {
        let com = '';
        let company = '';
        let project = '';
        let costcenter = '';
        let wh = '';
        let cash = '';
        let sales = '';
        let debtor = '';
        let bank = '';
        let creditcard = '';
        let boost = '';
        let epayment = '';
        let cheque = '';
        let quinpay = '';
        let splus = '';

        switch (outlet) {
            case 'Bundusan':
                wh = 'Outlet Bundusan - PPSB';
                com = 'PPSB';
                company = 'Paragon Pets Sdn Bhd';
                project = 'Outlet Bundusan';
                costcenter = 'Project Outlet Bundusan Cost Center';
                cash = '3700/001 - Cash Outlet Bundusan - PPSB';
                sales = '5000/000 - CASH SALES - PPSB';
                debtor = 'CASH SALES DEBTOR - PPSB';
                bank = '3500/001 - PBB - 3987750826 - PPSB';
                creditcard = '3500/001 - PBB - 3987750826 - PPSB'; //
                boost = '3500/003 - CIMB - 8603327551 - PPSB';
                quinpay = '3500/003 - CIMB - 8603327551 - PPSB';
                splus = '3500/003 - CIMB - 8603327551 - PPSB';
                epayment = '3500/001 - PBB - 3987750826 - PPSB'; //
                cheque = '3500/001 - PBB - 3987750826 - PPSB';
                // if epayment boost = 551
                // if epayment != boost = 215
                // cheque 215
                // credit card bank 826
                break;

            case 'Luyang':
                wh = 'Outlet Luyang - PPSB';
                com = 'PPSB';
                company = 'Paragon Pets Sdn Bhd';
                project = 'Outlet Luyang'
                costcenter = 'Project Outlet Luyang Cost Center';
                cash = '3700/000 - Cash Outlet Luyang - PPSB';
                sales = '5000/000 - CASH SALES - PPSB';
                debtor = 'CASH SALES DEBTOR - PPSB';
                bank = '3500/001 - PBB - 3987750826 - PPSB';
                creditcard = '3500/001 - PBB - 3987750826 - PPSB'; //
                boost = '3500/003 - CIMB - 8603327551 - PPSB';
                quinpay = '3500/003 - CIMB - 8603327551 - PPSB';
                splus = '3500/003 - CIMB - 8603327551 - PPSB';
                epayment = '3500/001 - PBB - 3987750826 - PPSB';
                cheque = '3500/001 - PBB - 3987750826 - PPSB';
                break;

            case 'Luyang 2':
                wh = 'Outlet Luyang - PPSB';
                com = 'PPSB';
                company = 'Paragon Pets Sdn Bhd';
                project = 'Outlet Luyang 2'
                costcenter = 'Project Outlet Luyang Cost Center';
                cash = '3700/000 - Cash Outlet Luyang - PPSB';
                sales = '5000/000 - CASH SALES - PPSB';
                debtor = 'CASH SALES DEBTOR - PPSB';
                bank = '3500/001 - PBB - 3987750826 - PPSB';
                creditcard = '3500/001 - PBB - 3987750826 - PPSB'; //
                boost = '3500/003 - CIMB - 8603327551 - PPSB';
                quinpay = '3500/003 - CIMB - 8603327551 - PPSB';
                splus = '3500/003 - CIMB - 8603327551 - PPSB';
                epayment = '3500/001 - PBB - 3987750826 - PPSB';
                cheque = '3500/001 - PBB - 3987750826 - PPSB';
                break;


            // @pk9f
            // @cimb
            // 3500/001 CIMB 8603 4506 95
            // epayment/boost/cheque
            // credit card remained
            case 'Keningau':
                wh = 'Outlet Keningau - PK9F';
                com = 'PK9F';
                company = 'Paragon K-9 Force Sdn Bhd';
                project = 'Outlet Keningau';
                costcenter = 'Project Outlet Keningau Cost Center';
                cash = '3700/003 - Cash Outlet Keningau - PK9F';
                sales = '5000/000 - CASH SALES - PK9F';
                debtor = 'Debtors - PK9F';
                bank = '3500/003 - CIMB - 86-0345069-5 - PK9F';
                //creditcard = '3500/001 - PBB - 3987750826 - PK9F';
                creditcard = '3700/001 - Cash Outlet Keningau Credit Card - PK9F'; // requested by Esther in June 2019
                boost = '3500/003 - CIMB - 86-0345069-5 - PK9F';
                quinpay = '3500/003 - CIMB - 8603327551 - PPSB';
                splus = '3500/003 - CIMB - 8603327551 - PPSB';
                epayment = '3500/003 - CIMB - 86-0345069-5 - PK9F';
                cheque = '3500/003 - CIMB - 86-0345069-5 - PK9F';
                break;

            case 'Kudat':
                wh = 'Outlet Kudat - PK9F';
                com = 'PK9F';
                company = 'Paragon K-9 Force Sdn Bhd';
                project = 'Outlet Kudat';
                costcenter = 'Project Outlet Kudat Cost Center';
                cash = '3700/004 - Cash Outlet Kudat - PK9F';
                sales = '5000/000 - CASH SALES - PK9F';
                debtor = 'Debtors - PK9F';
                bank = '3500/003 - CIMB - 86-0345069-5 - PK9F';
                creditcard = '3500/001 - PBB - 3987750826 - PK9F';
                boost = '3500/003 - CIMB - 86-0345069-5 - PK9F';
                quinpay = '3500/003 - CIMB - 86-0345069-5 - PK9F';
                splus = '3500/003 - CIMB - 86-0345069-5 - PK9F';
                epayment = '3500/003 - CIMB - 86-0345069-5 - PK9F';
                cheque = '3500/003 - CIMB - 86-0345069-5 - PK9F';
                break;

            case 'Tawau':
                wh = 'Outlet Tawau - PK9F';
                com = 'PK9F';
                company = 'Paragon K-9 Force Sdn Bhd';
                project = 'Outlet Tawau';
                costcenter = 'Project Outlet Tawau Cost Center';
                cash = '3700/002 - Cash Outlet Tawau - PK9F';
                sales = '5000/000 - CASH SALES - PK9F';
                debtor = 'Debtors - PK9F';
                bank = '3500/003 - CIMB - 86-0345069-5 - PK9F';
                creditcard = '3500/001 - PBB - 3987750826 - PK9F';
                boost = '3500/003 - CIMB - 86-0345069-5 - PK9F';
                quinpay = '3500/003 - CIMB - 86-0345069-5 - PK9F';
                splus = '3500/003 - CIMB - 86-0345069-5 - PK9F';
                epayment = '3500/003 - CIMB - 86-0345069-5 - PK9F';
                cheque = '3500/003 - CIMB - 86-0345069-5 - PK9F';
                break;

            default: return null;
        }

        /*
        let walkinCompanyCustomerName = 'Walk In (' + this.state.company + ')';
        let walkinCompanyCustomerID = 'Walk In (' + this.state.company + ')';
        if(outlet === 'Luyang 2') {
            // set customer name, not iD
            walkinCompanyCustomerName = 'Walk In (Likas)';
        }
        */


        this.setState({
            inv_debit_to: debtor,
            inv_account_cash: cash,
            inv_againts_change: cash,
            inv_account_bank: bank,
            inv_account_creditcard: creditcard,
            inv_account_boost: boost,
            inv_account_epayment: epayment,
            inv_account_cheque: cheque,
            inv_warehouse: wh,
            inv_company: company,
            inv_costcenter: costcenter + ' - ' + com,
            inv_project: project,
            inv_income_acc: sales,
            inv_againts_income: sales,
            customer: 'Walk In (' + this.state.company + ')',
            customerID: 'Walk In (' + this.state.company + ')'
        }, function () {
            console.log('@setAccountSettings');
            console.log('debtors: ' + this.state.inv_debit_to);
            console.log('cash: ' + this.state.inv_account_cash);
            console.log('bank: ' + this.state.inv_account_bank);
            console.log('creditcard: ' + this.state.inv_creditcard);
            console.log('boost/quinpay/splus: ' + this.state.inv_account_boost);
            console.log('epayment: ' + this.state.inv_account_epayment);
            console.log('cheque: ' + this.state.inv_account_cheque);
            console.log('warehouse: ' + this.state.inv_warehouse);
            console.log('project: ' + this.state.inv_project);
            console.log('costcenter: ' + this.state.inv_costcenter);
            console.log('againts_income_account: ' + this.state.inv_againts_income);
            console.log('account_for_change_amount: ' + this.state.inv_againts_change);
            console.log('posting_date: ' + this.state.inv_posting_date);
            console.log('outlet:', outlet);
            console.log('refer to comment when adding new payment method');
            this.setWalkIn(outlet);
            this.getOutletAddress(this.state.inv_project);
            //this.getTaxRate(outlet);
        })
    }

    getOutletAddress(project) {
        //console.log('@getOutletAddress exec');

        let fields = '?fields=["outlet_address"]';
        let filter = '&filters=[["name", "=", "' + project + '"]]';
        let api = fAppAPI() + 'api/resource/Project/' + fields + filter

        //console.log('making an api call: ' + api);
        return fetch(api, {
            method: 'GET', credentials: 'include'
        })
            .then((resp) => resp.json())
            .then((resj) => {
                let data = resj.data[0];
                this.setState({
                    inv_address: data.outlet_address
                }, function () {
                    console.log('address set');
                    console.log(this.state.inv_address)
                })
            })
            .catch((error) => {
                this.setState({ appFlow: 'error' }, function () {
                    //console.log('error @getOutletAddress method');
                    //console.log(error);
                })
            })
    }

    setWalkIn(outlet) {
        let ext = '(' + outlet + ')';
        let val = 'Walk In ' + ext;

        let cLabel = val;
        let cID = val;

        // set customer name and ID
        /*
        if(outlet === 'Luyang 2') {
            cLabel = 'Walk In (Likas)';
            cID = 'Walkin (Luyang 2)';
        }
        */

        this.setState({
            customer: cLabel,
            customerID: cID,
            selectedCustomer: { value: cID, label: cLabel }
        }, function () {
            console.log('selected customer is: ');
            console.log(this.state.selectedCustomer)
        })
    }

    getBdayCode() {
        console.log('bday treats does not require code')
        /*
        let bdayyear = this.state.bdayyear;
        let fields = '?fields=["code","discount"]';
        let filter = '&filters=[["year", "=", "' +bdayyear+ '"]]';
        let api = fAppAPI() + 'api/resource/Birthday Treats Code/'+fields+filter;

        console.log('@getBdayCode, calling API')
        console.log(api)

        return fetch(api,{
          method:'GET',
          credentials:'include'
        })
        .then((resp) => resp.json())
        .then((resj) => {
          let data = resj.data[0];
          console.log('@getBdayCode, params fetched');
          console.log(data);
          this.setState({
            bdaycode:data.code,
            bdaydiscount:data.discount
          },function(){
            console.log('Birthday Treats Code: ' + this.state.bdaycode);
            console.log('Birthday Treats Discount: ' + this.state.bdaydiscount);
          })
        })
        .catch((error) => {
          console.log('error @getBdayCode');
          console.log(error);
        })
        */
    }

    getMRPTier() {
        /*
        if(appMode === 'develop') {
          let fields = '?fields=["min_member", "max_member", "percentage"]';
          let api = fAppAPI() + 'api/resource/MRP Settings/'+fields;
          return fetch(api,{
            method:'GET',
            credentials:'include'
          })
          .then((resp) => resp.json())
          .then((resj) => {
            this.setState({
              mrptier:resj.data
            },function(){
              console.log('mrp tier fetched:');
              console.log(this.state.mrptier);
              this.setMRPmax();
            })
          })
          .catch((err) => {
            console.log('@getMRPTier error');
            console.log(err);
          })
        }
        */
        this.getMRPData()
    }

    getMRPData() {
        let api = fAppAPI() + 'api/resource/MRP Config/MRP Config'
        return fetch(api, {
            method: 'GET',
            credentials: 'include'
        })
            .then((resp) => resp.json())
            .then((resj) => {
                this.setState({
                    mrpdata: resj.data
                }, function () {
                    let mrpdata = this.state.mrpdata
                    this.setMRPmax()
                    console.log('mrpdata fetched')
                    console.log(mrpdata)
                    this.setState({
                        mrpenabled: mrpdata.enable
                    }, function () {
                        console.log('mrp enabled: ' + this.state.mrpenabled)
                    })
                })
            })
    }

    setMRPmax() {
        //let mrp = this.state.mrptier;
        let mrp = this.state.mrpdata.settings;
        let max = [];
        for (let i = 0; i < mrp.length; i++) {
            max.push(mrp[i].max_member);
        }
        let sum = max.reduce((a, b) => a + b, 0);
        this.setState({
            mrpmax: sum
        }, function () {
            console.log('max tier: ' + this.state.mrpmax);
        })
    }

    getMRPrate(count) {
        console.log('checking mrp rate')
        console.log('mrp enabled: ' + this.state.mrpenabled)
        let val = count;
        let m = this.state.mrpdata.settings;
        if (m.length > 0) {
            let n = parseInt(val, 10);

            for (let i = 0; i < m.length; i++) {
                if (m[i].min_member <= n && n <= m[i].max_member) {
                    this.setState({
                        mrprate: m[i].percentage
                    })
                    return m[i].percentage;
                }
            }
        }
        else {
            return 0.00;
        }
    }

    getTaxRate(outlet) {
        //console.log('@getTaxRate exec')

        let taxname = '';
        switch (outlet) {
            case 'Bundusan': taxname = 'GST SR - PPSB'; break;
            case 'Luyang': taxname = 'GST SR - PLY'; break;
            case 'Luyang 2': taxname = 'GST SR - PLY'; break;
            case 'Kudat': taxname = 'GST SR - PKD'; break;
            case 'Tawau': taxname = 'GST SR - PTW'; break;
            case 'Sandakan': taxname = 'GST SR - PSK'; break;
            default: taxname = 'GST SR - PPSB'; break;
        }
        //console.log('tax name is ' + taxname)
        //console.log('fetching rate...')
        let api = fAppAPI() + 'api/resource/Account/' + taxname;
        return fetch(api, {
            method: 'GET', credentials: 'include'
        })
            .then((res) => res.json())
            .then((resj) => {
                this.setState({
                    taxrate: resj.data.tax_rate
                }, function () {
                    //console.log('tax rate fetced: ' + this.state.taxrate);
                })
            })
    }

    /** @NOTE end configuration */
    //--------------------------------------------------------------------------
    /**
    * @NOTE Sales Invoice payment prep
    */

    mapItemForSubmit() {
        let item = this.state.item;
        return item.map((cart, index) => {
            return (
                {
                    "item_code": cart.item_code,
                    "qty": cart.qty,
                    "rate": cart.rate,
                    "amount": cart.amount,
                    "discount_percentage": cart.discount_percentage,
                    "discount_on_price": cart.discount_on_price,
                    "warehouse": this.state.inv_warehouse,
                    "cost_center": this.state.inv_costcenter
                }
            );
        })
    }

    getCashPayment() {
        if (this.state.cash > 0) {
            return (
                {
                    "mode_of_payment": "Cash",
                    "base_amount": Number(this.state.paid),
                    "amount": Number(this.state.paid),
                    "account": this.state.inv_account_cash
                }
            )
        }
        else {
            return null;
        }
    }

    getCreditCardPayment() {
        if (this.state.creditcard > 0) {
            return (
                {
                    "mode_of_payment": "Credit Card",
                    "base_amount": Number(this.state.creditcard),
                    "amount": Number(this.state.creditcard),
                    "account": this.state.inv_account_creditcard
                }
            )
        }
        else {
            return null;
        }
    }

    getChequePayment() {
        if (this.state.cheque > 0) {
            return (
                {
                    "mode_of_payment": "Cheque",
                    "base_amount": Number(this.state.cheque),
                    "amount": Number(this.state.cheque),
                    "account": this.state.inv_account_cheque
                }
            )
        }
        else {
            return null;
        }
    }

    getEPayment() {
        if (this.state.epayment > 0) {
            if (this.state.epaymenttype === 'Boost') {
                return (
                    {
                        "mode_of_payment": "Boost",
                        "base_amount": Number(this.state.epayment),
                        "amount": Number(this.state.epayment),
                        "account": this.state.inv_account_boost
                    }
                )
            }
            if (this.state.epaymenttype === 'Quinpay') {
                return (
                    {
                        "mode_of_payment": "Quinpay",
                        "base_amount": Number(this.state.epayment),
                        "amount": Number(this.state.epayment),
                        "account": this.state.inv_account_boost
                    }
                )
            }
            if (this.state.epaymenttype === 'Splus') {
                return (
                    {
                        "mode_of_payment": "Splus",
                        "base_amount": Number(this.state.epayment),
                        "amount": Number(this.state.epayment),
                        "account": this.state.inv_account_boost
                    }
                )
            }
            else {
                return (
                    {
                        "mode_of_payment": "E-Payment",
                        "base_amount": Number(this.state.epayment),
                        "amount": Number(this.state.epayment),
                        "account": this.state.inv_account_epayment
                    }
                )
            }
        }
        else {
            return null;
        }
    }

    /** @NOTE end configuration */
    //--------------------------------------------------------------------------
    /** @NOTE payment, membership upgrade & member registration */

    checkCash() {
        /*
        let cash = this.state.cash;
        let change = this.state.change;
        let creditcard = this.state.creditcard;
        let paid = this.state.paid;
        let epayment = this.state.epayment;
        let cheque = this.state.cheque;
        */

        let cash = 0.00;
        let change = 0.00;
        let creditcard = 0.00;
        let paid = 0.00;
        let epayment = 0.00;
        let cheque = 0.00;
        let totalpaid = 0.00;
        let sum = 0.00;

        if (this.state.cash !== '') { cash = this.state.cash }
        if (this.state.paid !== '') { paid = this.state.paid }
        if (this.state.change !== '') { change = this.state.change }
        if (this.state.creditcard !== '') { creditcard = this.state.creditcard }
        if (this.state.epayment !== '') { epayment = this.state.epayment }
        if (this.state.cheque !== '') { cheque = this.state.cheque }
        if (this.state.sum !== '') { sum = this.state.sum }

        let netpaid = Number(paid) - Number(change)
        totalpaid = Number(creditcard) + Number(epayment) + Number(cheque) + Number(netpaid);
        totalpaid = totalpaid.toFixed(2)

        console.log('@checkCash')
        console.log('cash: ' + cash)
        console.log('cash received: ' + paid)
        console.log('change: ' + change)
        console.log('totalpaid: ' + totalpaid)
        console.log('sum: ' + sum)

        if ((cash > 0 && paid === '')
            || (cash > 0 && paid === 0.00)
            || (cash > 0 && paid === '0.00')) {
            alert('CASH RECEIVED amount not set. Please provide value for cash received.');
        }
        else if (this.state.salesperson === '') {
            alert('No Sales Person selected. Please select a Sales Person')
        }
        else if (totalpaid < sum) {
            console.log('totalpaid:' + totalpaid)
            console.log('sum:' + sum)
            alert('PAID amount is LESS than Grand Total');
        }
        else if (totalpaid > sum) {
            console.log('totalpaid:' + totalpaid)
            console.log('sum:' + sum)
            alert('PAID amount is GREATER than Grand Total');
        }
        else {
            console.log('totalpaid:' + totalpaid)
            console.log('sum:' + sum)
            console.log('check done')
            this.execPayNow();
        }
    }

    execPayNow() {
        this.closeModal();
        this.toggleModal('submit');
        this.setState({ isReturn: false })

        let item = this.mapItemForSubmit();
        const membership = this.state.membership;
        //console.log('membership capture: ' + membership);
        //console.log('items are: ');
        //console.log(item);

        //console.log('mapItemForSubmit executed, checking zeropoints state');
        //console.log(this.state.zeropoints);

        let points = Math.round(Number(this.state.sum));
        points = Number(points) - Number(this.state.ptstominus);
        let zeropts = this.getPtsToMinus();
        points = Number(points) - Number(zeropts)
        console.log('points to add after minus and zeropoints item: ' + points);

        let cash = this.getCashPayment();
        let creditcard = this.getCreditCardPayment();
        let cheque = this.getChequePayment();
        let epayment = this.getEPayment();

        let payments = [];
        if (cash !== null) { payments.push(cash) }
        if (creditcard !== null) { payments.push(creditcard) }
        if (cheque !== null) { payments.push(cheque) }
        if (epayment !== null) { payments.push(epayment) }
        //"debit_to":this.state.inv_debit_to,

        let series = 'CB' + this.getBillPrefix(this.state.inv_project);

        /*
        let customer_name_to_submit = this.state.customerID;
        if (this.state.inv_project === 'Outlet Luyang 2'
        && this.state.customerID === 'Walk In (Luyang 2)' ) {
            customer_name_to_submit = 'Walk In (Likas)';
        }
        */

        let data = {
            "docstatus": 1,
            "is_pos": 1,
            "naming_series": series,
            "project": this.state.inv_project,
            "submit_on_creation": 1,
            "update_stock": 1,
            "customer": this.state.customerID,
            "base_total": Number(this.state.sum),
            "base_grand_total": Number(this.state.sum),
            "base_paid_amount": Number(this.state.paid),
            "base_change_amount": Number(this.state.change),
            "total": Number(this.state.sum),
            "grand_total": Number(this.state.sum),
            "paid_amount": Number(this.state.paid),
            "change_amount": Number(this.state.change),
            "items": item,
            "payments": payments,
            "approval_code": this.state.approvalcode,
            "merchant_code": this.state.merchantcode,
            "merchant_id": this.state.merchantid,
            "card_holder": this.state.cardholder,
            "card_type": this.state.cctype,
            "credit_card_number": this.state.ccno,
            "expiry_date": this.state.ccexpiry,
            "cheque_number": this.state.chequeno,
            "cheque_bank": this.state.chequebank,
            "e_payment_type": this.state.epaymenttype,
            "e_payment_code": this.state.epaymentcode,
            "e_payment_bank": this.state.epaymentbank,
            "company": this.state.inv_company,
            "account_for_change_amount": this.state.inv_againts_change,
            "againts_income_account": this.state.inv_againts_income,
            "posting_date": this.state.inv_posting_date,
            "posting_time": moment().format('h:mm:ss'),
            "cashier": this.state.firstname,
            "sales_person": this.state.salesperson_name,
            "void_points": this.state.ptstominus
        }

        //console.log('data ready: ');
        //console.log(data);

        let api = fAppAPI() + 'api/resource/Sales%20Invoice/?data=' + JSON.stringify(data);
        //console.log('api submited: ' + api)
        fetch(api, {
            method: 'POST',
            credentials: 'include'
        })
            .then((res) => res.json())
            .then((resj) => {
                //console.log('created invoice')
                //console.log(resj.data)
                let newinv = resj.data.name;
                //console.log('new created invoice: ' + newinv);

                //update the sales commission entry
                this.newSalesComEntry(newinv);

                this.setState({ inv_name: newinv, salesperson: '', selectedSalesPerson: [] });
                this.updateBdayRecord(this.state.customerID, newinv, this.state.bdaycheck);
                this.updateMemberPoints(points, this.state.customerID, membership, newinv, this.state.point);
                this.emptyCart();
                this.toggleModal('printreceipt');
            })
            .catch((error) => {
                // this.closeModal()
                // this.toggleModal('error')
                console.log('err at execPayNow')
                console.log(error)
            })
    }

    newSalesComEntry(receipt) {
        let api = "https://api.paragonpetsgroup.net/salescom.php?method=newsce"
            + "&value=" + this.state.sum
            + "&receipt=" + receipt
            + "&parent=" + this.state.salesperson
            + "&cashier=" + this.state.firstname
            + "&outlet=" + this.state.inv_project

        console.log('creating new sce');
        console.log(api)

        return fetch(api)
            .then((resp) => resp.json())
            .then((resj) => {
                if (resj.success === true) {
                    console.log('newsce success')
                }
                else {
                    alert("Sales Person commission entry unsuccessful. Please contact the developer.")
                    console.log('fetch err @newSalesComEntry')
                    console.log(resj)
                }
            })
            .catch((err) => {
                alert("Sales Person commission entry unsuccessful. Please contact the developer.")
                console.log('app err @newSalesComEntry')
                console.log(err)
            })
    }

    toggleIsReturn() {
        if (this.state.isReturn === false) {
            this.setState({
                isReturn: true,
                showUtilBtn: false
            }, function () {
                //console.log('isReturn is now TRUE');
                this.closeModal();
                this.toggleModal('printreportinv');
            })
        }
        else {
            this.setState({
                isReturn: false
            }, function () {
                //console.log('isReturn is now FALSE');
                this.closeModal();
                this.toggleModal('printreportinv');
            })
        }
    }

    execReturn(invoice) {
        let invd = [];
        let invi = [];
        let invp = [];

        let reti = [];
        let retp = [];

        let api = fAppAPI() + 'api/resource/Sales%20Invoice/' + invoice
        return fetch(api, {
            method: 'GET',
            credentials: 'include'
        })
            .then((resp) => resp.json())
            .then((resj) => {
                invd = resj.data;
                invi = resj.data.items;
                invp = resj.data.payments;

                let retpaid = Number(invd.paid_amount) - Number(invd.change_amount);

                invd.base_grand_total = -Math.abs(invd.base_grand_total);
                invd.base_net_total = -Math.abs(invd.base_net_total);
                invd.base_total = -Math.abs(invd.base_total);
                invd.base_paid_amount = -Math.abs(retpaid);

                invd.grand_total = -Math.abs(invd.grand_total);
                invd.net_total = -Math.abs(invd.net_total);
                invd.total = -Math.abs(invd.total);
                invd.paid_amount = -Math.abs(retpaid);

                for (let i = 0; i < invi.length; i++) {
                    invi[i].qty = -Math.abs(invi[i].qty);
                    invi[i].stock_qty = -Math.abs(invi[i].stock_qty);
                    invi[i].base_amount = -Math.abs(invi[i].base_amount);
                    invi[i].base_net_amount = -Math.abs(invi[i].base_net_amount);
                    invi[i].net_amount = -Math.abs(invi[i].net_amount);
                    invi[i].amount = -Math.abs(invi[i].amount);
                    invi[i].base_rate = Number(invi[i].base_rate);
                    invi[i].base_net_rate = Number(invi[i].base_net_rate);
                    invi[i].net_rate = Number(invi[i].net_rate);
                    invi[i].rate = Number(invi[i].rate);
                    reti.push(invi[i]);
                }

                for (let p = 0; p < invp.length; p++) {
                    if (invp[p].mode_of_payment === 'Cash') {
                        let retamount = Number(invp[p].amount) - Number(invd.change_amount);
                        invp[p].amount = -Math.abs(retamount);
                        invp[p].base_amount = -Math.abs(retamount);
                        retp.push(invp[p]);
                    }
                    else {
                        invp[p].amount = -Math.abs(invp[p].amount);
                        invp[p].base_amount = -Math.abs(invp[p].base_amount);
                        retp.push(invp[p]);
                    }
                }

                let data = {
                    "docstatus": 1,
                    "is_pos": 1,
                    "is_return": 1,
                    "return_against": invoice,
                    "submit_on_creation": 1,
                    "project": invd.project,
                    "update_stock": 1,
                    "debit_to": invd.debit_to,
                    "customer": invd.customer,
                    "base_total": Number(invd.base_total),
                    "base_grand_total": Number(invd.grand_total),
                    "base_paid_amount": Number(invd.base_paid_amount),
                    "base_change_amount": 0,//Number(invd.base_change_amount),
                    "total": Number(invd.total),
                    "grand_total": Number(invd.grand_total),
                    "paid_amount": Number(invd.paid_amount),
                    "change_amount": 0,//Number(invd.change_amount),
                    "write_off_amount": Number(invd.write_off_amount),
                    "items": reti,
                    "payments": retp,
                    "approval_code": invd.approval_code,
                    "merchant_code": invd.merchant_code,
                    "merchant_id": invd.merchant_id,
                    "card_holder": invd.card_holder,
                    "card_type": invd.card_type,
                    "credit_card_number": invd.credit_card_number,
                    "expiry_date": invd.expiry_date,
                    "cheque_number": invd.cheque_number,
                    "e_payment_code": invd.e_payment_code,
                    "company": invd.company,
                    "account_for_change_amount": invd.account_for_change_amount,
                    "against_income_account": invd.against_income_account,
                    "posting_date": invd.posting_date,
                    "cashier": invd.cashier
                }
                return data;
            })
            .then((out) => {
                let api = fAppAPI() + 'api/resource/Sales%20Invoice/?data=' + JSON.stringify(out);
                //console.log('return paid amount: ' + out.paid_amount);
                //console.log('return change amount: ' + out.change_amount);
                //console.log('return grand total: ' + out.grand_total);
                //console.log('return write off: ' + out.write_off_amount);

                fetch(api, {
                    method: 'POST',
                    credentials: 'include'
                })
                    .then((res) => res.json())
                    .then((resj) => {
                        //this.updateMemberPointsOnReturn(invd.customer, invd.paid_amount);
                        this.closeModal();
                        this.prepReport();
                    })
                    .catch((error) => {
                        this.closeModal()
                        this.toggleModal('error')
                    })
            })
            .catch((error) => {
                this.closeModal()
                this.toggleModal('error')
            })
    }

    execRedeem(code, pt, srs, index) {
        this.closeModal();
        this.toggleModal('submit')

        let series = 'RDM' + this.getBillPrefix(this.state.inv_project);
        let qty = 1

        if (this.state.redemption[index].qty_sent !== undefined && this.state.redemption[index].qty_sent !== null) {
            qty = this.state.redemption[index].qty_sent
        }

        let item = {
            "item_code": code,
            "qty": qty,
            "rate": 0.00,
            "amount": 0.00,
            "discount_percentage": 100,
            "discount_on_price": 0.00,
            "warehouse": this.state.inv_warehouse,
            "cost_center": this.state.inv_costcenter
        }

        let payments = {
            "mode_of_payment": "Cash",
            "base_amount": 0.00,
            "amount": 0.00,
            "account": this.state.inv_account_cash
        }

        let data = {
            "docstatus": 1,
            "is_pos": 1,
            "naming_series": series,
            "project": this.state.inv_project,
            "submit_on_creation": 1,
            "update_stock": 1,
            "customer": this.state.customerID,
            "base_total": 0.00,
            "base_grand_total": 0.00,
            "base_paid_amount": 0.00,
            "base_change_amount": 0.00,
            "total": 0.00,
            "grand_total": 0.00,
            "paid_amount": 0.00,
            "change_amount": 0.00,
            "items": [item],
            "payments": [payments],
            "company": this.state.inv_company,
            "account_for_change_amount": this.state.inv_againts_change,
            "againts_income_account": this.state.inv_againts_income,
            "posting_date": this.state.inv_posting_date,
            "posting_time": moment().format('h:mm:ss'),
            "cashier": this.state.firstname
        }
        console.log('execRedeem')
        console.log(data)
        let api = fAppAPI() + 'api/resource/Sales%20Invoice/?data=' + JSON.stringify(data);
        //console.log('api submited: ' + api)
        fetch(api, {
            method: 'POST',
            credentials: 'include'
        })
            .then((res) => res.json())
            .then((resj) => {
                console.log('redeem success, deduct member points...')
                this.execDeductMemberPt(this.state.customerID, pt, srs)
            })
            .catch((error) => {
                this.closeModal()
                this.toggleModal('error')
            })
    }

    updateMemberPoints(points, customer, membership, invoice, cp) {
        const cuscurpoint = cp;
        console.log('@updateMemberPoints exec');
        if (this.state.mrpenabled === 1) {
            console.log('mrp is enabled')
            console.log('check if in mlm tree')
            const thenewinv = invoice;
            //console.log('membership value passed: ' + membership)
            // best is we call api here
            // to check if this customer is registered
            // under to any customer in the MRP
            let filter = '?filters=[["member", "=", "' + customer + '"]]';
            let fields = '&fields=["customer"]';
            let mlm = fAppAPI() + 'api/resource/MRP Member/' + filter + fields;
            return fetch(mlm, {
                method: 'GET',
                credentials: 'include'
            })
                .then((resp) => resp.json())
                .then((resj) => {
                    if (resj.data.length > 0) {
                        console.log('in mrp tree, we need to do this twice.');
                        console.log('first update the principal, then the customer');

                        let principal = resj.data[0].customer;

                        filter = '?filters=[["name", "=", "' + principal + '"]]';
                        fields = '&fields=["reward_points"]';
                        let api = fAppAPI() + 'api/resource/Customer/' + filter + fields;
                        return fetch(api, {
                            method: 'GET',
                            credentials: 'include'
                        })
                            .then((resp) => resp.json())
                            .then((resj) => {
                                let cuspoint = Math.round(Number(points));
                                let curpoint = resj.data[0].reward_points;
                                let group = resj.data[0].customer_group;

                                console.log('principal cur point: ' + curpoint);
                                console.log('customer gained point: ' + cuspoint);
                                console.log('getting principal member counts...');

                                filter = '?filters=[["customer","=","' + principal + '"]]';
                                api = fAppAPI() + 'api/resource/MRP Member/' + filter;
                                return fetch(api, {
                                    method: 'GET',
                                    credentials: 'include'
                                })
                                    .then((resp) => resp.json())
                                    .then((resj) => {
                                        let count = resj.data.length;

                                        let rate = this.getMRPrate(count);

                                        console.log('principal member count: ' + count);
                                        console.log('based on mrp settings, principal will get partial %: ' + rate);

                                        let addpoint = Math.round(((Number(cuspoint) * Number(rate)) / 100).toFixed(2));
                                        console.log('calculated addpoint: ' + addpoint);
                                        let newpoint = parseInt(curpoint, 10) + parseInt(addpoint, 10);
                                        console.log('principal new point: ' + newpoint);
                                        console.log('done with calc, update the points now');

                                        // add mrp history
                                        this.addMRPhistory(principal, customer, addpoint, thenewinv);
                                        // update Principal
                                        this.execUpdatePrincipalPoints(newpoint, principal, customer, group);
                                        // update Member
                                        this.execUpdateMemberPoints(points, customer, membership, cuscurpoint);
                                    })
                            })
                    }
                    else {
                        console.log('not in mlm tree, continue normaly');
                        this.execUpdateMemberPoints(points, customer, membership, cuscurpoint);
                    }
                })
        }
        else {
            console.log('mrp is disabled')
            this.execUpdateMemberPoints(points, customer, membership, cuscurpoint);
        }
    }

    addMRPhistory(principal, member, pts, invoice) {
        if (this.state.mrpenabled === 1) {
            console.log('@addMRPhistory exec')
            let param = {
                'principal': principal,
                'member': member,
                'points': pts,
                'invoice': invoice,
                'outlet': this.state.inv_project,
                'date_and_time': moment().format('YYYY-MM-DD h:mm:ss')
            }
            let api = fAppAPI() + 'api/resource/MRP History/?data=' + JSON.stringify(param)
            fetch(api, {
                method: 'POST',
                credentials: 'include'
            })
                .then((resp) => resp.json())
                .then((resj) => {
                    console.log('mrphistory added')
                    console.log(resj)
                })
                .catch((err) => {
                    console.log('ERROR @addMRPhistory')
                    console.log(err)
                })
        }
    }

    execUpdatePrincipalPoints(point, principal, member, membership) {
        console.log('@execUpdatePrincipalPoints exec');

        const customer = member;
        const customergroup = membership;
        const newpoint = point;
        const classic = 499;
        const gold = 14999;

        let param = '';

        if (newpoint > classic && newpoint < gold) {
            param = {
                'customer_group': 'Gold',
                'reward_points': newpoint
            }
        }
        else if (newpoint > gold) {
            param = {
                'customer_group': 'Platinum',
                'reward_points': newpoint
            }
        }
        else {
            param = {
                'reward_points': newpoint
            }
        }

        let api = fAppAPI() + 'api/resource/Customer/' + principal + '?data=' + JSON.stringify(param);
        fetch(api, {
            method: 'PUT',
            credentials: 'include'
        })
            .then((resp) => resp.json())
            .then((resj) => {
                let customers = JSON.parse(localStorage.getItem('erpnext_customer'));
                let i = customers.findIndex(c => c.name === principal);
                let newEntry = {
                    customer_group: customers[i].customer_group,
                    customer_name: customers[i].customer_name,
                    image: customers[i].image,
                    mobile_number: customers[i].mobile_number,
                    name: customers[i].name,
                    nric: customers[i].nric,
                    reward_points: newpoint,
                    territory: customers[i].territory
                }
                customers.push(newEntry);
                customers.splice(i, 1);
                localStorage.setItem('erpnext_customer', JSON.stringify(customers));
            })
            .catch((error) => {
                console.log('ERROR @execUpdatePrincipalPoints')
                console.log(error)
            })
    }

    execUpdateMemberPoints(points, customer, membership, cp) {
        console.log('@execUpdateMemberPoints exec')
        //console.log('membership value passed: ' + membership)

        const membershipToCheck = membership;

        if (customer !== 'Classic Member' && customer !== 'Gold Member' && customer !== 'Platinum Member') {

            console.log('customer is not the prefix Classics/Gold/Platinum');
            console.log('customer is: ' + customer);
            console.log('points to add: ' + points);
            console.log('membership to check: ' + membershipToCheck);

            //let tominus = this.state.ptstominus;
            //let totalpoints = parseInt(points, 10) - parseInt(tominus, 10);

            let curpoint = cp;
            let classic = this.state.classic_max;
            let gold = this.state.gold_max;
            //let membership = this.state.membership;
            //let customer = this.state.customerID;
            let newpoints = parseInt(curpoint, 10) + parseInt(points, 10);

            console.log('customer curpoints: ' + curpoint)
            console.log('customer new point: ' + newpoints)

            let param = '';

            if (membershipToCheck !== 'None' && membershipToCheck !== 'Standard' && membershipToCheck !== 'Dealer') {
                if (newpoints > classic && newpoints < gold) {
                    console.log('member upgrading to Gold from Classic')
                    param = {
                        "customer_group": 'Gold',
                        "reward_points": newpoints
                    }
                }
                else if (newpoints > gold) {
                    console.log('member upgrading to Platinum from Gold')
                    param = {
                        "customer_group": 'Platinum',
                        "reward_points": newpoints
                    }
                }
                else {
                    //console.log('no membership upgrade, just update points')
                    param = {
                        "reward_points": newpoints
                    }
                }

                let api = fAppAPI() + 'api/resource/Customer/' + customer + '?data=' + JSON.stringify(param);

                //console.log('@updateMemberPoints exec')
                //console.log('api sent: ' + api)
                fetch(api, {
                    method: 'PUT',
                    credentials: 'include'
                })
                    .then((res) => res.json())
                    .then((resj) => {
                        //console.log(resj.data);
                        //this.getCustomers();
                        let customers = JSON.parse(localStorage.getItem('erpnext_customer'));
                        let i = customers.findIndex(c => c.name === customer);
                        let newEntry = {
                            customer_group: resj.data.customer_group,
                            customer_name: customers[i].customer_name,
                            image: customers[i].image,
                            mobile_number: resj.data.mobile_number,
                            name: customers[i].name,
                            nric: resj.data.nric,
                            reward_points: resj.data.reward_points,
                            territory: customers[i].territory
                        }

                        console.log('newentry:')
                        console.log(newEntry)

                        customers.push(newEntry);
                        customers.splice(i, 1);
                        localStorage.setItem('erpnext_customer', JSON.stringify(customers));
                        //this.setSelectCustomer(this.state.customersearchopt);
                    })
                    .catch((error) => {
                        this.closeModal()
                        this.toggleModal('error')
                    })
            }
            else if (membershipToCheck === 'Standard') {
								console.log('customer eligible membership upgrade to standard')
                if (this.state.eligible === true) {
                    param = {
                        "customer_group": 'Classic',
                        "reward_points": 0
                    }
                    let api = fAppAPI() + 'api/resource/Customer/' + customer + '?data=' + JSON.stringify(param);

                    //console.log('@updateMemberPoints exec')
                    //console.log('api sent: ' + api)
                    fetch(api, {
                        method: 'PUT',
                        credentials: 'include'
                    })
                        .then((res) => res.json())
                        .then((resj) => {
                            //console.log(resj.data);
                            this.getCustomers();
                        })
                        .catch((error) => {
                            this.closeModal()
                            this.toggleModal('error')
                        })
                }
            }
            else {
                console.log('no points added')
            }
        }
    }

    execDeductMemberPt(memberID, pt, id) {
        let series = id;
        let member = memberID;
        let gold = 500;
        let platinum = 15000;
        let curpoint = this.state.point;
        let membership = this.state.membership;
        let ptminus = pt;
        let param = '';

        let newpt = parseInt(curpoint, 10) - parseInt(pt, 10);
        if (newpt > 0) {
            param = {
                'reward_points': newpt
            }
        }
        else {
            param = {
                'reward_points': 0
            }
        }

        let api = fAppAPI() + 'api/resource/Customer/' + member + '?data=' + JSON.stringify(param);
        fetch(api, {
            method: 'PUT',
            credentials: 'include'
        })
            .then((resp) => resp.json())
            .then((resj) => {
                this.updateRedeemHistory(series)
            })
            .catch((error) => {
                console.log('ERROR @execDeductMemberPt')
                console.log(error)
            })
    }

    updateRedeemHistory(series) {
        console.log('updating redemption history with series: ' + series)
        let api = webAPI + 'method=updateredeem&name=' + series
        return fetch(api, {
            method: 'GET'
        })
            .then((resp) => resp.json())
            .then((resj) => {
                //console.log(resj.data)
                this.closeModal()
                this.syncData()
            })
    }

    editMember(id) {
        if (id !== 'update') {
            //let customers = this.state.customers;
            let customers = JSON.parse(localStorage.getItem('erpnext_customer'));
            let i = customers.findIndex(c => c.name === id);

            this.setState({
                new_name: customers[i].customer_name,
                new_nric: customers[i].nric,
                new_mobile: customers[i].mobile_number,
            }, function () {
                this.toggleModal('editmember');
            })
        }
        else {
            //console.log('@editMember exec:update')
            let newTier = this.state.membership;
            if (this.state.newTier !== '') {
                newTier = this.state.newTier;
            }

            console.log('updating member with tier: ');
            console.log(newTier);

            let param = {
                customer_name: this.state.new_name,
                nric: this.state.new_nric,
                mobile_number: this.state.new_mobile,
                customer_group: newTier
            }
            let api = fAppAPI() + 'api/resource/Customer/'
                + this.state.customerID
                + '?data=' + JSON.stringify(param);

            //console.log('api param: ' + api);
            fetch(api, {
                method: 'PUT',
                credentials: 'include'
            })
                .then((res) => res.json())
                .then((resj) => {

                    //console.log(resj.data);
                    //this.syncData();

                    // update @localStorage
                    let id = this.state.customerID;
                    let customers = [];
                    customers = JSON.parse(localStorage.getItem('erpnext_customer'));

                    let i = customers.findIndex(c => c.name === id);
                    let newEntry = {
                        customer_group: customers[i].customer_group,
                        customer_name: this.state.new_name,
                        image: customers[i].image,
                        mobile_number: this.state.new_mobile,
                        name: customers[i].name,
                        nric: this.state.new_nric,
                        reward_points: customers[i].reward_points,
                        territory: customers[i].territory
                    }
                    customers.push(newEntry);
                    customers.splice(i, 1);
                    localStorage.setItem('erpnext_customer', JSON.stringify(customers));
                    customers = JSON.parse(localStorage.getItem('erpnext_customer'));

                    //console.log('localStorage customers updated:');
                    //console.log(customers);
                    //console.log('updating related states');
                    this.setState({
                        customer: this.state.new_name,
                        nric: this.state.new_nric,
                        phone: this.state.new_mobile,
                    }, function () {
                        //console.log('done updating states')
                        //console.log('customer: ' + this.state.customer);
                        //console.log('nric: ' + this.state.nric);
                        //console.log('mobile: ' + this.state.phone);

                        this.toggleModal();
                    })
                    //this.closeModal();
                })
                .catch((error) => {
                    this.closeModal()
                    this.toggleModal('error')
                    console.log(error)
                })
        }
    }

    getBillPrefix(project) {
        switch (project) {
            case 'Outlet Bundusan': return 'PPB';
            case 'Outlet Luyang': return 'PPL';
            case 'Outlet Luyang 2': return 'PPL2';
            case 'Outlet Keningau': return 'PKU';
            case 'Outlet Kudat': return 'PKD';
            case 'Outlet Tawau': return 'PTW';
        }
    }

    setMemberNo(outlet, no) {
        let prefix = '';
        switch (outlet) {
            case 'Bundusan': prefix = 'PG'; break;
            case 'Luyang': prefix = 'PP'; break;
            case 'Luyang 2': prefix = 'PP2'; break;
            case 'Keningau': prefix = 'PK'; break;
            case 'Kudat': prefix = 'PD'; break;
            case 'Tawau': prefix = 'PT'; break;
        }

        let number = Number(no) + 1;

        let memberno = prefix + number;
        console.log('generated member no: ' + memberno);
        return memberno;
    }

    clearRefMember() {
        this.setState({
            ref_customer: '',
            ref_membership: '',
            ref_points: '',
            ref_nric: '',
            ref_phone: '',
            ref_profileimage: '',
            ref_display: false
        })
    }

    registerMember() {
        //console.log('@registerMember exec');
        let selects = JSON.parse(localStorage.getItem('erpnext_selects'));
        let customers = JSON.parse(localStorage.getItem('erpnext_customer'));
        let name = this.state.reg_name;
        let nric = this.state.reg_nric;
        let mobile = this.state.reg_mobile;
        let outlet = this.state.reg_outlet;
        let memberno = this.setMemberNo(outlet, customers.length);

        if (name === '' ||
            nric === '' ||
            mobile === '' ||
            outlet === null) {
            //this.toggleModal('register');
            //console.log('form not complete');
            this.setState({
                reg_false: true
            })
        }
        else {
            this.closeModal();
            this.toggleModal('registering');
            console.log('check nric duplication');
            // check nric duplication
            let api = fAppAPI() + 'api/resource/Customer/?filters=[["nric", "=", "' + this.state.reg_nric + '"]]';
            return fetch(api, {
                method: 'GET',
                credentials: 'include'
            })
                .then((resp) => resp.json())
                .then((resj) => {
                    if (resj.data.length === 0) {
                        //console.log('no nric duplication, continue with registration')
                        let data = {
                            "customer_type": 'Individual',
                            "customer_group": 'Standard',
                            "reward_points": 0,
                            "name": name,
                            "customer_name": name,
                            "mobile_number": mobile,
                            "nric": nric,
                            "territory": outlet,
                            "member_code": memberno,
                            "image": null
                        }

                        let api = fAppAPI() + 'api/resource/Customer/?data=' + JSON.stringify(data);
                        //console.log('api submited: ' + api)
                        fetch(api, {
                            method: 'POST',
                            credentials: 'include'
                        })
                            .then((res) => res.json())
                            .then((resj) => {
                                if (resj.data !== '') {
                                    //console.log('registration success!');
                                    //console.log(resj.data);

                                    let newData = {
                                        "name": resj.data.name,
                                        "customer_name": resj.data.customer_name,
                                        "image": resj.data.image,
                                        "customer_group": resj.data.customer_group,
                                        "reward_points": 0,
                                        "nric": resj.data.nric,
                                        "mobile_number": resj.data.mobile_number,
                                        "territory": resj.data.territory,
                                        "member_code": resj.data.member_code
                                    }

                                    console.log('pushing the following data onto the localStorage');
                                    console.log(newData);

                                    let label = '';
                                    let opt = this.state.customersearchopt;

                                    if (opt === 'name') {
                                        label = resj.data.customer_name;
                                    }
                                    else if (opt === 'nric') {
                                        label = resj.data.nric;
                                    }
                                    else if (opt === 'qrcode') {
                                        label = resj.data.member_code
                                    }

                                    customers.push(newData);
                                    localStorage.setItem('erpnext_customer', JSON.stringify(customers));
                                    this.setSelectCustomer(this.state.customersearchopt);

                                    let newMemberData = {
                                        customer: resj.data.customer_name,
                                        customerID: resj.data.name,
                                        membership: resj.data.customer_group,
                                        point: resj.data.reward_points,
                                        nric: resj.data.nric,
                                        phone: resj.data.mobile_number,
                                        reg_false: false,
                                        bdayused: false,
                                    }

                                    this.setState({
                                        customer: resj.data.customer_name,
                                        customerID: resj.data.name,
                                        membership: resj.data.customer_group,
                                        point: resj.data.reward_points,
                                        nric: resj.data.nric,
                                        phone: resj.data.mobile_number,
                                        reg_false: false,
                                        bdayused: false,
                                    }, function () {
                                        console.log('customer ID is: ' + this.state.customerID);
                                        console.log('check if has mlm ref provided...');

                                        if (this.state.ref_display === true) {
                                            this.closeModal();
                                            this.registerMLM();
                                        }
                                        else {
                                            console.log('no mlm ref provided. closing registration session.')
                                            this.setState({
                                                reg_name: '',
                                                reg_nric: '',
                                                reg_mobile: '',
                                                reg_outlet: '',
                                                selectedOutlet: null
                                            }, function () {
                                                this.setState({
                                                    selectedCustomer: {
                                                        value: name,
                                                        label: name
                                                    }
                                                });
                                            })
                                            this.closeModal();
                                            this.syncData(newMemberData);
                                        }
                                    })
                                }
                                else {
                                    this.closeModal();
                                    this.toggleModal('error');
                                }
                            })
                            .catch((error) => {
                                this.closeModal();
                                this.toggleModal('error');
                            })
                        //this.closeModal()
                    }
                    else {
                        this.setState({
                            reg_false: false,
                            reg_duplicate: true
                        }, function () {
                            this.closeModal();
                            this.toggleModal('register');
                        })
                    }
                })
                .catch((error) => {
                    this.closeModal()
                    this.toggleModal('error')
                })
        }
    }

    getOutletOuput(outlet) {
        switch (outlet) {
            case 'Kudat': return 'Outlet Kudat - PK9F';
            case 'Luyang': return "Outlet Luyang - PPSB";
            case 'Luyang 2': return "Outlet Luyang - PPSB";
            case 'Tawau': return "Outlet Tawau - PK9F";
            case 'Keningau': return "Outlet Keningau - PK9F";
            case 'Bundusan': return "Outlet Bundusan - PPSB";
            default: return null;
        }
    }

    registerMLM() {
        if (this.state.ref_membership === 'Gold'
            || this.state.ref_membership === 'Platinum'
            || this.state.ref_membership === 'Classic') {
            this.toggleModal('addmlm');

            // check how many member under the customer
            const {
                ref_customer,
                reg_name,
                reg_outlet,
                reg_nric,
                reg_mobile,
            } = this.state;

            let maxtier = this.state.mrpmax;

            let filter = '?filters=[["customer", "=", "' + ref_customer + '"]]';
            let mlm = fAppAPI() + 'api/resource/MRP Member/' + filter;
            return fetch(mlm, {
                method: 'GET',
                credentials: 'include'
            })
                .then((resp) => resp.json())
                .then((resj) => {
                    if (resj.data.length >= maxtier) {
                        // cancel
                        this.setState({
                            mlm_status: 'Fail',
                            mlm_message: 'The customer, ' + ref_customer + ' has reached the maximum member allowed.'
                        })
                        this.closeModal();
                        this.toggleModal('mlmstatus');
                        this.setState({
                            reg_name: '',
                            reg_nric: '',
                            reg_mobile: '',
                            reg_outlet: '',
                            selectedOutlet: null,
                            ref_customer: '',
                            ref_membership: '',
                            ref_points: '',
                            ref_nric: '',
                            ref_phone: '',
                            ref_profileimage: '',
                            ref_display: false
                        }, function () {
                            this.setState({
                                selectedCustomer: {
                                    value: reg_name,
                                    label: reg_name
                                },
                            });
                        })
                    }
                    else {
                        // < maxtier
                        let added = moment().format('YYYY-MM-DD h:mm:ss');
                        let data = {
                            customer: ref_customer,
                            member: reg_name,
                            outlet: 'Outlet ' + reg_outlet,
                            added: added
                        }
                        console.log('adding mlm member');
                        let api = fAppAPI() + 'api/resource/MRP Member/?data=' + JSON.stringify(data);
                        fetch(api, {
                            method: 'POST',
                            credentials: 'include'
                        })
                            .then((resp) => resp.json())
                            .then((resj) => {
                                console.log('mlm registration OK');
                                this.setState({
                                    mlm_status: 'Success',
                                    mlm_message: "Member " + reg_name + " successfuly registered under " + ref_customer + " ."
                                });
                                this.closeModal();
                                this.toggleModal('mlmstatus');
                                this.setState({
                                    reg_name: '',
                                    reg_nric: '',
                                    reg_mobile: '',
                                    reg_outlet: '',
                                    selectedOutlet: null,
                                    ref_customer: '',
                                    ref_membership: '',
                                    ref_points: '',
                                    ref_nric: '',
                                    ref_phone: '',
                                    ref_profileimage: '',
                                    ref_display: false
                                }, function () {
                                    this.setState({
                                        selectedCustomer: {
                                            value: reg_name,
                                            label: reg_name
                                        }
                                    });
                                })
                            })
                    }
                })
        }
    }

    /** @NOTE end paynow */
    //--------------------------------------------------------------------------

    getBin() {
        let fields = '?fields=["item_code", "actual_qty"]';
        // let filter = '&filters=[["warehouse", "=", "' + this.state.inv_warehouse + '"],["actual_qty", ">", 0]]';
        let filter = '&filters=[["warehouse", "=", "' + this.state.inv_warehouse + '"]]';
        let limit = '&limit_page_length=0';
        let api = fAppAPI() + "api/resource/Bin/" + fields + filter + limit;

        return fetch(api, {
            method: 'GET',
            credentials: 'include'
        })
            .then((resp) => resp.json())
            .then((resj) => {
                // save regardless of length
                localStorage.setItem('erpnext_bin', JSON.stringify(resj.data));
                this.setState({
                    totalBin: resj.data.length,
                    syncBin: 'done'
                }, function () {
                    this.getAllItems();
                    //console.log('bin saved to local storage');
                    console.log('syncBin DONE');
                })
            })
            .catch((error) => {
                console.log('error occured @method @getBin')
                this.setState({ syncBin: 'done' })
                this.toggleModal('error')
            })
    }

    getOutletRates() {
        switch (this.state.inv_project) {
            case 'Outlet Bundusan':
                let brate = [
                    "outlet_bundusan",
                    "promo_start_bundusan",
                    "promo_end_bundusan",
                    "promo_rate_bundusan",
                    "apply_member_rate_for_bundusan",
                    "bundusan_no_point",
                    "rate_bundusan_is_currency"
                ];
                return brate;

            case 'Outlet Luyang':
            case 'Outlet Luyang 2':
                let lrate = [
                    "outlet_luyang",
                    "promo_start_luyang",
                    "promo_end_luyang",
                    "promo_rate_luyang",
                    "apply_member_rate_for_luyang",
                    "luyang_no_point",
                    "rate_luyang_is_currency"
                ];
                return lrate;

            case 'Outlet Keningau':
                let krate = [
                    "outlet_keningau",
                    "promo_start_keningau",
                    "promo_end_keningau",
                    "promo_rate_keningau",
                    "apply_member_rate_for_keningau",
                    "keningau_no_point",
                    "rate_keningau_is_currency"
                ];
                return krate;

            case 'Outlet Kudat':
                let drate = [
                    "outlet_kudat",
                    "promo_start_kudat",
                    "promo_end_kudat",
                    "promo_rate_kudat",
                    "apply_member_rate_for_kudat",
                    "kudat_no_point",
                    "rate_kudat_is_currency"
                ];
                return drate;

            case 'Outlet Tawau':
                let wrate = [
                    "outlet_tawau",
                    "promo_start_tawau",
                    "promo_end_tawau",
                    "promo_rate_tawau",
                    "apply_member_rate_for_tawau",
                    "tawau_no_point",
                    "rate_tawau_is_currency"
                ];
                return wrate;

            default: return null;
        }
    }

    getAllServices() {
        let prop = [
            "name",
            "item_name",
            "image",
            "barcode",
            "standard_rate",
            "popular",
            "promotion",
            "points_required",
            "classic_discount",
            "classic_discount_is_currency",
            "gold_discount",
            "gold_discount_is_currency",
            "platinum_discount",
            "platinum_discount_is_currency"
        ];

        let rate = this.getOutletRates();
        prop.push(rate);

        let params = '"' + prop.join('","') + '"';
        let filter = '&filters=[["disabled", "=", 0],["item_group"]]';
        let limit = '&limit_page_length=0';
        let api = fAppAPI() + "api/resource/Item/?fields=[" + params + "]" + filter + limit;

        return fetch(api, {
            method: 'GET',
            credentials: 'include',
        })
            .then((resp) => resp.json())
            .then((resj) => {
                console.log('services fetched')
                console.log(resj.data)
                localStorage.setItem('erpnext_service', JSON.stringify(resj.data));
            });
    }

    getAllItems() {
        //console.log('@getAllItems exec');
        let prop = [
            "name",
            "item_name",
            "image",
            "barcode",
            "standard_rate",
            "zero_point",
            "popular",
            "promotion",
            "points_required",
            "classic_discount",
            "classic_discount_is_currency",
            "gold_discount",
            "gold_discount_is_currency",
            "platinum_discount",
            "platinum_discount_is_currency"
        ];

        let rate = this.getOutletRates();
        prop.push(rate);

        let params = '"' + prop.join('","') + '"';
        let filter = '&filters=[["disabled", "=", 0]]';
        let limit = '&limit_page_length=0';
        let api = fAppAPI() + "api/resource/Item/?fields=[" + params + "]" + filter + limit;

        return fetch(api, {
            method: 'GET',
            credentials: 'include',
        })
            .then((resp) => resp.json())
            .then((resj) => {
                localStorage.setItem('erpnext_item', JSON.stringify(resj.data));

                //console.log('products saved to local storage');
                //console.log('attempt to save items as select in local storage');
                let totalItems = this.setSelectItem('barcode');

                let items = JSON.parse(localStorage.getItem('erpnext_item'));

                let promotion = [];
                let popular = [];

                for (let i = 0; i < items.length; i++) {
                    if (items[i].popular === 1) {
                        let price = this.getItemPrice(items[i].name, i, true)
                        popular.push({
                            name: items[i].name,
                            item_name: items[i].item_name,
                            image: items[i].image,
                            price: price.price.toFixed(2)
                        })
                    }
                    else if (items[i].promotion === 1) {
                        let price = this.getItemPrice(items[i].name, i, true)
                        promotion.push({
                            name: items[i].name,
                            item_name: items[i].item_name,
                            image: items[i].image,
                            price: price.price.toFixed(2)
                        })
                    }
                }

                localStorage.setItem('popular_item', JSON.stringify(popular));
                localStorage.setItem('promotion_item', JSON.stringify(promotion));

                this.setState({ syncItem: 'done', totalItems: totalItems }, function () {
                    //console.log('syncItem DONE');
                })
            })
            .catch((error) => {
                console.log('error occured @method @getItem');
                console.log(error);
                this.setState({ syncItem: 'done' })
                this.toggleModal('error')
            })
    }

    setSelectItem(mode) {
        let items = JSON.parse(localStorage.getItem('erpnext_item'));
        let bin = JSON.parse(localStorage.getItem('erpnext_bin'));
        let selects = [];

        if (mode === 'barcode') {
            sessionStorage.setItem('select_item', JSON.stringify(''));
            for (let b = 0; b < bin.length; b++) {
                for (let i = 0; i < items.length; i++) {
                    if (items[i].name === bin[b].item_code && items[i].barcode !== null) {
                        selects.push({
                            value: items[i].name,
                            label: items[i].barcode
                        })
                    }
                }
            }
            sessionStorage.setItem('select_item', JSON.stringify(selects));
            console.log('product select now is BARCODE, saved to local storage');
            //console.log('total items are: ' + selects.length);
            return selects.length;
        }
        else if (mode === 'name') {
            sessionStorage.setItem('select_item', JSON.stringify(''));

            for (let b = 0; b < bin.length; b++) {
                for (let i = 0; i < items.length; i++) {
                    if (items[i].name === bin[b].item_code && items[i].item_name !== null) {
                        selects.push({
                            value: items[i].name,
                            label: items[i].item_name
                        })
                    }
                }
            }

            selects.push({
                value: 'SVC-TRANSPORT',
                label: 'Transportation Fee'
            })

            sessionStorage.setItem('select_item', JSON.stringify(selects));
            console.log('product select now is NAME, saved to local storage');
            return selects.length;
        }
        else if (mode === 'code') {
            sessionStorage.setItem('select_item', JSON.stringify(''));

            for (let b = 0; b < bin.length; b++) {
                for (let i = 0; i < items.length; i++) {
                    if (items[i].name === bin[b].item_code && items[i].name !== null) {
                        selects.push({
                            value: items[i].name,
                            label: items[i].name + ' - ' + items[i].item_name
                        })
                    }
                }
            }

            sessionStorage.setItem('select_item', JSON.stringify(selects));
            console.log('product select now is CODE, saved to local storage');
            return selects.length;
        }
        else if (mode === 'typebarcode') {
            sessionStorage.setItem('select_item', JSON.stringify(''));

            for (let b = 0; b < bin.length; b++) {
                for (let i = 0; i < items.length; i++) {
                    if (items[i].name === bin[b].item_code && items[i].barcode !== null) {
                        selects.push({
                            value: items[i].name,
                            label: items[i].barcode + ' - ' + items[i].item_name
                        })
                    }
                }
            }

            sessionStorage.setItem('select_item', JSON.stringify(selects));
            console.log('product select now is TYPE BARCODE, saved to local storage');
            return selects.length;
        }
    }

    async getItems() {
        try {
            await this.getBin()
        }
        catch (err) { console.log('failed @getItems!') }
    }

    getCustomers() {
        /**
        * @TODO we want to fetch customers from database
        * after fetching check if local storage for key 'erpnext_customer' exists
        * if not exists we create new entry for localStorage with key 'erpnext_customer'
        * length info:
        * ?limit_start=10&limit_page_length=20
        */
        this.setState({ eligible: false })

        //console.log('@getCustomers exec')
        // set params that will be saved onto localStorage
        let params = '["name", "customer_name", "customer_group", "reward_points", "nric", "mobile_number", "territory", "member_code"]';
        let filters = '&filters=[["disabled", "=", 0],["customer_group", "!=", "Franchise"],["customer_group", "!=", "Outlet"],["name", "!=", "Guest"]]';
        let limit = '&limit_page_length=10';
        let api = fAppAPI() + "api/resource/Customer/?fields=" + params + filters + limit;

        return fetch(api, {
            method: 'GET',
            credentials: 'include',
        })
            .then((resp) => resp.json())
            .then((resj) => {
                if (resj.data.length > 0) {
                    //console.log(resj.data);
                    localStorage.setItem('erpnext_customer', JSON.stringify(resj.data));
                    //localStorage.setItem('select_customer', JSON.stringify(''));
                    sessionStorage.setItem('select_customer', JSON.stringify(''));
                    //console.log('customer saved to localStorage')
                    this.setState({ totalCustomers: resj.data.length, })

                    //console.log('attempt to save customers data as select...')
                    this.setSelectCustomer('name');
                }
            })
            .catch((error) => {
                console.log('error occured @method @getCustomers')
                console.log(error)
                this.setState({ syncCustomer: 'done' })
                this.toggleModal('error')
            })
    }

    fetchCustomer(start, end) {
        /**
        * @TODO we want to fetch customers from database
        * after fetching check if local storage for key 'erpnext_customer' exists
        * if not exists we create new entry for localStorage with key 'erpnext_customer'
        * length info:
        * ?limit_start=10&limit_page_length=20
        */
        this.setState({ eligible: false })

        //console.log('@getCustomers exec')
        // set params that will be saved onto localStorage
        let params = '["name", "customer_name", "customer_group", "reward_points", "nric", "mobile_number", "territory", "member_code"]';
        let filters = '&filters=[["disabled", "=", 0],["customer_group", "!=", "Franchise"],["customer_group", "!=", "Outlet"],["name", "!=", "Guest"]]';
        let starts = '&limit_start=' + start;
        let limit = '&limit_page_length=0';
        let api = fAppAPI() + "api/resource/Customer/?fields=" + params + filters + starts + limit;

        //console.log('fetchCustomer api')
        //console.log(api)
        console.log('fetchCustomer exec, limit 2000, start: ' + start)

        return fetch(api, {
            method: 'GET',
            credentials: 'include',
        })
            .then((resp) => resp.json())
            .then((resj) => {
                if (resj.data.length > 0) {
                    localStorage.setItem('erpnext_customer', JSON.stringify(resj.data))
                    /*
                    let _customers = localStorage.getItem('erpnext_customer');
                    if(_customers === null) {
                      localStorage.setItem('erpnext_customer', JSON.stringify(resj.data))


                      let _empty = [];
                      _empty.push(JSON.parse(localStorage.getItem('erpnext_customer')));
                      localStorage.setItem('erpnext_customer', JSON.stringify(_empty));

                      console.log('creating new local data')
                      let _exists = [];
                      _exists = JSON.parse(localStorage.getItem('erpnext_customer'));
                      _exists.push(resj.data)
                      localStorage.setItem('erpnext_customer', JSON.stringify(_exists));

                    }
                    else {
                      /*
                      let _length = 0;

                      let _array = JSON.parse(localStorage.getItem('erpnext_customer'));
                      _length = _array[0].length;
                      console.log('adding data to existing local, length: ', _length)

                      _array[0].push(resj.data);
                      localStorage.setItem('erpnext_customer', JSON.stringify(_array));

                    }
                    */
                }
            })
            .catch((error) => {
                console.log('error occured @method @fetchCustomers')
                console.log(error)
                this.setState({ syncCustomer: 'done' })
                this.toggleModal('error')
            })
    }

    setSelectCustomer(mode) {
        console.log('@setSelectCustomer exec');
        let customers = JSON.parse(localStorage.getItem('erpnext_customer'));
        let selects = [];
        if (mode === 'name') {
            // localStorage.setItem('select_customer', JSON.stringify(''));
            sessionStorage.setItem('select_customer', JSON.stringify(''));
            for (let i = 0; i < customers.length; i++) {
                if (customers[i].customer_group !== 'Walk In' && customers[i].customer_name !== null) {
                    selects.push({ value: customers[i].name, label: customers[i].customer_name })
                }
            }
            // localStorage.setItem('select_customer', JSON.stringify(selects));
            sessionStorage.setItem('select_customer', JSON.stringify(selects));
            //console.log('customer select now is NAME, saved to local storage');
        }
        else if (mode === 'nric') {
            // localStorage.setItem('select_customer', JSON.stringify(''));
            sessionStorage.setItem('select_customer', JSON.stringify(''));
            for (let i = 0; i < customers.length; i++) {
                if (customers[i].customer_group !== 'Walk In' && customers[i].nric !== null) {
                    selects.push({ value: customers[i].name, label: customers[i].nric })
                }
            }
            sessionStorage.setItem('select_customer', JSON.stringify(selects));
            //localStorage.setItem('select_customer', JSON.stringify(selects));
            //console.log('customer select now is NRIC, saved to local storage');
        }
        else if (mode === 'qrcode') {
            //localStorage.setItem('select_customer', JSON.stringify(''));
            sessionStorage.setItem('select_customer', JSON.stringify(''));
            for (let i = 0; i < customers.length; i++) {
                if (customers[i].customer_group !== 'Walk In' && customers[i].member_code !== null) {
                    selects.push({ value: customers[i].name, label: customers[i].member_code })
                }
            }
            sessionStorage.setItem('select_customer', JSON.stringify(selects));
            //localStorage.setItem('select_customer', JSON.stringify(selects));
            //console.log('customer select now is QRCODE, saved to local storage');
        }
        this.setState({ syncCustomer: 'done' }, function () {
            console.log('syncCustomer DONE');
        })
    }

    //--------------------------------------------------------------------------

    getStockBalance(name) {
        if (this.state.nameProp !== name) {
            this.setState({
                stock: [],
                stockProp: null,
                nameProp: name
            });

            let fields = '["actual_qty", "warehouse", "item_code"]';
            let filter = '&filters=[["item_code", "=", "' + name + '"],["warehouse", "!=", "HQ - PPSB"]]';
            let api = fAppAPI() + "api/resource/Bin/?fields=" + fields + filter;
            //let stock = [];

            return fetch(api, {
                method: 'GET',
                credentials: 'include',
            })
                .then((res) => res.json())
                .then((resJson) => {
                    resJson.data.forEach((data) => {
                        let newQtyEntry = [...this.state.stock, { actual_qty: data.actual_qty, warehouse: data.warehouse }]
                        this.setState({
                            stock: newQtyEntry
                        }, function () {

                            let stock = this.state.stock;
                            let stockIndex = stock.findIndex(p => p.warehouse === this.state.inv_warehouse);
                            if (stockIndex !== -1) {
                                this.setState({
                                    stockProp: stock[stockIndex].actual_qty
                                }, function () {
                                    //console.log('propStock: ' + stock[stockIndex].actual_qty);
                                })
                            }
                            else {
                                //console.log('bug poped, stockIndex is: ' + stockIndex);
                                //console.log('item code is: ' + name);
                            }
                        });
                    })
                });
        }
    }

    getOutletName(warehouse) {
        switch (warehouse) {
            case 'Outlet Kudat - PK9F': return "Kudat";
            case 'Outlet Luyang - PPSB': return "Luyang";
            case 'Outlet Tawau - PK9F': return "Tawau";
            case 'Outlet Keningau - PK9F': return "Keningau";
            case 'Outlet Bundusan - PPSB': return "Bundusan";
            default: return null;
        }
    }

    mapStockBalance() {
        let stock = this.state.stock;
        return stock.map((item, index) => {
            return (
                <span key={index} style={{ marginRight: 5 }}>
                    {this.getOutletName(item.warehouse)}: {item.actual_qty},
                </span>
            )
        })
    }

    renderStockBalance() {
        if (this.state.stock.length > 0)
            return (
                <div id="stock">
                    <span style={{ marginRight: 5 }}>Stocks <strong>{this.state.nameProp}:</strong></span>
                    {this.mapStockBalance()}
                </div>
            )
    }

    prepStock() {
        this.toggleModal('spinner');
        this.timeoutHandle = setTimeout(() => {
            this.closeModal()
            this.toggleModal('stockreport')
        }, 1000)
    }

    //--------------------------------------------------------------------------

    syncStatus() {
        let syncItem = this.state.syncItem;
        let syncBin = this.state.syncBin;
        let syncCustomer = this.state.syncCustomer;

        if (syncItem === 'done' && syncCustomer === 'done' && syncBin === 'done') {
            return (
                <div>
                    <div>
                        Sync Completed
                        <i className="fa fa-check-circle" style={{ color: 'green', marginLeft: 10 }}></i>
                    </div>
                    <br />
                    <div>
                        Products: {this.state.totalItems}<br />
                        Customers: {this.state.totalCustomers}<br />
                    </div>
                </div>
            );
        }
        else {
            return (
                <span>
                    Retrieving Products & Customer Data, Please Wait...
                    <i className="fa fa-cog fa-spin fa-fw"></i>
                </span>
            );
        }
    }

    syncData(newmember) {
        if (newmember !== undefined && newmember !== null) {
            this.toggleModal('syncnoempty');
            this.setState({
                syncing: 'yes',
                syncBin: 'yes',
                syncItem: 'yes',
                syncCustomer: 'yes',
                customers: [],
                products: [],
                lengthItems: 0,
                totalItems: 0,
                totalCustomers: 0
            });
            localStorage.removeItem('erpnext_bin');
            localStorage.removeItem('erpnext_item');
            localStorage.removeItem('erpnext_customer');
            //localStorage.removeItem('select_customer');
            //localStorage.removeItem('select_item');
            sessionStorage.removeItem('select_customer');
            sessionStorage.removeItem('select_item');
            localStorage.removeItem('promotion_item');
            localStorage.removeItem('popular_item');
            localStorage.removeItem('erpnext_salesperson');

            this.getBdayCode();
            this.getMRPTier();
            this.getItems();
            this.getSalesPerson();

            this.timeoutHandle = setTimeout(() => {
                this.syncCustomers();

                this.setState({
                    customer: newmember.customer,
                    customerID: newmember.customerID,
                    membership: newmember.membership,
                    point: newmember.point,
                    nric: newmember.nric,
                    phone: newmember.phone,
                    reg_false: false,
                    bdayused: false,
                    selectedCustomer: {
                        value: newmember.customerID,
                        label: newmember.customer
                    }
                }, function () {
                    console.log('syncData has new member data')
                    console.log(newmember)
                    console.log('selected customer has been set')
                    console.log(this.state.selectedCustomer)
                })
            }, 5000)
        }
        else {
            this.toggleModal('sync');
            this.setState({
                syncing: 'yes',
                syncBin: 'yes',
                syncItem: 'yes',
                syncCustomer: 'yes',
                customers: [],
                products: [],
                lengthItems: 0,
                totalItems: 0,
                totalCustomers: 0
            });
            localStorage.removeItem('erpnext_bin');
            localStorage.removeItem('erpnext_item');
            localStorage.removeItem('erpnext_customer');
            //localStorage.removeItem('select_customer');
            //localStorage.removeItem('select_item');
            sessionStorage.removeItem('select_customer');
            sessionStorage.removeItem('select_item');
            localStorage.removeItem('promotion_item');
            localStorage.removeItem('popular_item');
            localStorage.removeItem('erpnext_salesperson');

            this.getBdayCode();
            this.getMRPTier();
            this.getItems();
            this.getSalesPerson();

            this.timeoutHandle = setTimeout(() => {
                this.syncCustomers();
            }, 5000)
        }
    }

    async syncCustomers() {
        try {
            await this.fetchCustomer()
        }
        catch (err) { console.log('failed @syncCustomer!') }
        /*
        try {
          await this.fetchCustomer(1, 0)
          console.log('sync customer from 1 - 2000')
        }
        catch(err) {console.log('failed @syncCustomer batch 1!')}


        try {
          await this.fetchCustomer(2001, 4000)
          console.log('sync customer from 20001 - 4000')
        }
        catch(err) {console.log('failed @syncCustomer batch 2!')}

        try {
          await this.fetchCustomer(4001, 6000)
          console.log('sync customer from 4001 - 6000')
        }
        catch(err) {console.log('failed @syncCustomer batch 3!')}

        try {
          await this.fetchCustomer(6001, 8000)
          console.log('sync customer from 6001 - 8000')
        }
        catch(err) {console.log('failed @syncCustomer batch 4!')}

        try {
          await this.fetchCustomer(8001, 10000)
          console.log('sync customer from 8001 - 10000')
        }
        catch(err) {console.log('failed @syncCustomer batch 5!')}
        */

        let custs = JSON.parse(localStorage.getItem('erpnext_customer'))
        this.setState({ totalCustomers: custs.length, })

        //localStorage.setItem('select_customer', JSON.stringify(''));
        sessionStorage.setItem('select_customer', JSON.stringify(''));
        this.setSelectCustomer('name');
    }

    //--------------------------------------------------------------------------

    listItems() {
        if (this.state.products !== null) {
            let items = this.state.products;
            return items.map((item, index) => {
                return (
                    <li key={index}>{item.item_name} (Last Modified: {item.modified})</li>
                )
            })
        }
    }

    //--------------------------------------------------------------------------

    toggleModal(action, index, code, price, count, foc) {
        // get the modal action first
        if (action !== undefined) {
            this.setState({
                modalAction: action,
                indexProp: index,
                codeProp: code,
                priceProp: price,
                countProp: count,
                focProp: foc,
            }, function () {
                switch (action) {
                    case 'paynow':
                        this.setState({
                            cash: 0.00,
                            paid: 0.00,
                            change: 0.00,
                            modalVisible: true
                        })
                        break;

                    case 'paynowcash':
                        this.setState({
                            cash: this.state.sum,
                            paid: '',
                            change: '',
                            modalVisible: true
                        })
                        break;

                    case 'changerate':
                        this.setState({ newdiscount: this.state.priceProp })
                        break;

                    case 'printclosing':
                        this.setState({ modalVisible: true })
                        break;

                    case 'printreport':
                        this.setState({ modalVisible: true })
                        break;

                    default:
                        return null;
                }
            });
        }

        if (this.state.modalVisible === false) {
            this.setState({ modalVisible: true })
        }
        else {
            if (this.state.modalAction !== 'spinner') {
                this.setState({ modalVisible: false })
            }
        }
    }

    closeModal() {
        this.setState({
            modalAction: '',
            indexProp: null,
            codeProp: null,
            priceProp: null,
            countProp: null
        }, function () {
            this.toggleModal()
        })
    }

    reOpenModal(action) {
        this.setState({ isReturn: false })
        if (action === 'dailyreport') {
            this.closeModal()
            this.prepReport()
        }
        else {
            this.closeModal()
            this.toggleModal(action)
        }
    }

    //--------------------------------------------------------------------------

    updateMinusProp(operation) {
        let max = this.state.countProp;
        let int = this.state.minusProp;
        let code = this.state.codeProp;
        let cart = this.state.cart;
        //let unit        = this.state.unit;
        //let units       = this.state.units;
        let cartIndex = cart.findIndex(c => c.code === code);
        //let unitIndex   = unit.findIndex( u => u.code === cart[cartIndex].code );
        //let curValue    = unit[unitIndex].value;

        if (cartIndex !== -1) {
            switch (operation) {
                case '+':
                    if (int < max) {
                        this.setState({
                            minusProp: int + 1
                        })
                    }
                    break;

                case '-':
                    if (int !== 0) {
                        this.setState({
                            minusProp: int - 1
                        })
                    }
                    break;

                default:
                    return null;
            }
        }
    }

    updateQty(operation) {
        //let max         = this.state.countProp;
        let int = this.state.minusProp;
        let code = this.state.codeProp;
        let cart = this.state.cart;
        //let unit        = this.state.unit;
        //let units       = this.state.units;
        let cartIndex = cart.findIndex(c => c.code === code);
        //let unitIndex   = unit.findIndex( u => u.code === cart[cartIndex].code );
        //let curValue    = unit[unitIndex].value;

        if (cartIndex !== -1) {
            switch (operation) {
                case '+':
                    this.setState({
                        minusProp: int + 1
                    }, function () {
                        console.log('minusProp added')
                    })
                    break;

                case '-':
                    if (int !== 0) {
                        this.setState({
                            minusProp: int - 1
                        }, function () {
                            console.log('minusProp minused')
                        })
                    }
                    break;

                default:
                    return;
            }
        }
    }

    handleChangeU(event) {
        this.setState({ username: event.target.value });
    }

    handleChangeP(event) {
        this.setState({ password: event.target.value });
    }

    handleChangeFee(e) {
        this.setState({
            serviceFee: e.target.value
        })
    }

    handleChangeReportDate(date) {
        this.setState({
            report_date: date
        }, function () {
            this.closeModal()
            this.prepReport(date)
        });
    }

    handleChangeNewDiscount(event) {
        let type = this.state.countProp;
        let price = this.state.codeProp;
        let value = event.target.value;
        let climit = this.state.currencylimit;
        let plimit = this.state.percentagelimit;

        if (this.state.countProp === 'RM') {
            if (Number(value) <= Number(climit) && Number(value) <= Number(price)) {
                this.setState({
                    newdiscount: value
                });
            }
        }
        else if (this.state.countProp === '%') {
            if (value <= plimit) {
                this.setState({
                    newdiscount: value
                });
            }
        }
    }

    handleChangeNewQty(event) {
        console.log('try exec @handleChangeNewQty')
        let int = this.state.minusProp;
        let code = this.state.codeProp;
        let value = event.target.value;

        let cart = this.state.cart;
        let cartIndex = cart.findIndex(c => c.code === code);

        if (cartIndex !== -1) {
            if (Number(value)) {
                this.setState({
                    minusProp: Number(value)
                })
            }
            else {
                this.setState({
                    minusProp: Number(0)
                })
            }
        }
        else {
            console.log('cartIndex not found')
        }
    }

    onBlurPaid() {
        let paid = this.state.paid;
        let cash = this.state.cash;

        let val = paid - cash
        this.setState({
            change: val.toFixed(2),
        }, function () {
            this.setState({
                paid: Number(paid).toFixed(2)
            })
        })
    }

    handleChangeMWInput = (event) => {
        const { target: { name, value } } = event
        this.setState({
            [name]: value,
            reg_false: false,
            reg_duplicate: false
        })
    }

    handleClosingInput = (event) => {
        const { target: { name, value, placeholder, id } } = event
        let val = (value * [placeholder]).toFixed(2)
        this.setState({
            [name]: value,
            [id]: val
        }, function () {
            this.getTotalCent()
            this.getTotalRM()
        });
    }

    getTotalCent() {
        let values = [
            parseFloat(this.state.cl_sum_cent_1),
            parseFloat(this.state.cl_sum_cent_5),
            parseFloat(this.state.cl_sum_cent_10),
            parseFloat(this.state.cl_sum_cent_20),
            parseFloat(this.state.cl_sum_cent_50),
            parseFloat(this.state.cl_sum_cent_rm1)
        ];

        let add = (a, b) => a + b;
        let gt = values.reduce(add);

        //console.log('@getTotalCent exec')
        //console.log('cents array: ' + values )
        //console.log('cents gt: ' + gt)

        this.setState({
            cl_total_cent: gt.toFixed(2)
        }, function () {
            this.getCLTotal()
        });
    }

    getTotalRM() {
        let values = [
            parseFloat(this.state.cl_sum_rm_1),
            parseFloat(this.state.cl_sum_rm_5),
            parseFloat(this.state.cl_sum_rm_10),
            parseFloat(this.state.cl_sum_rm_20),
            parseFloat(this.state.cl_sum_rm_50),
            parseFloat(this.state.cl_sum_rm_100)
        ];

        let add = (a, b) => a + b;
        let gt = values.reduce(add);

        //console.log('@getTotalRM exec')
        //console.log('rm array: ' + values )
        //console.log('rm gt: ' + gt)

        this.setState({
            cl_total_rm: gt.toFixed(2)
        }, function () {
            this.getCLTotal()
        });
    }

    getCLTotal() {
        let cents = parseFloat(this.state.cl_total_cent);
        let rm = parseFloat(this.state.cl_total_rm);
        let total = rm + cents;
        this.setState({ cl_total: total.toFixed(2) });
    }

    setEligible() {
        let eligible = this.state.eligible;
        if (eligible === false) {
            this.setState({ eligible: true }, function () {
                //console.log('eligible is true');
            })
        }
        else {
            this.setState({ eligible: false }, function () {
                //console.log('eligible is false');
            })
        }
    }

    renderEligible(pts) {
        if (this.state.membership === 'Standard' && pts >= this.state.classic_min) {
            return (
                <div>
                    <div className="row">
                        <div className="alert alert-warning">
                            This member current transaction has a total over RM {this.state.classic_min} in a single
                            receipt and eligible for membership upgrade,
                            from Standard to Classic.
                            <br />
                            <br />
                            <input type="checkbox" onChange={() => this.setEligible()} /> Confirm Upgrade
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderRegFalse() {
        if (this.state.reg_false === true) {
            return (
                <div>
                    <div className="alert alert-warning">
                        Kindly complete the form as required to complete the registration.
                    </div>
                </div>
            )
        }
    }

    renderRegDuplicate() {
        if (this.state.reg_duplicate === true) {
            return (
                <div>
                    <div className="alert alert-warning">
                        <label>NRIC {this.state.reg_nric}</label> already registered as a member!
                    </div>
                </div>
            )
        }
    }

    renderMakeReturnBtn() {
        if (this.state.returner === 1) {
            if (this.state.showUtilBtn === true) {
                return (
                    <Button
                        className="btn btn-warning"
                        //onClick={()=> this.execReturn(this.state.inv_name)}
                        onClick={() => this.toggleIsReturn()}
                    >
                        <i className="fa fa-refresh"></i>
                        <span style={{ marginLeft: 5 }}>Make Return</span>
                    </Button>
                )
            }
        }
    }

    renderReactToPrintBtn() {
        if (this.state.showUtilBtn === true) {
            return (
                <ReactToPrint
                    inv={this.state.inv_data}
                    trigger={() => <Button className="btn btn-success"><i className="fa fa-print"></i> Print</Button>}
                    content={() => this.componentRef}
                />
            )
        }
    }

    renderRefForm() {
        if (this.state.mrpenabled === 1) {
            return (
                <React.Fragment>
                    <hr />
                    <div className="form-group">
                        <label className="col-sm-3 control-label"></label>
                        <div className="col-sm-9">
                            <label className="control-label">Member Reference</label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-sm-3" align="right">
                            <Button
                                className="btn btn-default btn-toggle-nric"
                                onClick={() => this.toggleCustomerSearchOption()}
                            >
                                <i className="fa fa-refresh"></i>
                            </Button>
                        </div>
                        <div className="col-sm-9">
                            <AsyncSelect
                                value={this.state.customerRefLabel}
                                loadOptions={this.loadCustomerOptionsOnRegister}
                                onInputChange={this.handleAsyncSelectCustomer}
                                onChange={this.handleChangeCustomerOnRegister}
                                placeholder={this.state.customerSearchPlaceHolder}
                            />
                            {this.renderMembershipWarning()}
                        </div>
                    </div>
                    {this.renderRefMember()}
                </React.Fragment>
            );
        }
    }

    renderRedemptionData(qty) {
        if (this.state.fetchingredeem === false) {
            let data = this.state.redemption;
            return data.map((item, index) => {

                let collected = '';
                let button = '';
                let qty = 1

                if (item.qty_sent !== undefined && item.qty_sent !== null) {
                    qty = item.qty_sent
                }

                if (item.status === 'Request Cancelled') {
                    collected = 'Cancelled: ' + item.cancelation_reason
                    button = 'N/A'
                }
                else if (item.status === 'Item Collected') {
                    collected = item.date_collected
                    button = 'N/A'
                }
                else if (item.status === 'Item Ready') {
                    collected = 'Not Yet'
                    button = <Button className="btn btn-sm btn-success" onClick={() => this.execRedeem(item.item, item.points * qty, item.name, index)}>Collected</Button>
                }
                else if (item.status === 'Request Sent' || item.status === 'Request Received') {
                    collected = 'Processing Request'
                    button = 'N/A'
                }
                /*
                let collected = item.date_collected;
                let button = 'N/A';
                if( (item.date_collected === '' || item.date_collected === null) && item.cancelation_reason === '' ) {
                  collected = 'Not Yet'
                  button = <Button className="btn btn-sm btn-success" onClick={()=>this.execRedeem(item.item, item.points, item.name)}>Collected</Button>
                }
                else if( (item.date_collected === '' || item.date_collected === null) && item.cancelation_reason !== '' ) {
                  collected = "Cancelled: "+item.cancelation_reason
                  button = 'N/A';
                }
                else {
                  collected = "Not Yet"
                  button = <Button className="btn btn-sm btn-success" onClick={()=>this.execRedeem(item.item, item.points, item.name)}>Collected</Button>
                }
                */

                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.item} - {item.item_name}</td>
                        <td>{item.points}</td>
                        <td>{item.qty}</td>
                        <td>{item.qty_sent}</td>
                        <td>{item.status}</td>
                        <td>{item.date_requested}</td>
                        <td>{collected}</td>
                        <td>{button}</td>
                    </tr>
                );
            })
        }
        else {
            return (
                <tr>
                    <td colSpan="7">
                        <div align="center">
                            Please Wait... <i className="fa fa-circle-o-notch fa-spin fa-fw app-spinner-color"></i>
                        </div>
                    </td>
                </tr>
            );
        }
    }

    salesPersonOptions() {
        let salespersons = JSON.parse(localStorage.getItem('erpnext_salesperson'));
        let obj = [];
        if (salespersons !== null) {
            let items = salespersons;

            for (let i = 0; i < items.length; i++) {
                obj.push({
                    value: items[i].value,
                    label: items[i].label
                })
            }
        }
        return obj;
    }

    renderModal() {
        switch (this.state.modalAction) {
            case 'redemption':
                return (
                    <div>
                        <Modal.Header>
                            <Modal.Title>{this.state.customer} &bull; Redemption History</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div id="multipaymentwindow">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Item</th>
                                            <th>Pts Req.</th>
                                            <th>Qty Req</th>
                                            <th>Qty Sent</th>
                                            <th>Status</th>
                                            <th>Requested</th>
                                            <th>Collected</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.renderRedemptionData()}
                                    </tbody>
                                </table>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="btn btn-default" onClick={() => this.closeModal()}>Cancel</Button>
                        </Modal.Footer>
                    </div>
                );
                break;

            case 'paynowcash':
                let pcbasepoint = Math.round(Number(this.state.sum));
                let pctominus = this.state.ptstominus;
                let pcpoints = Number(pcbasepoint) - Number(pctominus);
                let pcptstodeduct = this.getPtsToMinus();
                pcpoints = Number(pcpoints) - pcptstodeduct;

                let salespersonopt = this.salesPersonOptions()

                return (
                    <div>
                        <Modal.Header>
                            <Modal.Title>(CASH) Payment Details . Grand Total {this.state.sum}</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            {this.renderEligible(pcpoints)}
                            <div className="row">
                                <div className="col-md-12">

                                    <label><small>Amount</small></label>
                                    <input
                                        name="cash"
                                        type="text"
                                        className="form-control mpw-input"
                                        value={this.state.cash}
                                        onChange={this.handleChangeMWInput}
                                        style={{ marginBottom: 10 }}
                                    />
                                    <input
                                        name="paid"
                                        type="text"
                                        className="form-control mpw-input"
                                        value={this.state.paid}
                                        onChange={this.handleChangeMWInput}
                                        placeholder="Received"
                                        style={{ marginBottom: 10 }}
                                        onBlur={() => this.onBlurPaid()}
                                    />
                                    <input
                                        name="change"
                                        type="text"
                                        className="form-control mpw-input"
                                        value={this.state.change}
                                        onChange={this.handleChangeMWInput}
                                        style={{ marginBottom: 10 }}
                                        placeholder="Change"
                                    />

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div>
                                        Reward Points:<br />
                                        <div style={{ textAlign: 'left' }}>
                                            <strong style={{ fontSize: 16 }}>{pcpoints}</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row" style={{ marginTop: 20 }}>
                                <div className="col-md-12 pull-left">
                                    <Select
                                        name="selectedSalesPerson"
                                        value={this.state.selectedSalesPerson}
                                        onChange={this.handleChangeSalesPerson}
                                        options={salespersonopt}
                                        placeholder="Select Sales Person"
                                    />
                                </div>
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="pull-right">
                                        <Button className="btn btn-success btn-lg" onClick={() => this.checkCash()}>PAY</Button>
                                        <Button className="btn btn-default btn-lg" onClick={() => this.closeModal()}>Cancel</Button>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <hr />
                                    <Button
                                        className="btn btn-primary btn-lg"
                                        onClick={() => this.toggleModal('paynow')}>
                                        USE OTHERS / MIXED PAYMENT METHOD
                                    </Button>
                                </div>
                            </div>
                        </Modal.Footer>
                    </div>
                );
                break;

            case 'paynow':
                let basepoint = Math.round(Number(this.state.sum));
                let tominus = this.state.ptstominus;
                let points = Number(basepoint) - Number(tominus);
                let ptstodeduct = this.getPtsToMinus();
                points = Number(points) - ptstodeduct;

                const { selectedCCType, selectedept } = this.state;
                const cards = [
                    { value: 'VISA', label: 'VISA' },
                    { value: 'MASTERCARD', label: 'MASTERCARD' },
                    { value: 'DEBIT CARD', label: 'DEBIT CARD' },
                    { value: 'OTHERS', label: 'OTHERS' }
                ];

                const epaytype = [
                    { value: 'Bank Transfer', label: 'Bank Transfer' },
                    { value: 'Boost', label: 'Boost' },
                    { value: 'Quinpay', label: 'Quinpay' },
                    { value: 'Splus', label: 'Splus' }
                ];

                let spopt = this.salesPersonOptions();

                return (
                    <div>
                        <Modal.Header>
                            <Modal.Title>Payment Details . Grand Total {this.state.sum}</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <div id="multipaymentwindow">
                                {this.renderEligible(points)}
                                <div className="row">

                                    <div className="col-md-3">
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <i className="fa fa-money"></i>
                                                <label>cash</label>
                                            </div>
                                            <div className="panel-body">
                                                <label><small>Amount</small></label>
                                                <input
                                                    name="cash"
                                                    type="text"
                                                    className="form-control mpw-input"
                                                    value={this.state.cash}
                                                    onChange={this.handleChangeMWInput}
                                                />
                                                <input
                                                    name="paid"
                                                    type="text"
                                                    className="form-control mpw-input"
                                                    value={this.state.paid}
                                                    onChange={this.handleChangeMWInput}
                                                    placeholder="Received"
                                                    onBlur={() => this.onBlurPaid()}
                                                />
                                                <input
                                                    name="change"
                                                    type="text"
                                                    className="form-control mpw-input"
                                                    value={this.state.change}
                                                    onChange={this.handleChangeMWInput}
                                                    placeholder="Change"
                                                />
                                            </div>
                                            <div className="panel-footer">
                                                <small>MYR Only</small>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <i className="fa fa-credit-card"></i>
                                                <label>credit/debit card</label>
                                            </div>
                                            <div className="panel-body">
                                                <label><small>Amount</small></label>
                                                <input
                                                    name="creditcard"
                                                    type="text"
                                                    className="form-control mpw-input"
                                                    value={this.state.creditcard}
                                                    onChange={this.handleChangeMWInput}
                                                />
                                                <input
                                                    name="approvalcode"
                                                    type="text"
                                                    className="form-control mpw-input"
                                                    value={this.state.approvalcode}
                                                    onChange={this.handleChangeMWInput}
                                                    placeholder="Approval Code"
                                                />
                                                <input
                                                    name="merchantcode"
                                                    type="text"
                                                    className="form-control mpw-input"
                                                    value={this.state.merchantcode}
                                                    onChange={this.handleChangeMWInput}
                                                    placeholder="Merchant Code"
                                                />
                                                <input
                                                    name="merchantid"
                                                    type="text"
                                                    className="form-control mpw-input"
                                                    value={this.state.merchantid}
                                                    onChange={this.handleChangeMWInput}
                                                    placeholder="Merchant ID"
                                                />
                                                <input
                                                    name="cardholder"
                                                    type="text"
                                                    className="form-control mpw-input"
                                                    value={this.state.cardholder}
                                                    onChange={this.handleChangeMWInput}
                                                    placeholder="Card Holder"
                                                />

                                                <Select
                                                    name="selectedCCType"
                                                    value={selectedCCType}
                                                    onChange={this.handleChangeCCType}
                                                    options={cards}
                                                    placeholder="Select Card Type"
                                                />
                                                <div style={{ height: 10 }}></div>
                                                <input
                                                    name="ccno"
                                                    type="text"
                                                    className="form-control mpw-input"
                                                    value={this.state.ccno}
                                                    onChange={this.handleChangeMWInput}
                                                    placeholder="Credit Card Number"
                                                />
                                                <input
                                                    name="ccexpiry"
                                                    type="text"
                                                    className="form-control mpw-input"
                                                    value={this.state.ccexpiry}
                                                    onChange={this.handleChangeMWInput}
                                                    placeholder="Expiry Date"
                                                />
                                            </div>
                                            <div className="panel-footer">
                                                <small>VISA / MASTERCARD</small>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <i className="fa fa-university"></i>
                                                <label>E-Payment</label>
                                            </div>
                                            <div className="panel-body">
                                                <label><small>Amount</small></label>
                                                <input
                                                    name="epayment"
                                                    type="text"
                                                    className="form-control mpw-input"
                                                    value={this.state.epayment}
                                                    onChange={this.handleChangeMWInput}
                                                    placeholder="E-Payment"
                                                />
                                                <Select
                                                    name="selectedept"
                                                    value={selectedept}
                                                    onChange={this.handleEPType}
                                                    options={epaytype}
                                                    placeholder="Select E-Payment Type"
                                                />
                                                <div style={{ height: 10 }}></div>
                                                <input
                                                    name="epaymentcode"
                                                    type="text"
                                                    className="form-control mpw-input"
                                                    value={this.state.epaymentcode}
                                                    onChange={this.handleChangeMWInput}
                                                    placeholder="E-Payment Code"
                                                />
                                                <input
                                                    name="epaymentbank"
                                                    type="text"
                                                    className="form-control mpw-input"
                                                    value={this.state.epaymentbank}
                                                    onChange={this.handleChangeMWInput}
                                                    placeholder="E-Payment Bank To"
                                                />
                                            </div>
                                            <div className="panel-footer">
                                                <small>Bank Transfer / Boost</small>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <i className="fa fa-edit"></i>
                                                <label>cheque</label>
                                            </div>
                                            <div className="panel-body">
                                                <label><small>Amount</small></label>
                                                <input
                                                    name="cheque"
                                                    type="text"
                                                    className="form-control mpw-input"
                                                    value={this.state.cheque}
                                                    onChange={this.handleChangeMWInput}
                                                    placeholder="Cheque Amount"
                                                />
                                                <input
                                                    name="chequeno"
                                                    type="text"
                                                    className="form-control mpw-input"
                                                    value={this.state.chequeno}
                                                    onChange={this.handleChangeMWInput}
                                                    placeholder="Cheque Number"
                                                />
                                                <input
                                                    name="chequebank"
                                                    type="text"
                                                    className="form-control mpw-input"
                                                    value={this.state.chequebank}
                                                    onChange={this.handleChangeMWInput}
                                                    placeholder="Cheque Bank"
                                                />
                                            </div>
                                            <div className="panel-footer">
                                                <small>Cheque NO required</small>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Modal.Body>

                        <Modal.Footer>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4" align="right">
                                        <div style={{ textAlign: 'left' }}>
                                            <Select
                                                name="selectedSalesPerson"
                                                value={this.state.selectedSalesPerson}
                                                onChange={this.handleChangeSalesPerson}
                                                options={spopt}
                                                placeholder="Select Sales Person"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row" style={{ marginTop: 20 }}>
                                <div class="col-md-12">
                                    <div className="col-md-6">
                                        <div style={{ textAlign: 'left' }}>
                                            Reward Points:<br />
                                            <strong style={{ fontSize: 16 }}>{points}</strong>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div style={{ textAlign: 'right' }}>
                                            <Button className="btn btn-success btn-lg" onClick={() => this.checkCash()}>PAY</Button>
                                            <Button className="btn btn-default btn-lg" onClick={() => this.closeModal()}>Cancel</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Modal.Footer>
                    </div>
                );
                break;

            case 'printreport':
                return (
                    <div>
                        <Modal.Header>
                            <Modal.Title>Print Report</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <PrintReport
                                ref={el => (this.componentReportRef = el)}
                                items={this.state.report_item_data}
                                cashier={this.state.firstname}
                                outlet={this.state.company}
                                company={this.state.inv_company}
                                date={this.state.inv_posting_date}
                                cash={this.state.inv_cash}
                                creditcard={this.state.inv_creditcard}
                                epayment={this.state.inv_epayment}
                                boost={this.state.inv_boost}
                                quinpay={this.state.inv_quinpay}
                                splus={this.state.inv_splus}
                                cheque={this.state.inv_cheque}
                                sum={this.state.inv_total}
                                ret_cash={this.state.ret_cash}
                                ret_creditcard={this.state.ret_creditcard}
                                ret_epayment={this.state.ret_epayment}
                            />
                        </Modal.Body>

                        <Modal.Footer>
                            <ReactToPrint
                                trigger={() => <Button className="btn btn-success"><i className="fa fa-print"></i> Print</Button>}
                                content={() => this.componentReportRef}
                            />
                            <Button className="btn btn-primary" onClick={() => this.reOpenModal('dailyreport')}>Report</Button>
                            <Button className="btn btn-default" onClick={() => this.closeModal()}>Cancel</Button>
                        </Modal.Footer>
                    </div>
                );
                break;

            case 'printreceipt':
                return (
                    <div>
                        <Modal.Header>
                            <Modal.Title>Print Receipt {this.state.inv_name}</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <PrintReceipt
                                ref={el => (this.componentRef = el)}
                                inv={this.state.inv_name}
                                tax={this.state.taxrate}
                                cashier={this.state.firstname}
                                customer={this.state.customer}
                                outlet={this.state.company}
                                address={this.state.inv_address}
                                salesperson={this.state.salesperson_name}
                            />
                        </Modal.Body>

                        <Modal.Footer>
                            <ReactToPrint
                                inv={this.state.inv_data}
                                trigger={() => <Button className="btn btn-primary"><i className="fa fa-print"></i> Print</Button>}
                                content={() => this.componentRef}
                            />
                            <Button className="btn btn-default" onClick={() => this.closeModal()}>Cancel</Button>
                        </Modal.Footer>
                    </div>
                );
                break;

            case 'printreportinv':
                return (
                    <div>
                        <Modal.Header>
                            <Modal.Title>Print Receipt {this.state.inv_name}</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <PrintReceipt
                                ref={el => (this.componentRef = el)}
                                inv={this.state.inv_name}
                                tax={this.state.taxrate}
                                cashier={this.state.firstname}
                                customer={this.state.customer}
                                outlet={this.state.company}
                                address={this.state.inv_address}
                                isReturn={this.state.isReturn}
                                utilBtn={this.toggleUtilBtn}
                                acc_cash={this.state.inv_account_cash}
                                acc_cc={this.state.inv_account_creditcard}
                                acc_ep={this.state.inv_account_epayment}
                            />
                        </Modal.Body>

                        <Modal.Footer>
                            <div className="pull-left">
                                {this.renderMakeReturnBtn()}
                            </div>
                            <div className="pull-right">
                                {
                                    /*
                                    <ReactToPrint
            inv={this.state.inv_data}
            trigger={ () => <Button className="btn btn-success"><i className="fa fa-print"></i> Print</Button> }
            content={ () => this.componentRef }
        />
                                    */
                                }
                                {this.renderReactToPrintBtn()}
                                <Button className="btn btn-primary" onClick={() => this.reOpenModal('dailyreport')}>Report</Button>
                                <Button className="btn btn-default" onClick={() => this.closeModal()}>Cancel</Button>
                            </div>
                        </Modal.Footer>
                    </div>
                );
                break;

            case 'printhistoryinv':
                return (
                    <div>
                        <Modal.Header>
                            <Modal.Title>Print Receipt {this.state.inv_name}</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <PrintReceipt
                                ref={el => (this.componentRef = el)}
                                inv={this.state.inv_name}
                                tax={this.state.taxrate}
                                cashier={this.state.firstname}
                                customer={this.state.customer}
                                outlet={this.state.company}
                                address={this.state.inv_address}
                                isReturn={this.state.isReturn}
                                utilBtn={this.toggleUtilBtn}
                                ishistory={true}
                            />
                        </Modal.Body>

                        <Modal.Footer>
                            <div className="pull-left">
                            </div>
                            <div className="pull-right">
                                {this.renderReactToPrintBtn()}
                                <Button className="btn btn-primary" onClick={() => this.reOpenModal('purchasehistory')}>Back To History</Button>
                                <Button className="btn btn-default" onClick={() => this.closeModal()}>Close</Button>
                            </div>
                        </Modal.Footer>
                    </div>
                );
                break;

            case 'printclosing':
                return (
                    <div>
                        <Modal.Header>
                            <Modal.Title>Print Closing {this.state.inv_posting_date}</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <PrintClosing
                                ref={el => (this.componentClosingRef = el)}
                                cashier={this.state.firstname}
                                outlet={this.state.company}
                                company={this.state.inv_company}
                                date={this.state.inv_posting_date}
                                cash={this.state.inv_cash}
                                ret_cash={this.state.ret_cash}
                                creditcard={this.state.inv_creditcard}
                                ret_creditcard={this.state.ret_creditcard}
                                epayment={this.state.inv_epayment}
                                boost={this.state.inv_boost}
                                quinpay={this.state.inv_quinpay}
                                splus={this.state.inv_splus}
                                ret_epayment={this.state.ret_epayment}
                                cheque={this.state.inv_cheque}
                                return={this.state.ret_cash}
                                sum={this.state.inv_total}
                                cl_cent_1={this.state.cl_cent_1}
                                cl_sum_cent_1={this.state.cl_sum_cent_1}
                                cl_cent_5={this.state.cl_cent_5}
                                cl_sum_cent_5={this.state.cl_sum_cent_5}
                                cl_cent_10={this.state.cl_cent_10}
                                cl_sum_cent_10={this.state.cl_sum_cent_10}
                                cl_cent_20={this.state.cl_cent_20}
                                cl_sum_cent_20={this.state.cl_sum_cent_20}
                                cl_cent_50={this.state.cl_cent_50}
                                cl_sum_cent_50={this.state.cl_sum_cent_50}
                                cl_cent_rm1={this.state.cl_rm_1}
                                cl_sum_cent_rm1={this.state.cl_sum_cent_rm1}
                                cl_rm_1={this.state.cl_rm_1}
                                cl_sum_rm_1={this.state.cl_sum_rm_1}
                                cl_rm_5={this.state.cl_rm_5}
                                cl_sum_rm_5={this.state.cl_sum_rm_5}
                                cl_rm_10={this.state.cl_rm_10}
                                cl_sum_rm_10={this.state.cl_sum_rm_10}
                                cl_rm_20={this.state.cl_rm_20}
                                cl_sum_rm_20={this.state.cl_sum_rm_20}
                                cl_rm_50={this.state.cl_rm_50}
                                cl_sum_rm_50={this.state.cl_sum_rm_50}
                                cl_rm_100={this.state.cl_rm_100}
                                cl_sum_rm_100={this.state.cl_sum_rm_100}
                                cl_total_cent={this.state.cl_total_cent}
                                cl_total_rm={this.state.cl_total_rm}
                                cl_total={this.state.inv_cash}
                            />
                        </Modal.Body>

                        <Modal.Footer>
                            <ReactToPrint
                                trigger={() => <Button className="btn btn-primary"><i className="fa fa-print"></i> Print</Button>}
                                content={() => this.componentClosingRef}
                            />
                            <Button className="btn btn-success" onClick={() => this.reOpenModal('closing')}>Re - Count</Button>
                            <Button className="btn btn-default" onClick={() => this.closeModal()}>Cancel</Button>
                        </Modal.Footer>
                    </div>
                );
                break;

            case 'emptycart':
                return (
                    <div>
                        <Modal.Header>
                            <Modal.Title>Confirmation Required</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            Are you sure you want to cancel the sale?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="btn btn-danger" onClick={() => this.emptyCart()}>OK</Button>
                            <Button className="btn btn-default" onClick={() => this.closeModal()}>Cancel</Button>
                        </Modal.Footer>
                    </div>
                );
                break;

            case 'refreshpos':
                return (
                    <div>
                        <Modal.Header>
                            <Modal.Title>Confirmation Required</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            Sync customers and products data? POS will be refreshed, current transaction will be cleared.
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="btn btn-danger" onClick={() => this.emptyCart()}>OK</Button>
                            <Button className="btn btn-default" onClick={() => this.closeModal()}>Cancel</Button>
                        </Modal.Footer>
                    </div>
                );
                break;

            case 'servicefee':
                let indexFee = this.state.indexProp;
                return (
                    <div>
                        <Modal.Header>
                            <Modal.Title>Set Service Fee</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <div className="form-inline" id="reducer" align="center">
                                <div className="form-group">
                                    <div className="refresh">
                                        <h4>RM</h4>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control new-discount-input"
                                        value={this.state.serviceFee}
                                        onChange={this.handleChangeFee} />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                className="btn btn-primary"
                                onClick={() => this.setServiceFee('update', indexFee)}>
                                Done
                            </Button>
                            <Button className="btn btn-default" onClick={() => this.closeModal()}>Cancel</Button>
                        </Modal.Footer>
                    </div>
                );
                break;

            case 'changerate':
                let index = this.state.indexProp;
                let type = this.state.countProp;
                let percentage = this.state.percentagelimit;
                let currency = this.state.currencylimit;

                let limit = '';

                if (type === '%') {
                    limit = percentage;
                }
                else if (type === 'RM') {
                    limit = currency;
                }
                else {
                    limit = percentage;
                }

                return (
                    <div>
                        <Modal.Header>
                            <Modal.Title>Change Discount Rate</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            Change the discount rate. <label>Max is {type}{limit}</label>
                            <div className="form-inline" id="reducer" align="center">
                                <div className="form-group">
                                    <div className="refresh">
                                        <Button
                                            className="btn btn-primary"
                                            onClick={() => this.toggleRateType(type)}
                                        >
                                            <i className="fa fa-refresh"></i>
                                        </Button>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="type">
                                        {type}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control new-discount-input"
                                        value={this.state.newdiscount}
                                        onChange={this.handleChangeNewDiscount} />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                className="btn btn-primary"
                                onClick={() => this.updateDiscountRate(index, type)}>
                                Done
                            </Button>
                            <Button className="btn btn-default" onClick={() => this.closeModal()}>Cancel</Button>
                        </Modal.Footer>
                    </div>
                );
                break;

            case 'reduceitem':
                let count = this.state.countProp;
                return (
                    <div>
                        <Modal.Header>
                            <Modal.Title>Deduct Item Count</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            How much you want to deduct? Total item is <strong>{count}</strong>
                            <div className="form-inline" id="reducer" align="center">
                                <div className="form-group">
                                    <Button className="btn btn-primary" onClick={() => this.updateMinusProp('-')}>&#8722;</Button>
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control reducer-input"
                                        value={this.state.minusProp}
                                        onChange={() => null} />
                                </div>
                                <div className="form-group">
                                    <Button className="btn btn-primary" onClick={() => this.updateMinusProp('+')}>&#43;</Button>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="pull-left">
                                <Button
                                    className="btn btn-warning"
                                    onClick={() => this.deleteItemFromCart(
                                        this.state.indexProp,
                                        this.state.codeProp,
                                        this.state.priceProp,
                                        this.state.countProp,
                                        this.state.focProp
                                    )
                                    }>
                                    Remove All
                                </Button>
                            </div>
                            <div className="pull-right">
                                <Button className="btn btn-danger" onClick={() => this.reduceItemFromCart(
                                    this.state.indexProp,
                                    this.state.codeProp,
                                    this.state.priceProp,
                                    this.state.countProp,
                                    this.state.focProp
                                )
                                }>
                                    OK
                                </Button>
                                <Button className="btn btn-default" onClick={() => this.closeModal()}>Cancel</Button>
                            </div>
                        </Modal.Footer>
                    </div>
                );
                break;

            case 'adjustqty':
                return (
                    <div>
                        <Modal.Header>
                            <Modal.Title>Adjust Quantity</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <div className="form-inline" id="reducer" align="center">
                                <div className="form-group">
                                    <Button
                                        className="btn btn-primary"
                                        onClick={() => this.updateQty('-')}>&#8722;</Button>
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control new-discount-input"
                                        value={this.state.minusProp}
                                        onChange={this.handleChangeNewQty} />
                                </div>
                                <div className="form-group">
                                    <Button
                                        className="btn btn-primary"
                                        onClick={() => this.updateQty('+')}>&#43;</Button>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="pull-left">
                                <Button
                                    className="btn btn-warning"
                                    onClick={() => this.deleteItemFromCart(
                                        this.state.indexProp,
                                        this.state.codeProp,
                                        this.state.priceProp,
                                        this.state.countProp,
                                        this.state.focProp
                                    )
                                    }>
                                    Remove All
                                </Button>
                            </div>
                            <div className="pull-right">
                                <Button className="btn btn-danger" onClick={() => this.updateQtyFromCart(
                                    this.state.indexProp,
                                    this.state.codeProp,
                                    this.state.priceProp,
                                    this.state.countProp,
                                    this.state.focProp
                                )
                                }>
                                    OK
                                </Button>
                                <Button className="btn btn-default" onClick={() => this.closeModal()}>Cancel</Button>
                            </div>
                        </Modal.Footer>
                    </div>
                );
                break;


            case 'spinner':
                return (
                    <div>
                        <Modal.Header>
                            <Modal.Title>Please Wait, Working...</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <div align="center">
                                <i className="fa fa-circle-o-notch fa-spin fa-fw fa-3x app-spinner-color"></i>
                            </div>
                        </Modal.Body>
                    </div>
                );
                break;

            case 'submit':
                return (
                    <div>
                        <Modal.Header>
                            <Modal.Title>Please Wait, Submiting...</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <div align="center">
                                <i className="fa fa-circle-o-notch fa-spin fa-fw fa-3x app-spinner-color"></i>
                            </div>
                        </Modal.Body>
                    </div>
                );
                break;

            case 'registering':
                return (
                    <div>
                        <Modal.Header>
                            <Modal.Title>Please Wait, Registering...</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <div align="center">
                                <i className="fa fa-circle-o-notch fa-spin fa-fw fa-3x app-spinner-color"></i>
                            </div>
                        </Modal.Body>
                    </div>
                );
                break;

            case 'checkmrp':
                return (
                    <div>
                        <Modal.Header>
                            <Modal.Title>Check Discount Based On Member Count</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <label><small>Member Num</small></label>
                            <input
                                name="mrpinput"
                                type="text"
                                className="form-control mpw-input"
                                value={this.state.mrpinput}
                                onChange={this.handleChangeMWInput}
                                style={{ marginBottom: 10 }}
                            />
                            <label>Percentage: {this.state.mrprate}%</label>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button className="btn btn-primary" onClick={() => this.setState({ mrprate: 0 }, function () { this.getMRPrate(this.state.mrpinput) })}>Check</Button>
                            <Button className="btn btn-default" onClick={() => this.closeModal()}>Close</Button>
                        </Modal.Footer>
                    </div>
                );
                break;

            case 'addmlm':
                return (
                    <div>
                        <Modal.Header>
                            <Modal.Title>Don't Close Yet, Setting Up the Member Reference Registration...</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <div align="center">
                                <i className="fa fa-circle-o-notch fa-spin fa-fw fa-3x app-spinner-color"></i>
                            </div>
                        </Modal.Body>
                    </div>
                );
                break;

            case 'mlmstatus':
                return (
                    <div>
                        <Modal.Header>
                            <Modal.Title>Member Reference Registration {this.state.mlm_status}</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            {this.state.mlm_message}
                        </Modal.Body>

                        <Modal.Footer>
                            <Button className="btn btn-default" onClick={() => this.closeModal()}>OK</Button>
                        </Modal.Footer>
                    </div>
                );
                break;

            case 'sync':
                return (
                    <div>
                        <Modal.Header>
                            <Modal.Title>Sync Data</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            {this.syncStatus()}
                        </Modal.Body>

                        <Modal.Footer>
                            <Button className="btn btn-success" onClick={() => this.emptyCart()}>OK</Button>
                            {/*<Button bsStyle="primary">Save changes</Button>*/}
                        </Modal.Footer>
                    </div>
                );
                break;

            case 'syncnoempty':
                return (
                    <div>
                        <Modal.Header>
                            <Modal.Title>Sync Data</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            {this.syncStatus()}
                        </Modal.Body>

                        <Modal.Footer>
                            <Button className="btn btn-success" onClick={() => this.closeModal()}>OK</Button>
                            {/*<Button bsStyle="primary">Save changes</Button>*/}
                        </Modal.Footer>
                    </div>
                );
                break;

            case 'pending':
                return (
                    <div>
                        <Modal.Header>
                            <Modal.Title>Pending Function</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            POS output and layouts needs to be confirmed before this function able to be executed
                        </Modal.Body>

                        <Modal.Footer>
                            <Button className="btn btn-default" onClick={() => this.closeModal()}>OK</Button>
                        </Modal.Footer>
                    </div>
                );
                break;

            case 'error':
                return (
                    <div>
                        <Modal.Header>
                            <Modal.Title>ERROR</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            Unable to complete the requested action. Please check your internet connection.
                        </Modal.Body>

                        <Modal.Footer>
                            <Button className="btn btn-default" onClick={() => this.closeModal()}>OK</Button>
                        </Modal.Footer>
                    </div>
                );
                break;

            case 'unitmaxed':
                return (
                    <div>
                        <Modal.Header>
                            <Modal.Title>Insufficient Stock Balance</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            Item Code <label>{this.state.nameProp}</label> has remaining of <label>{this.state.stockProp} Qty</label> in <label>{this.getOutletName(this.state.inv_warehouse)} Outlet.</label>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button className="btn btn-default" onClick={() => this.closeModal()}>OK</Button>
                        </Modal.Footer>
                    </div>
                );
                break;

            case 'closing':
                return (
                    <div>
                        <Modal.Header>
                            <Modal.Title>
                                Closing &bull;
                                Date: {this.state.inv_posting_date}
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <div id="multipaymentwindow">
                                <div className="row">

                                    <div className="col-md-3">
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <i className="fa fa-money"></i>
                                                <label>cash</label>
                                            </div>
                                            <div className="panel-body">
                                                <label><small>Amount</small></label>
                                                <input
                                                    name="inv_cash"
                                                    type="text"
                                                    className="form-control mpw-input"
                                                    value={this.state.inv_cash.toFixed(2)}
                                                    onChange={this.handleChangeMWInput}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <i className="fa fa-credit-card"></i>
                                                <label>credit card</label>
                                            </div>
                                            <div className="panel-body">
                                                <label><small>Amount</small></label>
                                                <input
                                                    name="inv_creditcard"
                                                    type="text"
                                                    className="form-control mpw-input"
                                                    value={this.state.inv_creditcard.toFixed(2)}
                                                    onChange={this.handleChangeMWInput}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <i className="fa fa-university"></i>
                                                <label>E-Payment</label>
                                            </div>
                                            <div className="panel-body">
                                                <label><small>Amount</small></label>
                                                <input
                                                    name="inv_epayment"
                                                    type="text"
                                                    className="form-control mpw-input"
                                                    value={this.state.inv_epayment.toFixed(2)}
                                                    onChange={this.handleChangeMWInput}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <i className="fa fa-edit"></i>
                                                <label>Cheque</label>
                                            </div>
                                            <div className="panel-body">
                                                <label><small>Amount</small></label>
                                                <input
                                                    name="inv_cheque"
                                                    type="text"
                                                    className="form-control mpw-input"
                                                    value={this.state.inv_cheque.toFixed(2)}
                                                    onChange={this.handleChangeMWInput}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <hr />
                                        <i className="fa fa-calculator"></i>
                                        <label>Cash Calculator</label>
                                        <br />
                                        <br />
                                    </div>

                                    <div className="col-md-6"><strong>COINS</strong><hr /></div>
                                    <div className="col-md-6"><strong>DOLLAR BILLS</strong><hr /></div>

                                    <div className="col-md-3">
                                        <div className="form-horizontal">
                                            <div className="form-group">
                                                <label className="col-md-3 control-label">0.1 &cent;</label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        name="cl_cent_1"
                                                        id="cl_sum_cent_1"
                                                        placeholder="0.01"
                                                        value={this.state.cl_cent_1}
                                                        onChange={this.handleClosingInput}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>{/* 1 cent */}

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">0.5 &cent;</label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        name="cl_cent_5"
                                                        id="cl_sum_cent_5"
                                                        placeholder="0.05"
                                                        value={this.state.cl_cent_5}
                                                        onChange={this.handleClosingInput}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>{/* 5 cent */}

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">10 &cent;</label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        name="cl_cent_10"
                                                        id="cl_sum_cent_10"
                                                        placeholder="0.10"
                                                        value={this.state.cl_cent_10}
                                                        onChange={this.handleClosingInput}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>{/* 10 cent */}

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">20 &cent;</label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        name="cl_cent_20"
                                                        id="cl_sum_cent_20"
                                                        placeholder="0.20"
                                                        value={this.state.cl_cent_20}
                                                        onChange={this.handleClosingInput}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>{/* 20 cent */}

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">50 &cent;</label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        name="cl_cent_50"
                                                        id="cl_sum_cent_50"
                                                        placeholder="0.50"
                                                        value={this.state.cl_cent_50}
                                                        onChange={this.handleClosingInput}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>{/* 50 cent */}

                                            {/*
                                        <div className="form-group">
                                            <label className="col-md-3 control-label">1&#36;</label>
                                            <div className="col-md-9">
                                            <input
                                                type="text"
                                                name="cl_cent_rm1"
                                                id="cl_sum_cent_rm1"
                                                placeholder="1.00"
                                                value={this.state.cl_cent_rm1}
                                                onChange={this.handleClosingInput}
                                                className="form-control"
                                            />
                                            </div>
                                        </div>
                                        */
                                            }

                                        </div>{/* form-horizontal */}
                                    </div>{/* col-md-4 */}

                                    <div className="col-md-3">
                                        <div className="form-horizontal">
                                            <div className="form-group">
                                                <label className="col-md-3 control-label">Total</label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        name="cl_sum_cent_1"
                                                        value={this.state.cl_sum_cent_1}
                                                        onChange={this.handleChangeMWInput}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>{/* 1 cent */}

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">Total</label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        name="cl_sum_cent_5"
                                                        value={this.state.cl_sum_cent_5}
                                                        onChange={this.handleChangeMWInput}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>{/* 5 cent */}

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">Total</label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        name="cl_sum_cent_10"
                                                        value={this.state.cl_sum_cent_10}
                                                        onChange={this.handleChangeMWInput}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>{/* 10 cent */}

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">Total</label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        name="cl_sum_cent_20"
                                                        value={this.state.cl_sum_cent_20}
                                                        onChange={this.handleChangeMWInput}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>{/* 20 cent */}

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">Total</label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        name="cl_sum_cent_50"
                                                        value={this.state.cl_sum_cent_50}
                                                        onChange={this.handleChangeMWInput}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>{/* 50 cent */}

                                            {
                                                /*
                                                <div className="form-group">
                                                    <label className="col-md-3 control-label">Total</label>
                                                    <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        name="cl_sum_cent_rm1"
                                                        value={this.state.cl_sum_cent_rm1}
                                                        onChange={this.handleChangeMWInput}
                                                        className="form-control"
                                                    />
                                                    </div>
                                                </div>
                                                */
                                            }

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">GT</label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        name="cl_total_cent"
                                                        value={this.state.cl_total_cent}
                                                        onChange={this.handleChangeMWInput}
                                                        className="form-control"
                                                        style={{
                                                            fontWeight: 'bold',
                                                            color: 'green'
                                                        }}
                                                    />
                                                </div>
                                            </div>{/* $1 coin */}

                                        </div>{/* form-horizontal */}
                                    </div>{/* col-md-4 */}

                                    {/*** break ***/}


                                    <div className="col-md-3">
                                        <div className="form-horizontal">
                                            <div className="form-group">
                                                <label className="col-md-3 control-label">1&#36;</label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        name="cl_rm_1"
                                                        placeholder="1.00"
                                                        id="cl_sum_rm_1"
                                                        value={this.state.cl_rm_1}
                                                        onChange={this.handleClosingInput}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>{/* 1 cent */}

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">5&#36;</label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        name="cl_rm_5"
                                                        placeholder="5.00"
                                                        id="cl_sum_rm_5"
                                                        value={this.state.cl_rm_5}
                                                        onChange={this.handleClosingInput}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>{/* 5 cent */}

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">10&#36;</label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        name="cl_rm_10"
                                                        placeholder="10.00"
                                                        id="cl_sum_rm_10"
                                                        value={this.state.cl_rm_10}
                                                        onChange={this.handleClosingInput}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>{/* 10 cent */}

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">20&#36;</label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        name="cl_rm_20"
                                                        placeholder="20.00"
                                                        id="cl_sum_rm_20"
                                                        value={this.state.cl_rm_20}
                                                        onChange={this.handleClosingInput}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>{/* 20 cent */}

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">50&#36;</label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        name="cl_rm_50"
                                                        placeholder="50.00"
                                                        id="cl_sum_rm_50"
                                                        value={this.state.cl_rm_50}
                                                        onChange={this.handleClosingInput}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>{/* 50 cent */}

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">100&#36;</label>
                                                <div className="col-md-9">
                                                    <input
                                                        type="text"
                                                        name="cl_rm_100"
                                                        placeholder="100.00"
                                                        id="cl_sum_rm_100"
                                                        value={this.state.cl_rm_100}
                                                        onChange={this.handleClosingInput}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>{/* 10 cent */}

                                        </div>{/* form-horizontal */}
                                    </div>{/* col-md-4 */}

                                    <div className="col-md-3">
                                        <div className="form-horizontal">
                                            <div className="form-group">
                                                <label className="col-md-3 control-label">Total</label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        name="cl_sum_rm_1"
                                                        value={this.state.cl_sum_rm_1}
                                                        onChange={this.handleChangeMWInput}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>{/* 1 cent */}

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">Total</label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        name="cl_sum_rm_5"
                                                        value={this.state.cl_sum_rm_5}
                                                        onChange={this.handleChangeMWInput}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>{/* 5 cent */}

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">Total</label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        name="cl_sum_rm_10"
                                                        value={this.state.cl_sum_rm_10}
                                                        onChange={this.handleChangeMWInput}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>{/* 10 cent */}

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">Total</label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        name="cl_sum_rm_20"
                                                        value={this.state.cl_sum_rm_20}
                                                        onChange={this.handleChangeMWInput}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>{/* 20 cent */}

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">Total</label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        name="cl_sum_rm_50"
                                                        value={this.state.cl_sum_rm_50}
                                                        onChange={this.handleChangeMWInput}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>{/* 50 cent */}

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">Total</label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        name="cl_sum_rm_100"
                                                        value={this.state.cl_sum_rm_100}
                                                        onChange={this.handleChangeMWInput}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>{/* 10 cent */}

                                            <div className="form-group">
                                                <label className="col-md-3 control-label">GT</label>
                                                <div className="col-md-5">
                                                    <input
                                                        type="text"
                                                        name="cl_total_rm"
                                                        value={this.state.cl_total_rm}
                                                        onChange={this.handleChangeMWInput}
                                                        className="form-control"
                                                        style={{
                                                            fontWeight: 'bold',
                                                            color: 'green'
                                                        }}
                                                    />
                                                </div>
                                            </div>{/* 10 cent */}

                                        </div>{/* form-horizontal */}
                                    </div>{/* col-md-4 */}

                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <hr />
                                    <i className="fa fa-balance-scale"></i>
                                    <label>Cash Count VS Cash Sales</label> (After minus returns)
                                    <br />
                                    <br />
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>POS CASH</label>
                                        <input
                                            className="form-control input-lg"
                                            value={this.state.cl_total}
                                            name={null}
                                            onChange={this.handleChangeMWInput}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>RETURN</label>
                                        <input
                                            className="form-control input-lg"
                                            value={(this.state.ret_cash).toFixed(2)}
                                            name={null}
                                            onChange={this.handleChangeMWInput}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>CASH SALES</label>
                                        <input
                                            className="form-control input-lg"
                                            value={(this.state.inv_cash).toFixed(2)}
                                            name={null}
                                            onChange={this.handleChangeMWInput}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <div>
                                <Button
                                    className="btn btn-success btn-log"
                                    onClick={() => this.toggleModal('printclosing')}>
                                    <i className="fa fa-print"></i>
                                    Print
                                </Button>
                                <Button className="btn btn-default" onClick={() => this.closeModal()}>Close</Button>
                            </div>
                        </Modal.Footer>
                    </div>
                );
                break;

            case 'stockreport':

                return (
                    <div>
                        <Modal.Header>
                            <Modal.Title>
                                Stock Report: {this.state.company}
                            </Modal.Title>
                            <div>Date/Time: {this.state.inv_posting_date} {this.state.inv_posting_time}</div>
                        </Modal.Header>

                        <Modal.Body>

                            <div className="col-md-11">
                                <AsyncSelect
                                    ref='asyncProduct'
                                    value={this.state.productSearchValue}
                                    loadOptions={this.loadProductOptionsOnStockReport}
                                    onInputChange={this.handleAsyncSelectProduct}
                                    onChange={this.handleChangeProductOnStockReport}
                                    placeholder={this.state.itemSearchPlaceHolder}
                                    autoFocus={true}
                                />
                            </div>

                            <div className="col-md-1">
                                <Button
                                    className="btn btn-default btn-toggle-nric"
                                    onClick={() => this.toggleItemSearchOption()}
                                >
                                    <i className="fa fa-refresh"></i>
                                </Button>
                            </div>

                            <div>
                                <StockReport
                                    items={JSON.parse(localStorage.getItem('erpnext_item'))}
                                    bin={JSON.parse(localStorage.getItem('erpnext_bin'))}
                                    outlet={this.state.company}
                                    date={this.state.inv_posting_date}
                                    time={this.state.inv_posting_time}
                                    product={this.state.selectedProduct}
                                />
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button className="btn btn-primary" onClick={() => this.resetStock()}>Show All</Button>
                            <Button className="btn btn-success" onClick={() => this.closeModal()}>OK</Button>
                        </Modal.Footer>
                    </div>
                );
                break;

            case 'dailyreport':
                return (
                    <div>
                        <Modal.Header>
                            <Modal.Title>
                                <div>
                                    <div className="pull-left">
                                        Sales Report ({this.state.company} Outlet)
                                    </div>
                                    <div className="pull-right">
                                        <DatePicker
                                            selected={this.state.report_date}
                                            onChange={this.handleChangeReportDate}
                                            className="datepicker-select"
                                            calendarClassName="datepicker-calendar"
                                        />
                                    </div>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <table className="table table-striped">
                                    <thead>
                                        {this.toggleThead()}
                                    </thead>
                                    <tbody>
                                        {this.renderReport()}
                                    </tbody>
                                </table>

                                <div className="clearfix"></div>

                                <table className="table sale-sum-tbl">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <strong>Cash Sales</strong><br />
                                                Cash Returns<br /><br />

                                                <strong>Credit Card Sales</strong><br />
                                                Credit Card Returns<br /><br />

                                                <strong>E-Payment Sales</strong><br />
                                                <strong>Boost Sales</strong><br />
                                                <strong>Quinpay Sales</strong><br />
                                                <strong>Splus Sales</strong><br />
                                                E-Payment Returns (+Boost/Quinpay/Splus)<br /><br />

                                                <strong>Cheque Sales</strong><br /><br />
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                <strong>{(this.state.inv_cash).toFixed(2)}</strong><br />
                                                {this.state.ret_cash.toFixed(2)}<br /><br />

                                                <strong>{(this.state.inv_creditcard).toFixed(2)}</strong><br />
                                                {this.state.ret_creditcard.toFixed(2)}<br /><br />

                                                <strong>{(this.state.inv_epayment).toFixed(2)}</strong><br />
                                                <strong>{this.state.inv_boost.toFixed(2)}</strong><br />
                                                <strong>{this.state.inv_quinpay.toFixed(2)}</strong><br />
                                                <strong>{this.state.inv_splus.toFixed(2)}</strong><br />
                                                {this.state.ret_epayment.toFixed(2)}<br /><br />

                                                <strong>{this.state.inv_cheque.toFixed(2)}</strong><br /><br />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <table className="table sale-sum-tbl">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <label>Total Sales</label><br />
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                <label>{
                                                    (parseFloat(this.state.inv_cash)
                                                        + parseFloat(this.state.inv_creditcard)
                                                        + parseFloat(this.state.inv_boost)
                                                        + parseFloat(this.state.inv_quinpay)
                                                        + parseFloat(this.state.inv_splus)
                                                        + parseFloat(this.state.inv_cheque)
                                                        + parseFloat(this.state.inv_epayment)).toFixed(2)
                                                }</label><br />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <div className="pull-left">
                                <Button
                                    className="btn btn-primary"
                                    onClick={() => this.toggleReportType()}
                                >
                                    <i className="fa fa-exchange"></i>
                                    <span style={{ marginLeft: 5 }}>Report By {this.state.report_type_text}</span>
                                </Button>
                                <Button
                                    className="btn btn-success"
                                    onClick={() => this.toggleModal('printreport')}
                                >
                                    <i className="fa fa-print"></i> Print
                                </Button>
                            </div>
                            <div className="pull-right">
                                <Button
                                    className="btn btn-primary"
                                    onClick={() => this.reloadReport()}
                                >
                                    <i className="fa fa-refresh"></i>
                                    <span style={{ marginLeft: 5 }}>Refresh</span>
                                </Button>
                                <Button className="btn btn-default" onClick={() => this.closeModal()}>Close</Button>
                            </div>
                        </Modal.Footer>
                    </div>
                );
                break;

            case 'purchasehistory':
                return (
                    <div>
                        <Modal.Header>
                            <Modal.Title>
                                <div>
                                    <div className="pull-left">
                                        Customer Purchase History: {this.state.customerID}
                                    </div>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Invoice</th>
                                            <th>Payment Type</th>
                                            <th>Date</th>
                                            <th>Time</th>
                                            <th style={{ textAlign: 'right' }}>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.renderReport(true)}
                                    </tbody>
                                </table>
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <div className="pull-left">
                            </div>
                            <div className="pull-right">
                                <Button className="btn btn-default" onClick={() => this.closeModal()}>Close</Button>
                            </div>
                        </Modal.Footer>
                    </div>
                );
                break;

            case 'register':
                //let no = JSON.parse(localStorage.getItem('erpnext_customer')).length;
                //console.log(no);
                const { selectedOutlet } = this.state;
                const outlets = [
                    { value: 'Bundusan', label: 'Bundusan' },
                    { value: 'Luyang', label: 'Luyang' },
                    { value: 'Luyang 2', label: 'Likas' },
                    { value: 'Keningau', label: 'Keningau' },
                    { value: 'Kudat', label: 'Kudat' },
                    { value: 'Tawau', label: 'Tawau' },
                ];

                return (
                    <div>
                        <Modal.Header>
                            <Modal.Title>Register A New Member</Modal.Title>
                            <div>Classic &bull; 0 Points</div>
                        </Modal.Header>

                        <Modal.Body>
                            {this.renderRegFalse()}
                            {this.renderRegDuplicate()}
                            <div className="form-horizontal">
                                <div className="form-group">
                                    <label className="col-sm-3 control-label">Full Name</label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            name="reg_name"
                                            value={this.state.reg_name}
                                            className="form-control"
                                            placeholder="Member Name"
                                            onChange={this.handleChangeMWInput}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-sm-3 control-label">Mobile Number</label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            name="reg_mobile"
                                            value={this.state.reg_mobile}
                                            className="form-control"
                                            placeholder="Mobile Number"
                                            onChange={this.handleChangeMWInput}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-sm-3 control-label">NRIC</label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            name="reg_nric"
                                            value={this.state.reg_nric}
                                            className="form-control"
                                            placeholder="NRIC Number"
                                            onChange={this.handleChangeMWInput}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-sm-3 control-label">Outlet</label>
                                    <div className="col-sm-9">
                                        <Select
                                            name="selectOutlet"
                                            value={selectedOutlet}
                                            onChange={this.handleChangeOutlet}
                                            options={outlets}
                                            placeholder="Select Outlet"
                                        />
                                    </div>
                                </div>
                                {this.renderRefForm()}
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button className="btn btn-success" onClick={() => this.registerMember()}>Register Now</Button>
                            <Button className="btn btn-default" onClick={() => this.closeModal()}>Cancel</Button>
                        </Modal.Footer>
                    </div>
                );
                break;

            case 'editmember':
                const tier = [
                    { value: 'Classic', label: 'Classic' },
                    { value: 'Gold', label: 'Gold' }
                ];

                return (
                    <div>
                        <Modal.Header>
                            <Modal.Title>Edit Member</Modal.Title>
                            <div>{this.state.customer} ({this.state.membership})</div>
                        </Modal.Header>

                        <Modal.Body>
                            <div className="form-horizontal">
                                <div className="form-group">
                                    <label className="col-sm-3 control-label">Full Name</label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            name="new_name"
                                            value={this.state.new_name}
                                            className="form-control"
                                            placeholder="Member Name"
                                            onChange={this.handleChangeMWInput}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-sm-3 control-label">Mobile Number</label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            name="new_mobile"
                                            value={this.state.new_mobile}
                                            className="form-control"
                                            placeholder="Mobile Number (No Dash)"
                                            onChange={this.handleChangeMWInput}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-sm-3 control-label">NRIC</label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            name="new_nric"
                                            value={this.state.new_nric}
                                            className="form-control"
                                            placeholder="NRIC Number (No Dash)"
                                            onChange={this.handleChangeMWInput}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-sm-3 control-label">Membership</label>
                                    <div className="col-sm-9">
                                        <Select
                                            name="selectedNewTier"
                                            value={this.state.selectedNewTier}
                                            onChange={this.handleChangeTier}
                                            options={tier}
                                            placeholder="Change Membership"
                                        />
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button className="btn btn-success" onClick={() => this.editMember('update')}>Update Now</Button>
                            <Button className="btn btn-default" onClick={() => this.closeModal()}>Cancel</Button>
                        </Modal.Footer>
                    </div>
                );
                break;

            default:
                return (
                    null
                );
        }
    }

    //--------------------------------------------------------------------------

    renderMRPBtn() {
        if (appMode === 'develop') {
            return (
                <React.Fragment>
                    <Button
                        className="app-btn-w btn-sm btn-info"
                        onClick={() => this.toggleModal('checkmrp')}>
                        MRP Tier
                    </Button>
                </React.Fragment>
            );
        }
    }

    renderTopNavRightItems() {
        if (this.state.isLoggedIn === true) {
            return (
                <div>
                    {this.renderMRPBtn()}
                    <a className="btn btn-success btn-sm app-btn-w" href={fPosNew()} target="_blank" rel="noopener noreferrer">New <i className="fa fa-user"></i></a>
                    { /*<a className="btn btn-success btn-sm app-btn-w" href="http://localhost:3000" target="_blank" rel="noopener noreferrer">New <i className="fa fa-user"></i></a>*/}
                    <Button className="app-btn-w btn-sm btn-primary" onClick={() => this.toggleModal('register')}>Register</Button>
                    <Button className="app-btn-w btn-sm" onClick={() => this.prepClosing()}>Closing</Button>
                    <Button className="app-btn-w btn-sm" onClick={() => this.prepReport()}>Report</Button>
                    <Button className="app-btn-w btn-sm" onClick={() => this.prepStock()}>Stocks</Button>
                    <Button className="app-btn-w btn-sm" onClick={() => this.syncData()}>Sync</Button>
                    <Button className="btn-danger app-btn-w btn-sm" onClick={() => this.logoutERPNext()}>Logout</Button>
                </div>
            )
        }
    }

    renderPosUser() {
        let date = moment
        if (this.state.firstname !== '') {
            return (
                <a href="/" className="navbar-brand">
                    {this.state.firstname} ({this.state.company === 'Luyang 2' ? 'Likas' : this.state.company} Outlet)
                    <span style={{ marginLeft: 5, marginRight: 5 }}>&bull;</span> {moment().format('DD MMMM YYYY')}
                </a>
            )
        }
        else {
            return (
                <a href="/" className="navbar-brand">
                    Paragon Pets Cloud POS System
                </a>
            )
        }
    }

    renderTopNav() {
        return (
            <nav className="navbar navbar-default app-pos-navbar">
                <div className="navbar-header">
                    {this.renderPosUser()}
                </div>
                <div className="navbar-form navbar-right">
                    {this.renderTopNavRightItems()}
                </div>
            </nav>
        )
    }

    //--------------------------------------------------------------------------

    getAvatar() {
        //console.log('@getAvatar exec');
        //console.log('profile image');
        //console.log(this.state.profileimage);

        return "/images/avatar-blank.png";

        /*
        if(this.state.customer === 'Walk In') {
          return "/images/avatar-blank.png";
        }
        else if(this.state.profileimage === '' || this.state.profileimage === null){
          return "/images/avatar-blank.png";
        }
        else {
          return fAppAPI() + this.state.profileimage;
        }
        */
    }

    getRefAvatar() {
        /*
        if(this.state.ref_profileimage === '' || this.state.ref_profileimage === null){
          return "/images/avatar-blank.png";
        }
        else {
          return fAppAPI() + this.state.ref_profileimage;
        }
        */
        return "/images/avatar-blank.png";
    }

    //--------------------------------------------------------------------------

    getCartSum() {
        let cart = this.state.cart;
        //console.log('cart entries');
        //console.log(cart);
        //let tax = this.state.taxrate;
        let discountedprice = 0;

        if (cart.length > 0) {
            let total = [];

            return cart.map((cart, index) => {
                let count = this.getCartItemCount(cart.code, cart.foc);
                let discountrate = this.getCartItemDiscount(cart.code, cart.foc);
                let discounttype = this.getCartItemDiscountType(cart.code, cart.foc);

                //console.log('item count: ' + count);
                //console.log('discount rate: ' + discountrate);
                //console.log('discount type: ' + discounttype);

                if (discounttype === '%') {
                    discountedprice = (cart.price - ((cart.price * discountrate) / 100)).toFixed(2);
                }
                else if (discounttype === 'RM') {
                    discountedprice = (cart.price - discountrate).toFixed(2);
                }

                let discCount = discountedprice * count
                console.log('value before roundToTwoDec', discCount)

                let preprice = this.roundToTwoDec(discountedprice * count);
                preprice = preprice.toString();

                let spp = preprice.split('.');

                // considering 39.60
                // head 39
                // body 6
                // tail
                let spphead = spp[0];
                let sppbody = spp[1].charAt(0);
                let spptail = spp[1].charAt(1);

                if (spptail !== 0 || spptail !== 5) {
                    if (spptail < 5) {
                        spptail = 0;
                    }
                    else if (spptail > 5) {
                        spptail = 0;
                        sppbody = parseInt(sppbody, 10) + 1;
                        if (sppbody === 10) {
                            spphead = parseInt(spphead, 10) + 1;
                            sppbody = 0;
                        }
                    }
                }

                let finalprice = spphead + '.' + sppbody + spptail
                console.log('final price before fixed2', finalprice)

                let prenewrate = Number(finalprice / count).toFixed(2)
                console.log('final price per item after fixed2', prenewrate)

                let newfinalprice = Number(prenewrate * count).toFixed(2)
                console.log('final price for overall item', newfinalprice)

                /*
                // discovered bug
prenewrate = prenewrate.toString();
let spr = prenewrate.split('.');
let sprhead = spr[0];
let sprbody = spr[1].charAt(0);
let sprtail = spr[1].charAt(1);

if(sprtail !== 0 || sprtail !== 5) {
    if(sprtail < 5) {
        sprtail = 0;
    }
    else if(sprtail > 5) {
        sprtail = 0;
        sprbody = parseInt(sprbody, 10) + 1;
        if(sprbody === 10) {
            sprhead = parseInt(sprhead, 10) + 1;
            sprbody = 0;
        }
    }
}
let newrate = sprhead + '.' + sprbody + sprtail
let newfinalprice = Number(newrate*count)
//let newfinalprice = Number(newrate*count).toFixed(2)
                */

                this.updateItemForSubmit('discount', cart.code, discountrate, null, cart.foc)
                this.updateItemForSubmit('amount', cart.code, newfinalprice, null, cart.foc)

                if (cart.foc === false) {
                    this.updateItemForSubmit('rate', cart.code, prenewrate, null, cart.foc)
                }

                total.push(Number(newfinalprice));

                let add = (a, b) => a + b;
                let gt = total.reduce(add);
                //let tx = (gt/100) * tax;
                //let preformated = parseFloat(gt) + parseFloat(tx)
                //let formated = Number(preformated).toFixed(2);
                let formated = Number(gt).toFixed(2);

                this.setState({ sum: formated }, function () {
                    console.log('sum updated');
                    return formated;
                })
            })
        }
        else {
            this.setState({ sum: 0.00 })
        }
    }

    getBasePrice(wh, item) {
        switch (wh) {
            case 'Outlet Bundusan - PPSB':
                if (item.outlet_bundusan !== 0.00) {
                    return item.outlet_bundusan;
                }
                else {
                    return item.standard_rate
                }

            case 'Outlet Luyang - PPSB':
                if (item.outlet_luyang !== 0.00) {
                    return item.outlet_luyang;
                }
                else {
                    return item.standard_rate
                }

            case 'Outlet Keningau - PK9F':
                if (item.outlet_keningau !== 0.00) {
                    return item.outlet_keningau;
                }
                else {
                    return item.standard_rate
                }

            case 'Outlet Kudat - PK9F':
                if (item.outlet_kudat !== 0.00) {
                    return item.outlet_kudat;
                }
                else {
                    return item.standard_rate
                }

            case 'Outlet Tawau - PK9F':
                if (item.outlet_tawau !== 0.00) {
                    return item.outlet_tawau;
                }
                else {
                    return item.standard_rate
                }

            default:
                return item.standard_rate;
        }
    }
    /*
    getPromoSetting(wh, item) {
        let promo = [];
        switch(wh) {
            case 'Outlet Bundusan - PPSB':
                promo = {
                    "start": item.promo_start_bundusan,
                    "end": item.promo_end_bundusan,
                    "rate": item.promo_rate_bundusan,
                    "type": item.rate_bundusan_is_currency,
                    "point": item.bundusan_no_point,
                                        "apply": item.apply_member_rate_for_bundusan
                }
                return promo;

            case 'Outlet Luyang - PPSB':
                promo = {
                    "start": item.promo_start_luyang,
                    "end": item.promo_end_luyang,
                    "rate": item.promo_rate_luyang,
                    "type": item.rate_luyang_is_currency,
                    "point": item.luyang_no_point,
                                        "apply": item.apply_member_rate_for_luyang
                }
                return promo;

            case 'Outlet Keningau - PK9F':
                promo = {
                    "start": item.promo_start_keningau,
                    "end": item.promo_end_keningau,
                    "rate": item.promo_rate_keningau,
                    "type": item.rate_keningau_is_currency,
                    "point": item.keningau_no_point,
                                        "apply": item.apply_member_rate_for_keningau
                }
                return promo;

            case 'Outlet Kudat - PK9F':
                promo = {
                    "start": item.promo_start_kudat,
                    "end": item.promo_end_kudat,
                    "rate": item.promo_rate_kudat,
                    "type": item.rate_kudat_is_currency,
                    "point": item.kudat_no_point,
                                        "apply": item.apply_member_rate_for_kudat
                }
                return promo;

            case 'Outlet Tawau - PK9F':
                promo = {
                    "start": item.promo_start_tawau,
                    "end": item.promo_end_tawau,
                    "rate": item.promo_rate_tawau,
                    "type": item.rate_tawau_is_currency,
                    "point": item.tawau_no_point,
                                        "apply": item.apply_member_rate_for_tawau
                }
                return promo;

            default:
                promo = {
                    "start": null,
                    "end": null,
                    "rate": 0,
                    "type": 0,
                    "point": 0,
                                        "apply": 1
                }
                return promo;
        }
    }
    */

    getItemPrice(code, index, mapping) {
        let today = this.state.inv_posting_date;
        //let items = this.state.products;
        let items = JSON.parse(localStorage.getItem('erpnext_item'));
        let wh = this.state.inv_warehouse;
        let item = items[index];
        //console.log(index);

        //let promo_start = '';
        //let promo_end = '';
        //let promo_rate = ''; // float
        //let promo_type = ''; // % or currency, 0 or 1
        //let promo_point = ''; // 1 or 0, true or false
        let promo_price = 0.00; // we need to calc this
        let promo_valid = false;
        //let promo_disc = 0;
        let promo_setting = [];

        let base_price = this.getBasePrice(wh, item);
        let promo = fGetPromoSetting(wh, item);

        if (promo.rate !== 0.00 && promo.rate !== null) {
            if (promo.start <= today && promo.end >= today) {
                promo_valid = true;

                // lets start calc!
                // good luck!
                // @NOTE setting the promo_price value
                if (promo.type === 0) { // this is %
                    promo_price = (base_price - (base_price * promo.rate / 100)).toFixed(2);
                }
                else if (promo.type === 1) {
                    promo_price = (base_price - promo.rate).toFixed(2);
                }
            }
            else {
                promo_price = base_price;
            }
        }
        else {
            promo_price = base_price;
        }

        promo_setting = {
            "valid": promo_valid,
            "start": promo.start,
            "end": promo.end,
            "rate": promo.rate,
            "type": promo.type,
            "point": promo.point,
            "apply": promo.apply,
            "price": Number(promo_price),
            "base": Number(base_price)
        }

        if (mapping === false) {
            if (promo.point === 1 && promo_valid === true) {
                let ptstominus = this.state.ptstominus;
                let pts = Math.round(Number(promo_price));
                this.setState({
                    ptstominus: ptstominus + pts
                })
            }
        }
        return promo_setting;
    }

    updateCartForFOC(code, name, isModifyQty, theNewQty) {
        console.log('adding foc item to cart...');
        console.log('reset the isFOC first!');
        this.resetFOCprop()

        let cart = this.state.cart;
        let item = this.state.item;
        let unit = this.state.unit;
        let units = this.state.units;
        let discount = this.state.discount;

        let items = JSON.parse(localStorage.getItem('erpnext_item'));

        // foc items config
        let price = 0.00;
        let base = 0.00;
        let disrate = 100;
        let distype = 0;
        let promotion = [];
        let foc = true;

        if (cart.length === 0) {
            let newCartEntry = [...cart, { code: code, name: name, price: price, base: base, foc: foc }]
            let newItemEntry = [...item, { zeropoint: 0, item_code: code, qty: 1, rate: price, amount: price, discount_percentage: 100, discount_on_price: 0, foc: foc }]
            let newUnitEntry = [...unit, { code: code, value: 1, foc: foc }]
            let newDisEntry = [...discount, { code: code, value: disrate, type: distype, foc: foc }]

            this.setState({
                cart: newCartEntry,
                item: newItemEntry,
                unit: newUnitEntry,
                discount: newDisEntry,
                units: units + 1
            }, function () {
                console.log('new cart entry made')
                console.log(this.state.cart)
                console.log('new unit entry made')
                console.log(this.state.unit)
                console.log('new discount entry made')
                console.log(this.state.discount)
                console.log('units/total item in cart updated')
                console.log(this.state.units)
                console.log('all states updated, getting cart sum')
                this.getCartSum()
            });
        }
        else {
            let cartIndex = cart.findIndex(c => c.code === code && c.foc === true);
            console.log('cart has more than one item')
            if (cartIndex === -1) {
                console.log('new item added has no entry, making a new one')

                let newCartEntry = [...cart, { code: code, name: name, price: price, base: base, foc: foc }]
                let newItemEntry = [...item, { zeropoint: 0, item_code: code, qty: 1, rate: price, amount: price, discount_percentage: 100, discount_on_price: 0, foc: foc }]
                let newUnitEntry = [...unit, { code: code, value: 1, foc: foc }]
                let newDisEntry = [...discount, { code: code, value: disrate, type: distype, foc: foc }]
                this.setState({
                    cart: newCartEntry,
                    item: newItemEntry,
                    unit: newUnitEntry,
                    discount: newDisEntry,
                    units: units + 1
                }, function () {
                    this.getCartSum()
                });
            }
            else {
                if (isModifyQty === true) {
                    //let unitIndex = unit.findIndex( u => u.code === cart[cartIndex].code );
                    let unitIndex = unit.findIndex(
                        u => u.code === cart[cartIndex].code && u.foc === true
                    );
                    let curValue = unit[unitIndex].value;
                    let newValue = theNewQty;
                    let newAmount = (price * newValue).toFixed(2)

                    unit[unitIndex] = { ...unit[unitIndex], value: newValue }

                    let curUnits = this.state.units;
                    let unitsToAdd = Number(curUnits) - Number(curValue) + newValue;

                    this.setState({ unit }, function () {
                        this.updateItemForSubmit('qty', unit[unitIndex].code, newValue, null, foc)
                        this.updateItemForSubmit('amount', unit[unitIndex].code, newAmount, null, foc)
                        this.setState({ units: unitsToAdd }, function () { this.getCartSum() });
                    });
                }
                else {
                    console.log('item exists, updating the unit')
                    let unitIndex = unit.findIndex(
                        u => u.code === cart[cartIndex].code && u.foc === true
                    );
                    let curValue = unit[unitIndex].value;
                    let newValue = curValue + 1;

                    //console.log('new unit in cart: ' + newValue);
                    //console.log('max qty: ' + this.state.stockProp);
                    //console.log('the price is: ' + price);
                    let newAmount = (price * newValue).toFixed(2)

                    unit[unitIndex] = { ...unit[unitIndex], value: newValue }

                    this.setState({ unit }, function () {
                        this.updateItemForSubmit('qty', unit[unitIndex].code, newValue, null, false)
                        this.updateItemForSubmit('amount', unit[unitIndex].code, newAmount, null, false)
                        this.setState({ units: units + 1 }, function () { this.getCartSum() });
                    });
                }
            }
        }
    }

    updateCart(code, name, isModifyQty, theNewQty) {
        console.log('updating non foc item...')
        //console.log(isModifyQty)
        this.getStockBalance(code);
        let cart = this.state.cart;
        let item = this.state.item;
        let unit = this.state.unit;
        let units = this.state.units;
        let foc = false // this is predetermined!
        //let items       = this.state.products;
        let items = JSON.parse(localStorage.getItem('erpnext_item'));
        let discount = this.state.discount;
        let price = '';
        let base = '';
        let disrate = 0;
        let distype = 0;
        let promotion = [];
        let zeropoint = 0;
        /**
        * @TODO get the price and discount rate
        * name is the ID or the code for the product
        * NOT the item_name
        **/
        let i = items.findIndex(p => p.name === code);
        zeropoint = items[i].zero_point;
        promotion = this.getItemPrice(code, i, false);

        //console.log('@updateCart exec');
        //console.log('balance stock: ' + balance);
        //console.log('promotion settings');
        //console.log(promotion);

        price = promotion.base;
        base = promotion.base;

        if (promotion.valid === true) {
            disrate = promotion.rate;
            distype = promotion.type;
        }
        else {
            if (promotion.apply === 1) {
                switch (this.state.membership) {
                    case 'Classic':
                        disrate = items[i].classic_discount;
                        distype = items[i].classic_discount_is_currency;
                        break;

                    case 'Gold':
                        disrate = items[i].gold_discount;
                        distype = items[i].gold_discount_is_currency;
                        break;

                    case 'Platinum':
                        disrate = items[i].platinum_discount;
                        distype = items[i].platinum_discount_is_currency;
                        break;

                    default: disrate = 0; distype = 0; break;
                }
            }
            else {
                disrate = 0; distype = 0;
            }
        }

        //console.log('var price: ' + price);
        //console.log('var base: ' + base);
        //console.log('disrate: ' + disrate);
        //console.log('distype: ' + distype);
        //console.log('membership: ' + this.state.membership);

        if (cart.length === 0) {
            let newCartEntry = [...cart, { code: code, name: name, price: price, base: base, foc: foc }]
            let newItemEntry = [...item, { zeropoint: zeropoint, item_code: code, qty: 1, rate: price, amount: price, discount_percentage: 0, discount_on_price: 0, foc: foc }]
            let newUnitEntry = [...unit, { code: code, value: 1, foc: foc }]
            let newDisEntry = [...discount, { code: code, value: disrate, type: distype, foc: foc }]

            this.setState({
                cart: newCartEntry,
                item: newItemEntry,
                unit: newUnitEntry,
                discount: newDisEntry,
                units: units + 1
            }, function () {
                //console.log('new cart entry made')
                //console.log(this.state.cart)
                //console.log('new unit entry made')
                //console.log(this.state.unit)
                //console.log('new discount entry made')
                //console.log(this.state.discount)
                //console.log('units/total item in cart updated')
                //console.log(this.state.units)
                //console.log('all states updated, getting cart sum')
                this.getCartSum()
            });
        }
        else {
            let cartIndex = cart.findIndex(c => c.code === code && c.foc === false);
            //console.log('cart has more than one item')
            if (cartIndex === -1) {
                console.log('new item added has no entry, making a new one')

                let newCartEntry = [{ code: code, name: name, price: price, base: base, foc: foc }, ...cart];
                let newItemEntry = [{ zeropoint: zeropoint, item_code: code, qty: 1, rate: price, amount: price, discount_percentage: 0, discount_on_price: 0, foc: foc }, ...item]
                let newUnitEntry = [{ code: code, value: 1, foc: foc }, ...unit ]
                let newDisEntry = [{ code: code, value: disrate, type: distype, foc: foc }, ...discount]
                this.setState({
                    cart: newCartEntry,
                    item: newItemEntry,
                    unit: newUnitEntry,
                    discount: newDisEntry,
                    units: units + 1
                }, function () {
                    this.getCartSum()
                });
            }
            else {
                if (isModifyQty === true) {
                    //let unitIndex = unit.findIndex( u => u.code === cart[cartIndex].code );
                    let unitIndex = unit.findIndex(
                        u => u.code === cart[cartIndex].code && u.foc === false
                    );
                    let curValue = unit[unitIndex].value;
                    let newValue = theNewQty;
                    let newAmount = (price * newValue).toFixed(2)

                    unit[unitIndex] = { ...unit[unitIndex], value: newValue }

                    let curUnits = this.state.units;
                    let unitsToAdd = Number(curUnits) - Number(curValue) + newValue;

                    this.setState({ unit }, function () {
                        this.updateItemForSubmit('qty', unit[unitIndex].code, newValue, null, foc)
                        this.updateItemForSubmit('amount', unit[unitIndex].code, newAmount, null, foc)
                        this.setState({ units: unitsToAdd }, function () { this.getCartSum() });
                    });
                }
                else {
                    console.log('item exists, updating the unit')
                    let unitIndex = unit.findIndex(
                        u => u.code === cart[cartIndex].code && u.foc === false
                    );
                    let curValue = unit[unitIndex].value;
                    let newValue = curValue + 1;

                    //console.log('new unit in cart: ' + newValue);
                    //console.log('max qty: ' + this.state.stockProp);
                    //console.log('the price is: ' + price);
                    let newAmount = (price * newValue).toFixed(2)

                    unit[unitIndex] = { ...unit[unitIndex], value: newValue }

                    this.setState({ unit }, function () {
                        this.updateItemForSubmit('qty', unit[unitIndex].code, newValue, null, false)
                        this.updateItemForSubmit('amount', unit[unitIndex].code, newAmount, null, false)
                        this.setState({ units: units + 1 }, function () { this.getCartSum() });

                    });
                }
            }
        }
    }

    getPtsToMinus() {
        //console.log('exec @updatePtsToMinus')
        let item = this.state.item;
        let pt = 0;
        for (let i = 0; i < item.length; i++) {
            if (item[i].foc === false && item[i].zeropoint === 1) {
                let pts = Math.round(Number(item[i].amount));
                pt = pt + pts;
                //console.log('total pts to minus: '+pt);
                return pt;
            }
            else {
                //console.log(item[i].item_code + ' has zeropoint set to: ' + item[i].zeropoint);
                return 0;
            }
        }
    }

    handleChangeOutlet = (selectedOutlet) => {
        if (selectedOutlet) {
            let outlet = `${selectedOutlet.value}`;
            this.setState({
                selectedOutlet
            }, function () {
                this.setState({
                    reg_outlet: outlet
                })
            });
        }
    }

    handleChangeCCType = (selectedCCType) => {
        if (selectedCCType) {
            let type = `${selectedCCType.value}`;
            this.setState({
                selectedCCType
            }, function () {
                this.setState({
                    cctype: type
                })
            })
        }
    }

    handleEPType = (selectedept) => {
        if (selectedept) {
            let type = `${selectedept.value}`;
            this.setState({
                selectedept
            }, function () {
                this.setState({
                    epaymenttype: type
                })
            })
        }
    }

    handleChangeTier = (selectedNewTier) => {
        if (selectedNewTier) {
            let tier = `${selectedNewTier.value}`;
            this.setState({
                selectedNewTier
            }, function () {
                this.setState({
                    newTier: tier
                }, function () {
                    console.log('new tier set');
                    console.log(this.state.newTier);
                })
            })
        }
    }

    handleChangeProduct = (selectedProduct) => {
        //console.log(this.state.productSearchValue);
        this.setState({ selectedProduct });
        if (selectedProduct) {
            let code = `${selectedProduct.value}`;
            let name = `${selectedProduct.label}`;
            //let name    = `${selectedProduct.label}`;

            this.setState({
                productSearchValue: '',
            })
            if (this.state.itemsearchopt === 'barcode' || this.state.itemsearchopt === 'typebarcode' || this.state.itemsearchopt === 'code') {
                let items = JSON.parse(localStorage.getItem('erpnext_item'));
                let itemIndex = items.findIndex(p => p.name === code);
                if (itemIndex !== -1) {
                    name = items[itemIndex].item_name
                }
            }
            if (this.state.isFOC === false) {
                console.log('exec updateCart')
                this.updateCart(code, name);
            }
            else {
                console.log('exec updateCartForFOC')
                this.updateCartForFOC(code, name);
            }
        }
    }

    handleChangeProductOnStockReport = (selectedProduct) => {
        //console.log(this.state.productSearchValue);
        this.setState({ selectedProduct });
        if (selectedProduct) {
            this.reloadStock()
        }
    }

    handleChangeSalesPerson = (selectedSalesPerson) => {
        if (selectedSalesPerson) {
            let salesperson = `${selectedSalesPerson.value}`;
            let salesperson_name = `${selectedSalesPerson.label}`;
            this.setState({
                selectedSalesPerson
            }, function () {
                this.setState({
                    salesperson: salesperson,
                    salesperson_name: salesperson_name
                })
            })
        }
    }

    //--------------------------------------------------------------------------

    handleChangeCustomer = (selectedCustomer) => {
        this.setState({
            selectedCustomer,
            inputValue: '',
        });
        //let customers = this.state.customers;
        let customers = JSON.parse(localStorage.getItem('erpnext_customer'));
        if (selectedCustomer) {
            let id = `${selectedCustomer.value}`;
            if (id !== this.state.customer) {
                for (let i = 0; i < customers.length; i++) {
                    if (customers[i].name === id) {
                        let level = customers[i].customer_group;
                        let points = customers[i].reward_points;

                        /*
                        // Sharon advice not to auto set
                        // 4th Jan 2019
                                      else if(level === 'Gold' && points === 0) {
                                          points = 500;
                                      }
                                      else if(level === 'Platinum' && points === 0) {
                                          points = 15000;
                                      }
                        */

                        this.setState({
                            customer: customers[i].customer_name,
                            customerID: customers[i].name,
                            membership: customers[i].customer_group,
                            point: points,
                            nric: customers[i].nric,
                            phone: customers[i].mobile_number,
                            profileimage: customers[i].image
                        }, function () {
                            //console.log('@handleChangeCustomer exec');
                            //console.log('customerID: ' + this.state.customerID);
                            //console.log('customer: ' + this.state.customer);
                            //console.log('membership: ' + this.state.membership);
                            // before do sum,
                            // update the discount state
                            this.updateCartItemDiscount();
                            this.getCartSum();
                            this.checkBdayRecord();
                            //console.log('customer ID is: ' + this.state.customerID);
                        })
                    }
                }
            }
        }
    }

    handleChangeCustomerOnRegister = (selectedCustomer) => {
        //console.log(this.state.productSearchValue);
        this.setState({
            customerRefLabel: `${selectedCustomer.label}`,
            customerRefValue: `${selectedCustomer.value}`
        }, function () {
            //console.log('customerRefLabel: ' + this.state.customerRefLabel);
            //console.log('customerRefValue: ' + this.state.customerRefValue);
            //console.log('displaying the member...');

            let customers = JSON.parse(localStorage.getItem('erpnext_customer'));
            let id = `${selectedCustomer.value}`;
            if (id !== this.state.customer) {
                for (let i = 0; i < customers.length; i++) {
                    if (customers[i].name === id) {
                        let level = customers[i].customer_group;
                        let points = customers[i].reward_points;

                        this.setState({
                            ref_customer: customers[i].customer_name,
                            ref_membership: customers[i].customer_group,
                            ref_points: points,
                            ref_nric: customers[i].nric,
                            ref_phone: customers[i].mobile_number,
                            ref_profileimage: customers[i].image,
                            ref_display: true
                        })
                    }
                }
            }
        })
    }

    renderMembershipWarning() {
        if (this.state.ref_membership === 'Standard') {
            return (
                <div style={{ marginTop: 20 }}>
                    <div className="alert alert-warning">
                        Only Classic / Gold / Platinum members are
                        allowed to have referrence. Referrence will
                        not be registered.
                    </div>
                </div>
            )
        }
    }

    renderRefMember() {
        if (this.state.ref_display === true) {
            return (
                <React.Fragment>
                    <div className="form-group">
                        <label className="col-sm-3 control-label"></label>
                        <div className="col-sm-9">
                            <label className="control-label" style={{ color: 'gray' }}>Member Info</label>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="col-sm-3 control-label"></label>
                        <div className="col-sm-9" id="mlm-ref">
                            <div className="avatar col-sm-3">
                                <img className="img-circle" src={this.getRefAvatar()} alt="avatar" />
                            </div>
                            <div className="membership col-sm-9">
                                <div>
                                    <strong>{this.state.ref_customer}</strong>
                                </div>
                                <div>NRIC: {this.state.ref_nric}</div>
                                <div>Mobile: {this.state.ref_phone}</div>
                                <div style={{ marginTop: 5 }}>
                                    <i className="fa fa-certificate"></i> {this.state.ref_membership}
                                    <span className="app-spliter"></span>
                                    <i className="fa fa-trophy"></i> {this.state.ref_points}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="col-sm-3 control-label"></label>
                        <div className="col-sm-9">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => this.clearRefMember()}
                            >
                                Clear
                            </button>
                        </div>
                    </div>

                </React.Fragment>
            );
        }
    }

    updateDiscountRate(index, type) {
        console.log('exec updateDiscountRate')

        let distype = '';
        let discount = this.state.discount;

        if (type === '%') {
            distype = 0;
        }
        else if (type === 'RM') {
            distype = 1;
        }

        discount[index] = { ...discount[index], value: this.state.newdiscount, type: distype }

        this.setState({ discount }, function () {
            this.updateItemForSubmit('discount', discount[index].code, this.state.newdiscount, type)
            this.getCartSum()
            this.closeModal()
            console.log(discount);
        })
    }

    toggleRateType(type) {
        if (type === '%') {
            this.setState({
                countProp: 'RM',
                newdiscount: 0,
            }, function () {
                console.log('count prop is now: ' + this.state.countProp);
            })
        }
        else if (type === 'RM') {
            this.setState({
                countProp: '%',
                newdiscount: 0
            }, function () {
                console.log('count prop is now: ' + this.state.countProp);
            })
        }
    }

    updateItemForSubmit(action, code, param, type, foc) {
        let item = this.state.item;
        //console.log('@updateItemForSubmit')
        //console.log(item)
        let itemIndex = item.findIndex(i => i.item_code === code && i.foc === foc);
        switch (action) {
            case 'discount':
                if (type === '%') {
                    item[itemIndex] = { ...item[itemIndex], discount_percentage: param, discount_on_price: 0 }
                    this.setState({ item })
                }
                else if (type === 'RM') {
                    item[itemIndex] = { ...item[itemIndex], discount_percentage: 0, discount_on_price: param }
                    this.setState({ item })
                }
                break;

            case 'amount':
                item[itemIndex] = { ...item[itemIndex], amount: param }
                this.setState({ item })
                break;

            case 'rate':
                //console.log('@updateItemForSubmit');
                //console.log('updating rate...');
                //console.log('foc prop is: ' + item[itemIndex].foc);
                //console.log('param is: ' + param);
                //console.log('index is: ' + itemIndex);
                //console.log('foc prop is:'+this.state.focProp)

                item[itemIndex] = { ...item[itemIndex], rate: param }
                this.setState({ item }, function () {
                    //console.log('new cart item state');
                    //console.log(item);
                })
                break;

            case 'qty':
                item[itemIndex] = { ...item[itemIndex], qty: param }
                this.setState({ item }, function () {

                })
                break;

            case 'delete':
                let theitem = [...this.state.item];
                let theitemIndex = theitem.findIndex(
                    i => i.item_code === code
                        && i.foc === foc
                );
                theitem.splice(theitemIndex, 1);
                this.setState({ item: theitem })

                console.log('@updateItemForSubmit::delete')
                console.log('deleting item index ' + theitemIndex)
                console.log('new item state')
                console.log(this.state.item)
                break;

            default: return null;
        }
    }

    updateCartItemDiscount() {
        let discount = this.state.discount;
        for (let i = 0; i < discount.length; i++) {
            if (discount[i].foc === false) {
                let code = discount[i].code;
                let rate = this.getItemDiscountRateOrType('rate', code);
                let type = this.getItemDiscountRateOrType('type', code);

                discount[i] = { ...discount[i], value: rate, type: type }
                this.setState({ discount })
            }
        }
    }

    getItemDiscountRateOrType(action, code) {
        //let items = this.state.products;
        let items = JSON.parse(localStorage.getItem('erpnext_item'));
        //let tier = this.state.membership;
        let wh = this.state.inv_warehouse;
        let today = this.state.inv_posting_date;
        let disrate = 0;
        let distype = 0;

        let i = items.findIndex(p => p.name === code);
        let item = items[i];

        //console.log('@getItemDiscountRateOrType exec');
        //console.log('code: ' + code);
        //console.log('action: ' + action);
        //console.log('active item');
        //console.log(item);

        let promo_valid = false;
        let promo = fGetPromoSetting(wh, item);

        if (promo.rate !== 0.00 && promo.rate !== null) {
            if (promo.start <= today && promo.end >= today) {
                promo_valid = true;
            }
        }

        if (promo_valid === true) {
            if (action === 'rate') {
                return promo.rate
            }
            else if (action === 'type') {
                return promo.type
            }
        }
        else {
            switch (this.state.membership) {
                case 'Classic':
                    disrate = item.classic_discount;
                    distype = item.classic_discount_is_currency;
                    if (action === 'rate') {
                        return disrate
                    }
                    else if (action === 'type') {
                        return distype
                    }
                    break;

                case 'Gold':
                    disrate = item.gold_discount;
                    distype = item.gold_discount_is_currency;
                    if (action === 'rate') {
                        return disrate
                    }
                    else if (action === 'type') {
                        return distype
                    }
                    break;

                case 'Platinum':
                    disrate = item.platinum_discount;
                    distype = item.platinum_discount_is_currency;
                    if (action === 'rate') {
                        return disrate
                    }
                    else if (action === 'type') {
                        return distype
                    }
                    break;

                default:
                    disrate = 0; distype = 0;
                    if (action === 'rate') {
                        return disrate
                    }
                    else if (action === 'type') {
                        return distype
                    }
                    break;
            }
        }
    }

    //--------------------------------------------------------------------------

    itemsAsOption() {
        let storage = JSON.parse(localStorage.getItem('erpnext_item'));
        if (storage !== null) {
            let obj = [];
            let items = storage;

            for (let i = 0; i < items.length; i++) {
                obj.push({
                    value: items[i].name,
                    label: items[i].item_name
                })
            }
            return obj;
        }
    }

    customerAsOption() {
        let storage = JSON.parse(localStorage.getItem('erpnext_customer'));
        if (storage !== null) {
            let obj = [];
            let customers = storage;

            for (let i = 0; i < customers.length; i++) {
                if (customers[i].customer_group === 'Walk In' && customers[i].territory === this.state.company) {
                    obj.push({ value: customers[i].name, label: customers[i].customer_name })
                }
                else if (customers[i].customer_group !== 'Walk In') {
                    obj.push({ value: customers[i].name, label: customers[i].customer_name })
                }
            }
            return obj;
        }
    }

    nricAsOption() {
        let storage = JSON.parse(localStorage.getItem('erpnext_customer'));
        if (storage !== null) {
            let obj = [];
            let customers = storage;

            for (var i = 0; i < customers.length; i++) {
                obj.push({ value: customers[i].name, label: customers[i].nric })
            }
            return obj;
        }
    }

    //--------------------------------------------------------------------------

    emptyCart() {
        this.setState({
            // @accounting
            paymentmodes: [],

            // @products
            pricerules: [],
            stores: null,
            stock: [],
            taxrate: 0,

            // @sales
            sale: [],
            customer: '',
            customerID: '',
            membership: 'None',
            point: 0,
            ptstominus: 0, // to minus the point above. check @getItemPrice
            zeropoints: 0,
            nric: '',
            nricopt: false,
            phone: '',
            profileimage: '',
            cart: [],
            item: [], // this for @pay now
            payments: [], // this for @pay now
            unit: [], // product counts for each cart item added
            discount: [], // the user may change the discount
            newdiscount: 0,
            units: 0, // total counts for all products added
            sum: 0.00, // before discount

            // @payment
            grandtotal: 0.00, // after discount
            cash: 0.00,
            paid: 0.00,
            change: 0.00,

            creditcard: 0.00,
            approvalcode: '',
            merchantcode: '',
            merchantid: '',
            cardholder: '',
            ccno: '',
            ccexpiry: '',
            cctype: undefined,
            selectedCCType: null,

            cheque: 0.00,
            chequeno: '',
            chequebank: '',
            epayment: 0.00,
            epaymentcode: '',
            epaymentbank: '',
            epaymenttype: '',
            selectedept: null,

            // @removeitem @cart
            // index, code, price, count
            nameProp: null,
            stockProp: null,
            indexProp: null,
            codeProp: null,
            priceProp: null,
            countProp: null,
            minusProp: 1,

            // @search - select
            selectedProduct: null,
            selectedCustomer: null,
            customerSearchResult: '',
            productNameSearchResult: '',
            productBarCodeSearchResult: '',
        }, function () {
            //console.log('selected Customer');
            //console.log(this.state.selectedCustomer);
            this.setWalkIn(this.state.company)
            this.closeModal();
        });
    }

    getCartItemCount(code, foc) {
        let unit = this.state.unit;
        for (var u = 0; u < unit.length; u++) {
            if (unit[u].code === code && unit[u].foc === foc) {
                return unit[u].value;
            }
        }
    }

    getCartItemDiscount(code, foc) {
        //let items = this.state.products;
        let discount = this.state.discount;

        let disIndex = discount.findIndex(d => d.code === code && d.foc === foc);
        return this.state.discount[disIndex].value;
    }

    getCartItemDiscountType(code, foc) {
        //let items = this.state.products;
        let discount = this.state.discount;

        let disIndex = discount.findIndex(d => d.code === code && d.foc === foc);
        let type = this.state.discount[disIndex].type;
        if (type === 0) {
            return "%";
        }
        else if (type === 1) {
            return "RM";
        }
    }

    removeItemFromCart(index, code, price, count, foc) {
        if (count > 1) {
            this.setState({
                minusProp: 1
            }, function () {
                this.toggleModal('reduceitem', index, code, price, count, foc);
            })
        }
        else {
            this.deleteItemFromCart(index, code, price, count, foc);
        }
    }

    adjustQtyFromCart(index, code, price, count, foc) {
        this.setState({ minusProp: count }, function () {
            this.toggleModal('adjustqty', index, code, price, count, foc);
        })
    }

    updateQtyFromCart(index, code, price, count, foc) {
        console.log('@updateQtyFromCart exec');
        console.log('code is: ' + code);
        console.log('index is: ' + index);
        console.log('minusProp is: ' + this.state.minusProp);
        console.log('focProp is: ' + foc);

        let unit = this.state.unit;
        let units = this.state.units;
        let cart = this.state.cart;
        let cartIndex = cart.findIndex(c => c.code === code && c.foc === foc);
        let unitIndex = unit.findIndex(u => u.code === cart[cartIndex].code && u.foc === foc);
        let newValue = this.state.minusProp;
        let curValue = unit[unitIndex].value;

        if (newValue > 0) {
            if (foc === false) {
                this.updateCart(code, cart[cartIndex].name, true, newValue);
                this.closeModal();
            }
            else {
                console.log('need method adjust qty for FOC items');
                this.updateCartForFOC(code, cart[cartIndex].code, true, newValue);
                this.closeModal();
            }
        }
        else {
            this.deleteItemFromCart(index, code, price, count, foc)
            this.closeModal();
        }
    }

    deleteItemFromCart(index, code, price, count, foc) {
        let cart = [...this.state.cart];
        let unit = [...this.state.unit];
        let discount = [...this.state.discount];
        let units = this.state.units;

        cart.splice(index, 1);
        this.setState({
            cart: cart
        }, function () {
            console.log(this.state.cart)
        });

        let unitIndex = unit.findIndex(u => u.code === code && u.foc === foc);
        unit.splice(unitIndex, 1);
        this.setState({ unit: unit });

        let disIndex = discount.findIndex(d => d.code === code && d.foc === foc);
        discount.splice(disIndex, 1);
        this.setState({ discount: discount });

        this.updateItemForSubmit('delete', code, price, count, foc)

        let newunits = units - count;
        this.setState({ units: newunits }, function () {
            this.getCartSum()
        });

        if (this.state.modalVisible === true) {
            this.closeModal();
        }
        this.setState({
            stock: []
        })
    }

    reduceItemFromCart(index, code, price, count, foc) {
        let unit = this.state.unit;
        let units = this.state.units;
        let cart = this.state.cart;
        let cartIndex = cart.findIndex(c => c.code === code && c.foc === foc);

        let unitIndex = unit.findIndex(u => u.code === cart[cartIndex].code);
        let curValue = unit[unitIndex].value;
        let newValue = Number(curValue) - Number(this.state.minusProp);
        let qtyToMinus = this.state.minusProp;
        //console.log('to minus is: ' + qtyToMinus);
        if (qtyToMinus > 0) {

            //console.log('new value is: ' + newValue);
            if (newValue === 0) {
                this.deleteItemFromCart(index, code, price, count, foc)
            }
            else if (newValue !== 0) {
                unit[unitIndex] = { ...unit[unitIndex], value: newValue }
                this.setState({ unit }, function () {
                    console.log('@reduceItemFromCart')
                    console.log('index to reduce: ' + unitIndex)
                    console.log('new unit state')
                    console.log(this.state.unit)
                    this.setState({
                        units: units - this.state.minusProp
                    }, function () {
                        this.getCartSum();
                        this.closeModal();
                    });
                });
            }
        }
        else {
            this.closeModal();
        }
    }

    roundToTwoDec(num) {
        let value = +(Math.round(num + "e+2") + "e-2");
        // console.log('value after roundToTwoDec', value);

        return value = parseFloat(value).toFixed(2);
    }

    cartList() {
        let cart = this.state.cart;
        let discountedprice = 0;

        if (cart !== null) {
            return cart.map((cart, index) => {
                let count = this.getCartItemCount(cart.code, cart.foc);
                let discountrate = this.getCartItemDiscount(cart.code, cart.foc);
                let discounttype = this.getCartItemDiscountType(cart.code, cart.foc);

                if (discounttype === '%') {
                    discountedprice = (cart.price - ((cart.price * discountrate) / 100)).toFixed(2);
                }
                else if (discounttype === 'RM') {
                    discountedprice = (cart.price - discountrate).toFixed(2);
                }

                let finalprice = this.roundToTwoDec(discountedprice * count);

                return (
                    <tr key={index}>
                        <td className="item-w">
                            <div onClick={() => this.removeItemFromCart(index, cart.code, discountedprice, count, cart.foc)}>
                                {cart.name}<br />
                                <small>{cart.code} | RM {cart.base.toFixed(2)}</small>
                            </div>
                        </td>
                        <td className="qty-w">
                            <div onClick={() => this.adjustQtyFromCart(index, cart.code, discountedprice, count, cart.foc)}>
                                {count}
                            </div>
                        </td>
                        <td className="disc-w">
                            <div onClick={() => this.toggleModal('changerate', index, cart.price.toFixed(2), discountrate, discounttype)}>
                                {discounttype}{Number(discountrate).toFixed(2)}
                            </div>
                        </td>
                        <td className="total-w">
                            <div onClick={() => this.setServiceFee('check', index)}>
                                {finalprice}
                            </div>
                        </td>
                    </tr>
                );
            })
        }
    }

    setServiceFee(action, index) {
        let cart = this.state.cart;
        let code = cart[index].code;

        if (action === 'check') {
            if (code === 'SVC-TRANSPORT') {
                this.toggleModal('servicefee', index);
            }
            else {
                console.log('not a service. price change will not be allowed');
            }
        }
        else if (action === 'update') {
            cart[index] = { ...cart[index], price: this.state.serviceFee }
            this.setState({ cart }, function () {
                console.log('cart updated: ');
                console.log(this.state.cart);
                this.getCartSum()
                this.closeModal()
            })
        }
    }

    //--------------------------------------------------------------------------

    renderItemImage(image, name, itemname) {
        if (image === undefined || image === null) {
            return (
                <div>
                    <img src="/images/item-blank.png" onClick={() => this.updateCart(name, itemname)} alt={itemname} />
                </div>
            )
        }
        else {
            return (
                <div>
                    <img src={fAppAPI() + image} onClick={() => this.updateCart(name, itemname)} alt={itemname} />
                </div>
            )
        }
    }

    mapFeaturedItem(type) {
        let promotion = JSON.parse(localStorage.getItem('promotion_item'));
        let popular = JSON.parse(localStorage.getItem('popular_item'));

        switch (type) {
            case 'popular':
                if (popular !== null) {
                    return popular.map((item, index) => {
                        return (
                            <div key={index} className="col-md-2">
                                <div id="popular">
                                    {this.renderItemImage(item.image, item.name, item.item_name)}
                                    <div className="popular-body">
                                        <div className="popular-heading">{item.item_name}</div>
                                        <small>{item.name}</small><br />
                                        <div className="popular-info">
                                            RM {item.price}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    });
                }
                break;

            case 'promotion':
                if (promotion !== null) {
                    return promotion.map((item, index) => {
                        return (
                            <div key={index} className="col-md-2">
                                <div id="popular">
                                    {this.renderItemImage(item.image, item.name, item.item_name)}
                                    <div className="popular-body">
                                        <div className="popular-heading">{item.item_name}</div>
                                        <small>{item.name}</small><br />
                                        <div className="popular-info">
                                            RM {item.price}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    });
                }
                break;

            default: return (null);
        }
    }

    mapPopularItem() {
        let items = JSON.parse(localStorage.getItem('erpnext_item'));
        if (items !== null) {
            return items.map((item, index) => {
                if (item.popular === 1) {
                    let price = this.getItemPrice(item.name, index, true)
                    return (
                        <div key={index} className="col-md-2">
                            <div id="popular">
                                {this.renderItemImage(item.image, item.name, item.item_name)}
                                <div className="popular-body">
                                    <div className="popular-heading">{item.item_name}</div>
                                    <small>{item.name}</small><br />
                                    <div className="popular-info">
                                        RM {price.price.toFixed(2)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                else {
                    return null;
                }
            })
        }
    }

    mapPromotionItem() {
        let items = JSON.parse(localStorage.getItem('erpnext_item'));
        if (items !== null) {
            return items.map((item, index) => {
                if (item.promotion === 1) {
                    let price = this.getItemPrice(item.name, index, true)
                    return (
                        <div key={index} className="col-md-2">
                            <div id="popular">
                                {this.renderItemImage(item.image, item.name, item.item_name)}
                                <div className="popular-body">
                                    <div className="popular-heading">{item.item_name}</div>
                                    <small>{item.name}</small><br />
                                    <div className="popular-info">
                                        RM {price.price.toFixed(2)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                else {
                    return null;
                }
            })
        }
    }

    renderLoginError() {
        if (this.state.loginError === true) {
            return (
                <div className="alert alert-warning" role="alert">
                    Invalid Username or Password
                </div>
            )
        }
    }

    setDialogWidth() {
        let action = this.state.modalAction;
        switch (action) {
            case 'paynow': return 'paynow-modal';
            case 'closing': return 'paynow-modal';
            case 'dailyreport': return 'report-modal';
            case 'redemption': return 'paynow-modal';
            case 'purchasehistory': return 'report-modal';
            case 'stockreport': return 'report-modal';
            default: return null;
        }
    }

    renderNRIC() {
        if (this.state.nric !== '') {
            return (
                <div>NRIC: {this.state.nric}</div>
            )
        }
    }

    renderMobileNO() {
        if (this.state.phone !== '') {
            return (
                <div>Mobile: {this.state.phone}</div>
            )
        }
    }

    toggleNRICOption() {
        if (this.state.nricopt === true) {
            this.setState({
                nricopt: false,
                customerSearchPlaceHolder: 'Search Customer By Name...'
            }, function () {
                this.setSelectCustomer('name');
            })
        }
        else {
            this.setState({
                nricopt: true,
                customerSearchPlaceHolder: 'Search Customer By IC...'
            }, function () {
                this.setSelectCustomer('nric');
            })
        }
    }

    toggleItemSearchOption() {
        let param = this.state.itemsearchopt;
        // order are barcode->typebarcode->code->name
        switch (param) {
            case 'barcode':
                this.setState({
                    itemSearchPlaceHolder: 'Type Product Barcode...',
                    itemsearchopt: 'typebarcode'
                });
                this.setSelectItem('typebarcode');
                break;

            case 'typebarcode':
                this.setState({
                    itemSearchPlaceHolder: 'Search Product By Item Code...',
                    itemsearchopt: 'code'
                });
                this.setSelectItem('code');
                break;

            case 'code':
                this.setState({
                    itemSearchPlaceHolder: 'Search Product Name...',
                    itemsearchopt: 'name'
                });
                this.setSelectItem('name');
                break;

            case 'name':
                this.setState({
                    itemSearchPlaceHolder: 'Scan Product Barcode...',
                    itemsearchopt: 'barcode'
                });
                this.setSelectItem('barcode');
                break;

            default:
                this.setState({
                    itemSearchPlaceHolder: 'Scan Product Barcode...',
                    itemsearchopt: 'barcode'
                });
                this.setSelectItem('barcode');
                break;
        }
    }

    toggleCustomerSearchOption() {
        let param = this.state.customersearchopt;
        switch (param) {
            case 'name':
                this.setState({
                    customerSearchPlaceHolder: 'Search By IC...',
                    customersearchopt: 'nric'
                });
                this.setSelectCustomer('nric');
                break;

            case 'nric':
                this.setState({
                    customerSearchPlaceHolder: 'Scan QR Code OR Barcode...',
                    customersearchopt: 'qrcode'
                });
                this.setSelectCustomer('qrcode');
                break;

            case 'qrcode':
                this.setState({
                    customerSearchPlaceHolder: 'Search By Name...',
                    customersearchopt: 'name'
                });
                this.setSelectCustomer('name');
                break;

            default:
                this.setState({
                    customerSearchPlaceHolder: 'Search By Name...',
                    customersearchopt: 'name'
                });
                this.setSelectCustomer('name');
                break;
        }
    }

    setOpt() {
        const customer = this.customerAsOption();
        const nric = this.nricAsOption();

        if (this.state.nricopt === true) {
            return nric;
        }
        else {
            return customer;
        }
    }

    setEditable(membership) {
        let editer = this.state.editer;
        if (membership !== 'None' && editer === 1) {
            return (
                <span
                    className="profile-btns"
                    onClick={() => this.editMember(this.state.customerID)}
                >
                    <i className="fa fa-pencil"></i>
                    <small>Edit</small>
                </span>
            )
        }
    }

    //----------------------------------------------------------------------------

    renderPurchaseHistoryIcon() {
        if (this.state.membership !== 'None') {
            return (
                <React.Fragment>
                    <span
                        className="profile-btns"
                        onClick={() => this.getPurhcaseHistory()}
                    >
                        <i className="fa fa-clipboard"></i>
                        <small>History</small>
                    </span>
                    <span
                        className="profile-btns"
                        onClick={() => this.getRedemption()}
                    >
                        <i className="fa fa-gift"></i>
                        <small>Redemption</small>
                    </span>
                </React.Fragment>
            );
        }
    }

    getRedemption() {
        this.fetchRedeemHistory()
        this.toggleModal('redemption')
    }

    getPurhcaseHistory() {
        this.toggleModal('spinner');
        this.setState({
            invoices: [],
            inv_cash: 0.00,
            inv_creditcard: 0.00,
            inv_epayment: 0.00,
            inv_cheque: 0.00,
            inv_total: 0.00,
            returns: [],
            ret_cash: 0.00,
            ret_creditcard: 0.00,
            ret_epayment: 0.00,
            report_type: 'Invoice',
            report_type_text: 'Product',
            report_item_data: [],
            isReturn: false,
            showUtilBtn: true,
        })
        let fields = '?fields["name", "status"]';
        let filter = '&filters=[["customer", "=", "' + this.state.customerID + '"],["is_pos", "=", "1"],["status", "!=", "Cancelled"]]';
        let limits = '&limit_page_length=0';
        let api = fAppAPI() + 'api/resource/Sales Invoice/' + fields + filter + limits;

        console.log('@getPurhcaseHistory, calling API')
        console.log(api)

        return fetch(api, {
            method: 'GET',
            credentials: 'include'
        })
            .then((resp) => resp.json())
            .then((resj) => {
                this.setState({
                    invoices: resj.data
                }, function () {
                    this.getTotalByModeOfPayment(true)
                    this.timeoutHandle = setTimeout(() => {
                        this.closeModal()
                        //this.updateInvTotal()
                        this.toggleModal('purchasehistory')
                    }, 5000)
                })
            })
            .catch((error) => {
                console.log('@getPurhcaseHistory error')
                console.log(error)
                this.setState({ appFlow: 'error' })
            })
    }

    goPayCashNow() {
        this.setState({ salesperson_name: 'Not Available' }, function () {
            console.log('salesperson_name state set to default -> Not Available')
            this.toggleModal('paynowcash')
        })
    }

    //----------------------------------------------------------------------------

    render() {
        switch (this.state.appFlow) {
            case 'default':
                return (
                    <div>
                        {this.renderTopNav()}
                        <div className="container-fluid app-pos-container">
                            <div className="row">
                                <div id="app-pos">

                                    <div className="customer">
                                        {/* @search @customer */}
                                        <div className="clearfix">
                                            <div className="pull-left">
                                                <div id="placeholder">
                                                    <i className="fa fa-search"></i> {this.state.customerSearchPlaceHolder}
                                                </div>
                                            </div>
                                            <div className="pull-right">
                                                {this.renderBdayCheckbox()}
                                            </div>
                                        </div>

                                        <div className="form-inline">
                                            <div className="form-group">
                                                <Button
                                                    className="btn btn-default btn-toggle-nric"
                                                    onClick={() => this.toggleCustomerSearchOption()}
                                                >
                                                    <i className="fa fa-refresh"></i>
                                                </Button>
                                            </div>
                                            <div className="form-group select-container">
                                                <AsyncSelect
                                                    value={this.state.customerSearchValue}
                                                    loadOptions={this.loadCustomerOptions}
                                                    onInputChange={this.handleAsyncSelectCustomer}
                                                    onChange={this.handleChangeCustomer}
                                                    placeholder={this.state.customerSearchPlaceHolder}
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <div className="col-md-4">
                                                <div className="avatar">
                                                    <img className="img-circle" src={this.getAvatar()} alt="avatar" />
                                                </div>
                                                <div align="center">
                                                    {this.setEditable(this.state.membership)}
                                                    {this.renderPurchaseHistoryIcon()}
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                {/* @membership */}
                                                <div className="membership">
                                                    <div>
                                                        <strong>
                                                            {
                                                                this.state.customer === 'Walk In (Luyang 2)' ?
                                                                    'Walk In (Likas)' : this.state.customer
                                                            }
                                                        </strong>
                                                    </div>
                                                    {this.renderNRIC()}
                                                    {this.renderMobileNO()}
                                                    <div style={{ marginTop: 5 }}>
                                                        <i className="fa fa-certificate"></i> {this.state.membership}
                                                        <span className="app-spliter"></span>
                                                        <i className="fa fa-trophy"></i> {this.state.point}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* @cart list */}
                                        <div className="deco">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="item-w">Item</th>
                                                        <th style={{ width: 50 }}>Qty</th>
                                                        <th className="disc-w">Dis</th>
                                                        <th className="total-w">RM</th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                        <div className="items">
                                            <table>
                                                <tbody>
                                                    {this.cartList()}
                                                </tbody>
                                            </table>
                                        </div>

                                        {/* @grandtotal */}
                                        <div className="grandtotal">
                                            <div className="col-md-6">
                                                <strong>GRAND TOTAL</strong><br />
                                                <small>Total Items: {this.state.units}</small>
                                            </div>
                                            <div className="col-md-6" align="right">
                                                <h2>{this.state.sum}</h2>
                                            </div>
                                        </div>

                                        {/* @paynow */}
                                        <div className="paybutton">
                                            <button id="payNow" type="button" className="btn btn-block btn-success btn-lg" onClick={() => this.goPayCashNow()}>
                                                Pay Now
                                            </button>
                                            <button id="noSale" type="button" className="btn btn-block btn-danger btn-sm" onClick={() => this.toggleModal('emptycart')}>
                                                No Sale
                                            </button>
                                        </div>
                                    </div>

                                    <div className="products">
                                        <div className="product-div">
                                            <div className="row">

                                                <div id="placeholder" className="col-md-11">
                                                    <div className="pull-left">
                                                        <i className="fa fa-search"></i> {this.state.itemSearchPlaceHolder}
                                                    </div>
                                                    {this.renderFOCcheckbox()}
                                                </div>

                                                <div className="col-md-11">
                                                    <AsyncSelect
                                                        ref='asyncProduct'
                                                        value={this.state.productSearchValue}
                                                        loadOptions={this.loadProductOptions}
                                                        onInputChange={this.handleAsyncSelectProduct}
                                                        onChange={this.handleChangeProduct}
                                                        placeholder={this.state.itemSearchPlaceHolder}
                                                        autoFocus={true}
                                                    />
                                                </div>

                                                <div className="col-md-1">
                                                    <Button
                                                        className="btn btn-default btn-toggle-nric"
                                                        onClick={() => this.toggleItemSearchOption()}
                                                    >
                                                        <i className="fa fa-refresh"></i>
                                                    </Button>
                                                </div>
                                            </div>

                                            <br />

                                            <div className="row">
                                                <div className="scrollable">
                                                    <div className="col-md-12">
                                                        <label><small>POPULAR</small></label><br />
                                                        {this.mapFeaturedItem('popular')}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <hr className="app-hr" />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="scrollable">
                                                    <div className="col-md-12">
                                                        <label><small>PROMOTION</small></label><br />
                                                        {this.mapFeaturedItem('promotion')}
                                                    </div>
                                                </div>
                                            </div>
                                            {this.renderStockBalance()}
                                        </div>

                                    </div>
                                </div>{ /*app-pos*/}
                            </div>
                        </div>

                        <Modal dialogClassName={this.setDialogWidth()} show={this.state.modalVisible} onHide={() => this.toggleModal()}>
                            {this.renderModal()}
                        </Modal>
                    </div>
                );

            /***/

            case 'login':
                return (
                    <div>
                        {this.renderTopNav()}

                        <div className="container">
                            <div className="row" style={{ marginTop: 20 }}>
                                <div className="col-md-4 col-md-offset-4">
                                    {this.renderLoginError()}
                                    <div className="panel panel-default">
                                        <div className="panel-heading">
                                            Welcome &bull; Authorization Required
                                        </div>
                                        <div className="panel-body">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <div className="input-group-addon"><i className="fa fa-user"></i></div>
                                                    <input type="text" className="form-control" placeholder="Username" value={this.state.username} onChange={this.handleChangeU} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <div className="input-group-addon"><i className="fa fa-key"></i></div>
                                                    <input type="password" className="form-control" placeholder="Password" value={this.state.password} onChange={this.handleChangeP} />
                                                </div>
                                            </div>
                                            <Button bsStyle="primary" onClick={() => this.authERPNext()}>
                                                Sign In <i className="fa fa-sign-in"></i>
                                            </Button>
                                        </div>
                                        <div className="panel-footer">
                                            <small>{moment().format('YYYY-MM-DD')}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                );

            /***/

            case 'loading':
                return (
                    <div>
                        {this.renderTopNav()}
                        <div className="row" style={{ marginTop: 20 }}>
                            <div className="col-md-4 col-md-offset-4">
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        Please Wait
                                    </div>
                                    <div className="panel-body">
                                        Initializing... <i className="fa fa-circle-o-notch fa-spin fa-fw app-spinner-color"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );

            /***/
            default:
                return (
                    <div>
                        No view provided for application flow: {this.state.appFlow}
                    </div>
                );
        }
    }
}

/**

<hr/>
<div className="form-group">
  <label className="col-sm-3 control-label"></label>
  <div className="col-sm-9">
    <label className="control-label">MLM Reference</label>
  </div>
</div>
<div className="form-group">
  <div className="col-sm-3" align="right">
    <Button
      className="btn btn-default btn-toggle-nric"
      onClick={()=>this.toggleCustomerSearchOption()}
    >
      <i className="fa fa-refresh"></i>
    </Button>
  </div>
  <div className="col-sm-9">
    <AsyncSelect
      value={this.state.customerRefLabel}
      loadOptions={this.loadCustomerOptionsOnRegister}
      onInputChange={this.handleAsyncSelectCustomer}
      onChange={this.handleChangeCustomerOnRegister}
      placeholder={this.state.customerSearchPlaceHolder}
    />
  </div>
</div>
{this.renderRefMember()}

**/
