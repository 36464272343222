import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment';
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import ReactToPrint from 'react-to-print';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { webAPI } from './library/appconst.js';
import { fAppAPI } from './library/appfunction.js';
import './App.css';

export default class Report extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loggedin: false,
      pin:'',
      appFlow:'idle',
      firstname:'',
      summary:[],
      data:[],
      outlet:'',
      dateFrom:null,
      dateTo:null,
      isTest:false,
      reportByDate:[],
      groupByDate:true,
    }
    this.setDateFrom = this.setDateFrom.bind(this);
    this.setDateTo = this.setDateTo.bind(this);
    this.handleChangePin = this.handleChangePin.bind(this);
  }

  componentDidMount() {
    //this.fetchReport()
  }

  //----------------------------------------------------------------------------

  handleChangePin(event){
    this.setState({
      pin: event.target.value
    });
  }

  setGroupByDate() {
    if(this.state.groupByDate === true) {
      this.setState({groupByDate:false},function(){
        console.log('group by date false')
        document.getElementById("groupByDateCheckbox").checked = false;
      })
    }
    else {
      this.setState({groupByDate:true},function(){
        console.log('group by date true')
        document.getElementById("groupByDateCheckbox").checked = true;
      })
    }
  }

  setDateFrom(date) {
    let formated = moment(date).format('YYYY-MM-DD')
    this.setState({dateFrom:date})
  }

  setDateTo(date) {
    this.setState({dateTo:date})
  }

  setOutlet = (outlet) => {
    if(outlet) {
      this.setState({
        outlet
      })
    }
  }

  renderSelectOutlet() {
    const outlets = [
      {value:'Outlet Bundusan', label:'Outlet Bundusan'},
      {value:'Outlet Luyang', label:'Outlet Luyang'},
      {value:'Outlet Keningau', label:'Outlet Keningau'},
      {value:'Outlet Kudat', label:'Outlet Kudat'},
      {value:'Outlet Tawau', label:'Outlet Tawau'},
    ];
    const {outlet} = this.state;
    return (
      <Select
        name="outlet"
        value={outlet}
        onChange={this.setOutlet}
        options={outlets}
        placeholder="Select Outlet"
        className="select-container-outlet"
      />
    );
  }

  fetchReport() {
    this.setState({appFlow:'loading'})
    const {outlet, dateFrom, dateTo, isTest} = this.state;
    let api = '';
    if(isTest === true) {
      api = webAPI + 'method=report'
          + '&outlet=Outlet Bundusan'
          + '&from=2019-01-01'
          + '&to=2019-01-05'
    }
    else {
      api = webAPI + 'method=report'
          + '&outlet='+outlet.value
          + '&from='+moment(dateFrom).format('YYYY-MM-DD')
          + '&to='+moment(dateTo).format('YYYY-MM-DD')
    }
    //console.log('calling api')
    //console.log(api)
    return fetch(api)
    .then((resp) => resp.json())
    .then((resj) => {
      this.setState({
        summary:resj.summary,
        data:resj.data
      },function(){
        this.groupReportByDate()
      })
    })
  }

  groupReportByDate() {
    let data = this.state.data;
    let entries = [];

    for(let i=0;i<data.length;i++) {
      if(i+1 === data.length) {
        let v = entries.findIndex(
          d => d.date === data[i].invoice.posting_date
        );
        if(v === -1) {
          let entry = {
            inv:1,
            date:data[i].invoice.posting_date,
            total:data[i].invoice.net_total,
            cost:data[i].invoice.net_cost,
            gross:data[i].invoice.net_gross
          }
          entries.push(entry)
        }
        else {
          let inv = entries[v].inv;
          let total = entries[v].total;
          let ntotal = total + data[i].invoice.net_total;
          let cost = entries[v].cost;
          let ncost = cost + data[i].invoice.net_cost;
          let gross = entries[v].gross;
          let ngross = gross + data[i].invoice.net_gross;
          entries[v] = {
            inv:inv+1,
            date: data[i].invoice.posting_date,
            total:ntotal,
            cost:ncost,
            gross:ngross
          }
        }
        console.log('all data added')
        //console.log(entries)
        this.setState({
          reportByDate:entries
        },function(){
          this.setState({appFlow:'default'},function(){
            document.getElementById("groupByDateCheckbox").checked = true;
          })
        })
      }
      else {
        if(entries.length === 0) {
          console.log('adding new inv')
          let entry = {
            inv:1,
            date:data[i].invoice.posting_date,
            total:data[i].invoice.net_total,
            cost:data[i].invoice.net_cost,
            gross:data[i].invoice.net_gross
          }
          entries.push(entry)
        }
        else {
          let v = entries.findIndex(
            d => d.date === data[i].invoice.posting_date
          );
          if(v === -1) {
            let entry = {
              inv:1,
              date:data[i].invoice.posting_date,
              total:data[i].invoice.net_total,
              cost:data[i].invoice.net_cost,
              gross:data[i].invoice.net_gross
            }
            entries.push(entry)
          }
          else {
            let inv = entries[v].inv;
            let total = entries[v].total;
            let ntotal = total + data[i].invoice.net_total;
            let cost = entries[v].cost;
            let ncost = cost + data[i].invoice.net_cost;
            let gross = entries[v].gross;
            let ngross = gross + data[i].invoice.net_gross;
            entries[v] = {
              inv:inv+1,
              date: data[i].invoice.posting_date,
              total:ntotal,
              cost:ncost,
              gross:ngross
            }
          }
        }
      }
    }
  }

  renderReport() {
    //console.log('reportGroupByDate:')
    //console.log(this.state.reportByDate)
    let flow = this.state.appFlow;
    if(flow === 'ready') { return 'Set report criteria by setting the date from and date to.' }
    else if(flow === 'loading') {
      return (
        <React.Fragment>
          <span>Generating Report, Please Wait... </span>
          <i className="fa fa-circle-o-notch fa-spin fa-fw app-spinner-color"></i>
        </React.Fragment>
      )
    }
    else if(flow === 'default') {
      let summary = this.state.summary;

      let outlet = summary.outlet;
      let transaction = summary.transaction;
      let selling = summary.selling;
      let buying = summary.buying;
      let grossprofit = summary.gross;
      let grosspercent = summary.percentage;

      return (
        <div>
          <h3>Summary</h3>
          <p>
            <strong>Outlet: </strong>{outlet}<br/>
            <strong>Total Transaction: </strong>{transaction}<br/>
            <strong>Total Selling: </strong>{selling.toFixed(2)}<br/>
            <strong>Total Cost: </strong>{buying.toFixed(2)}<br/>
            <strong>Gross Profit: </strong>{grossprofit.toFixed(2)}<br/>
            <strong>Gross Percentage: </strong>{grosspercent.toFixed(2)}%<br/>
          </p>
          <hr/>
          <h4>Transactions</h4>
          <input
            id="groupByDateCheckbox"
            type="checkbox"
            onClick={()=>this.setGroupByDate()}
            /> Group By Dates
          <hr/>
          <table className="table table-striped">
          <thead>
          <tr>
            <th>#</th>
            <th>Invoice</th>
            <th>Amount</th>
            <th>Cost</th>
            <th>Gross</th>
            <th>%</th>
            <th>Date</th>
          </tr>
          </thead>
          <tbody>
          {this.renderInvoiceList()}
          <tr>
            <td></td>
            <td><strong>{transaction}</strong></td>
            <td><strong>{selling.toFixed(2)}</strong></td>
            <td><strong>{buying.toFixed(2)}</strong></td>
            <td><strong>{grossprofit.toFixed(2)}</strong></td>
            <td></td>
            <td></td>
          </tr>
          </tbody>
          </table>
        </div>
      );
    }
  }

  renderInvoiceItem(data) {
    console.log('@renderInvoiceItem')
    return data.map((item, index) => {
      return (
        <React.Fragment>
        <hr className="spliter"/>
        <p>
          Code: {item.item_code}<br/>
          Qty: {item.qty}<br/>
          Rate Per Item: {item.rate_per_item.toFixed(2)}<br/>
          Cost Per Item: {item.cost_per_item.toFixed(2)}<br/>
          Net Total: {item.net_total.toFixed(2)}<br/>
          Net Gross: {item.net_gross.toFixed(2)}
        </p>
        </React.Fragment>
      );
    })
  }

  renderInvoiceList() {
    if(this.state.groupByDate === true) {
      let reportByDate = this.state.reportByDate;
      return reportByDate.map((item, index) => {
        let percent = ((item.gross/item.total)*100)
        return (
          <tr key={index}>
            <td>{index+1}</td>
            <td>Total {item.inv}</td>
            <td>{item.total.toFixed(2)}</td>
            <td>{item.cost.toFixed(2)}</td>
            <td>{item.gross.toFixed(2)}</td>
            <td>{percent.toFixed(2)}</td>
            <td>{item.date}</td>
          </tr>
        );
      })
    }
    else {
      let data = this.state.data;
      return data.map((item, index) => {
        let percent = ((item.invoice.net_gross/item.invoice.net_total)*100)
        return (
          <tr key={index}>
            <td>{index+1}</td>
            <td>
              {item.invoice.name}<br/>
              {this.renderInvoiceItem(item.items)}
            </td>
            <td>{item.invoice.net_total.toFixed(2)}</td>
            <td>{item.invoice.net_cost.toFixed(2)}</td>
            <td>{item.invoice.net_gross.toFixed(2)}</td>
            <td>{percent.toFixed(2)}</td>
            <td>{item.invoice.posting_date}</td>
          </tr>
        );
      })
    }
  }

  //----------------------------------------------------------------------------

  checkData() {
    const {outlet, dateFrom, dateTo} = this.state;
    if(outlet === ''
    || dateFrom === null
    || dateFrom === ''
    || dateTo === null
    || dateTo === '') {
      alert("Criteria not set properly.")
    }
    else {
      this.fetchReport()
    }
  }

  verifyPin() {
    if(this.state.pin === '8899') {
      this.setState({loggedin:true, appFlow:'ready'})
    }
    else {
      alert('Incorrect Pin')
    }
  }

  //----------------------------------------------------------------------------

  render() {
    if(this.state.loggedin === false) {
      return (
        <div>
          <nav className="navbar navbar-default app-pos-navbar">
            <div className="navbar-header">
              <a className="navbar-brand" href="#">Sales Report</a>
            </div>
          </nav>
          <div className="container-fluid">
            <div className="row" style={{paddingTop:10}}>
              <div className="col-md-6">
                <p>Authorization Required</p>
                <form className="form-inline">

                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      value={this.state.pin}
                      onChange={this.handleChangePin}/>
                  </div>

                  <div className="form-group">
                    <Button
                      className="btn btn-primary btn-pad-left"
                      onClick={()=>this.verifyPin()}
                    >
                      Sign In
                    </Button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      )
    }
    else {
      return (
        <div>
          <nav className="navbar navbar-default app-pos-navbar">
            <div className="navbar-header">
              <a className="navbar-brand" href="#">Sales Report</a>
            </div>
          </nav>

          <div className="container-fluid">
            <div className="row report-opt-div">
              <div className="col-md-3">
                <label>Date From</label>
                <DatePicker
                  selected={this.state.dateFrom}
                  onChange={this.setDateFrom}
                  className="datepicker-select-repo"
                  calendarClassName="datepicker-calendar"
                />
              </div>
              <div className="col-md-3">
                <label>Date To</label>
                <DatePicker
                  selected={this.state.dateTo}
                  onChange={this.setDateTo}
                  className="datepicker-select-repo"
                  calendarClassName="datepicker-calendar"
                />
              </div>
              <div className="col-md-3">
                <label>Outlet</label>
                {this.renderSelectOutlet()}
              </div>
              <div className="col-md-3">
                <label></label><br/>
                <Button
                  id="repoSubmit"
                  className="btn btn-primary repo-submit-btn"
                  onClick={()=>this.checkData()}
                >
                  Submit
                </Button>
              </div>
            </div>
            <div className="row" style={{paddingTop:20}}>
              <div className="col-md-12">
                {this.renderReport()}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutHandle);
  }
}
