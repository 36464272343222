export const devAPI = 'https://api.paragonpetsgroup.net/dev.php?';
export const webAPI = 'https://api.paragonpetsgroup.net/';

export const appColor = {
	yellow:  '#FDD835',
	blue:    '#03A9F4',
	orange:  '#FF6F00',
	green:   '#8BC34A',
  grey:    'silver'
}
