import React, { Component } from 'react';
import './App.css';

//------------------------------------------------------------------------------

export default class PrintClosing extends Component {
    constructor(props) {
        super(props)
        this.state = {
            appFlow: 'loading',
            cl_cent_1: 0, cl_sum_cent_1: 0,
            cl_cent_5: 0, cl_sum_cent_5: 0,
            cl_cent_10: 0, cl_sum_cent_10: 0,
            cl_cent_20: 0, cl_sum_cent_20: 0,
            cl_cent_50: 0, cl_sum_cent_50: 0,
            cl_cent_rm1: 0, cl_sum_cent_rm1: 0,
            cl_rm_1: 0, cl_sum_rm_1: 0,
            cl_rm_5: 0, cl_sum_rm_5: 0,
            cl_rm_10: 0, cl_sum_rm_10: 0,
            cl_rm_20: 0, cl_sum_rm_20: 0,
            cl_rm_50: 0, cl_sum_rm_50: 0,
            cl_rm_100: 0, cl_sum_rm_100: 0,
            cl_total_cent: 0.00,
            cl_total_rm: 0.00,
            cl_total: 0.00,
            total: 0.00,
            cashier: '',
            company: '',
            outlet: '',
            date: '',
            return: '',
            ret_cash:'',
            ret_creditcard:'',
            ret_epayment:''
        }
    }

    componentDidMount() {
        //console.log('@componentDidMount exec');
				/*
				console.log('sum up returns for cash, cc and epayment')
				let r_cs = -10.00
				let r_cc = -10.00
				let r_ep = -10.00
				let sum = 1000.00

				let r_sum = parseFloat(r_cs) + parseFloat(r_cc) + parseFloat(r_ep)
				console.log('r_sum test is ',r_sum.toFixed(2))

				let total = parseFloat(sum) + parseFloat(r_sum)
				console.log('total after minus r_sum ',total.toFixed(2))
				*/

				let r_cs = this.props.ret_cash
				let r_cc = this.props.ret_creditcard
				let r_ep = this.props.ret_epayment

				let r_sum = (parseFloat(r_cs) + parseFloat(r_cc) + parseFloat(r_ep)).toFixed(2)
				let total = this.props.sum
				let total_after_return = (parseFloat(total) + parseFloat(r_sum)).toFixed(2)

				console.log('cash returns: ',r_cs.toFixed(2))
				console.log('credit returns: ',r_cc.toFixed(2))
				console.log('epayment returns: ',r_ep.toFixed(2))
				console.log('total returns: ',r_sum)
				console.log('sum before returns: ',total)
				console.log('sum after returns: ',total_after_return)

        this.setState({
            cl_cent_1: this.props.cl_cent_1, cl_sum_cent_1: this.props.cl_sum_cent_1,
            cl_cent_5: this.props.cl_cent_5, cl_sum_cent_5: this.props.cl_sum_cent_5,
            cl_cent_10: this.props.cl_cent_10, cl_sum_cent_10: this.props.cl_sum_cent_10,
            cl_cent_20: this.props.cl_cent_20, cl_sum_cent_20: this.props.cl_sum_cent_20,
            cl_cent_50: this.props.cl_cent_50, cl_sum_cent_50: this.props.cl_sum_cent_50,
            cl_cent_rm1: this.props.cl_rm_1, cl_sum_cent_rm1: this.props.cl_sum_cent_rm1,
            cl_rm_1: this.props.cl_rm_1, cl_sum_rm_1: this.props.cl_sum_rm_1,
            cl_rm_5: this.props.cl_rm_5, cl_sum_rm_5: this.props.cl_sum_rm_5,
            cl_rm_10: this.props.cl_rm_10, cl_sum_rm_10: this.props.cl_sum_rm_10,
            cl_rm_20: this.props.cl_rm_20, cl_sum_rm_20: this.props.cl_sum_rm_20,
            cl_rm_50: this.props.cl_rm_50, cl_sum_rm_50: this.props.cl_sum_rm_50,
            cl_rm_100: this.props.cl_rm_100, cl_sum_rm_100: this.props.cl_sum_rm_100,
            cl_total_cent: this.props.cl_total_cent,
            cl_total_rm: this.props.cl_total_rm,
            cl_total: this.props.cl_total,
            //total: this.props.sum,
						total: total_after_return,
            total_cash: this.props.cash,
            ret_cash:this.props.ret_cash,
						return: this.props.return,
            total_creditcard: this.props.creditcard,
            ret_creditcard:this.props.ret_creditcard,
            total_epayment: this.props.epayment,
            ret_epayment:this.props.ret_epayment,
            total_boost:this.props.boost,
            total_quinpay:this.props.quinpay,
            total_splus:this.props.splus,
            total_cheque: this.props.cheque,
            cashier: this.props.cashier,
            company: this.props.company,
            outlet: this.props.outlet,
            date: this.props.date,
        }, function() {
            this.setState({appFlow:'default'})
        })
    }

    render() {
        switch(this.state.appFlow) {
            case 'default':
                return (
                    <div id="app-pos-modal">
                        <div className="receipt">
                            <table>
                            <tbody>
                            <tr>
                                <td colSpan="3" align="center" style={{border:'none'}}>
                                    {/*<label>{this.state.company}</label><br/>*/}
                                    Cashier: {this.state.cashier}<br/>
                                    Closing Date: {this.state.date}<br/>
                                    Outlet: {this.state.outlet === "Luyang 2" ? "Likas" : this.state.outlet}
                                </td>
                            </tr>
                            <tr><td colSpan="3"><br/></td></tr>
                            <tr><td colSpan="3"><hr className="app-hr-thin"/></td></tr>
                            <tr>
                                <td colSpan="2">
                                  <label>SALES</label><br/><br/>
                                </td>
                                <td align="right" valign="top"><label>{this.state.total}</label></td>
                            </tr>
                            <tr><td colSpan="3"><label>Sales Breakdown</label></td></tr>
                            <tr>
                                <td colSpan="2">
                                  Cash Sales<br/>
                                  Sales Return
                                </td>
                                <td align="right">
																	{(this.state.cl_total).toFixed(2)}<br/>
                                  {this.state.ret_cash.toFixed(2)}<br/><br/>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                  Credit Card<br/>
                                  Credit Card Returns
                                </td>
                                <td align="right">
                                  {(this.state.total_creditcard+this.state.ret_creditcard).toFixed(2)}<br/>
                                  {this.state.ret_creditcard.toFixed(2)}<br/><br/>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                  E-Payment<br/>
                                  Boost<br/>
                                  Quinpay<br/>
                                  Splus<br/>
                                  E-Payment Returns (+Boost)
                                </td>
                                <td align="right">
                                  {(this.state.total_epayment+this.state.ret_epayment).toFixed(2)}<br/>
                                  {this.state.total_boost.toFixed(2)}<br/>
                                  {this.state.total_quinpay.toFixed(2)}<br/>
                                  {this.state.total_splus.toFixed(2)}<br/>
                                  {this.state.ret_epayment.toFixed(2)}<br/><br/>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">Cheque</td>
                                <td align="right">{this.state.total_cheque.toFixed(2)}</td>
                            </tr>
                            <tr><td colSpan="3"><hr className="app-hr-thin"/></td></tr>
                            <tr><td colSpan="3"><label>POS Cash Count</label></td></tr>
                            <tr>
                                <td className="item-w"> &cent; 0.1</td>
                                <td className="qty-w">&times;{this.state.cl_cent_1}</td>
                                <td className="total-w">{this.state.cl_sum_cent_1}</td>
                            </tr>
                            <tr>
                                <td className="item-w"> &cent; 0.5 </td>
                                <td className="qty-w">&times;{this.state.cl_cent_5}</td>
                                <td className="total-w">{this.state.cl_sum_cent_5}</td>
                            </tr>
                            <tr>
                                <td className="item-w"> &cent; 10</td>
                                <td className="qty-w">&times;{this.state.cl_cent_10}</td>
                                <td className="total-w">{this.state.cl_sum_cent_10}</td>
                            </tr>
                            <tr>
                                <td className="item-w"> &cent; 20</td>
                                <td className="qty-w">&times;{this.state.cl_cent_20}</td>
                                <td className="total-w">{this.state.cl_sum_cent_20}</td>
                            </tr>
                            <tr>
                                <td className="item-w"> &cent; 50</td>
                                <td className="qty-w">&times;{this.state.cl_cent_50}</td>
                                <td className="total-w">{this.state.cl_sum_cent_50}</td>
                            </tr>
                            {
                              /*
                              <tr>
                                  <td className="item-w"> &cent; 1</td>
                                  <td className="qty-w">&times;{this.state.cl_cent_rm1}</td>
                                  <td className="total-w">{this.state.cl_sum_cent_rm1}</td>
                              </tr>
                              */
                            }
                            <tr><td colSpan="3"><hr className="app-hr-thin"/></td></tr>
                            <tr>
                                <td className="item-w">&#36; 1</td>
                                <td className="qty-w">&times;{this.state.cl_rm_1}</td>
                                <td className="total-w">{this.state.cl_sum_rm_1}</td>
                            </tr>
                            <tr>
                                <td className="item-w">&#36; 5</td>
                                <td className="qty-w">&times;{this.state.cl_rm_5}</td>
                                <td className="total-w">{this.state.cl_sum_rm_5}</td>
                            </tr>
                            <tr>
                                <td className="item-w">&#36; 10</td>
                                <td className="qty-w">&times;{this.state.cl_rm_10}</td>
                                <td className="total-w">{this.state.cl_sum_rm_10}</td>
                            </tr>
                            <tr>
                                <td className="item-w">&#36; 20</td>
                                <td className="qty-w">&times;{this.state.cl_rm_20}</td>
                                <td className="total-w">{this.state.cl_sum_rm_20}</td>
                            </tr>
                            <tr>
                                <td className="item-w">&#36; 50</td>
                                <td className="qty-w">&times;{this.state.cl_rm_50}</td>
                                <td className="total-w">{this.state.cl_sum_rm_50}</td>
                            </tr>
                            <tr>
                                <td className="item-w">&#36; 1</td>
                                <td className="qty-w">&times;{this.state.cl_rm_100}</td>
                                <td className="total-w">{this.state.cl_sum_rm_100}</td>
                            </tr>
                            <tr><td colSpan="3"><hr className="app-hr-thin"/></td></tr>
                            <tr>
                                <td colSpan="2"><label>Total Cents</label></td>
                                <td align="right">{this.state.cl_total_cent}</td>
                            </tr>
                            <tr>
                                <td colSpan="2"><label>Total Dollar Bills</label></td>
                                <td align="right">{this.state.cl_total_rm}</td>
                            </tr>
                            <tr>
                                <td colSpan="2"><label>Total POS Cash Count</label></td>
                                <td align="right">{this.state.cl_total.toFixed(2)}</td>
                            </tr>
                            </tbody>
                            </table>
                        </div>
                    </div>
                );

            case 'loading':
                return (
                    <div id="app-pos-modal">
                        <div align="center">
                            Generating Receipt...
                            <i className="fa fa-circle-o-notch fa-spin fa-fw app-spinner-color"></i>
                        </div>
                    </div>
                );

            default:
                return (
                    <div id="app-pos-modal">
                        <div align="center">
                            ERROR: Unable to generate receipt. Please check your internet connection.
                        </div>
                    </div>
                );
        }
    }
}
