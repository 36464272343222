import React, { Component } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { Modal, Row, Button, Panel, Grid, Col, FormGroup, FormControl } from 'react-bootstrap';

import {ReportHeader, ReportFooter, ReportLoading, ReportError} from './Component';
import './Reports.css';
import {appColor} from './Constant';
import {fVerifyPin, fInitApp, fSyncItemData, fSyncSalesPersonData} from './Function';

//---

export default class Reports extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appFlow:'loading',
      appBusy:false,
      loadingText:'Please wait...',
      pin:'',
      modalSyncVisible:false,
      modalErrorVisible:false,
      dataLoaded:false
    }
  }

  async componentDidMount() {
    await this.initComponent()
  }

  async initComponent() {
    this.setState({ appFlow:'loading' })
    try {
      const flow = await fInitApp()
      this.setState({appFlow:flow})
    }
    catch(err) {
      console.log('app err @initComponent')
      console.log(err)
      this.setState({appFlow:'error'})
    }
  }

  //-- @method

  handleInputChange = (event) => {
    const {target:{name, value}} = event
    this.setState({
      [name]:value
    })
  }

  handleModalOnHide() {
    this.setState({
      modalSyncVisible:false,
      modalErrorVisible:false,
      appBusy:false,
      pin:''
    })
  }

  async verifyPin() {
    this.setState({
      appBusy:true,
      modalSyncVisible:false,
      modalErrorVisible:false
    })

    let pin = this.state.pin
    try {
      const success = await fVerifyPin(pin);
      if(success === 'OK') {
        console.log('pin is valid, setting up session')
        sessionStorage.setItem('paragon_session', 'active')

        this.setState({
          appBusy:false,
          appFlow:'default'
        },function() {
          this.setState({modalSyncVisible:true})
          console.log('syncing data')
          this.syncData()
        })
      }
      else {
        this.setState({
          appBusy:false,
          modalErrorVisible:true
        })
      }
    }
    catch(err) {
      this.setState({
        appBusy:false,
        modalErrorVisible:true
      })
    }
  }

  async syncData() {
    console.log('syncing....');
    this.setState({
      dataLoaded:false,
      modalSyncVisible:true
    })

    try {
      const itemfetched = await fSyncItemData();
      if(itemfetched === true) {
        const personsfetched = await fSyncSalesPersonData();
        if(personsfetched === true) {
          this.setState({
            dataLoaded:true
          })
        }
        else {
          this.setState({
            dataLoaded:false,
            modalSyncVisible:false
          },function() {
            alert('Syncing Error.')
          })
        }
      }
      else {
        this.setState({
          dataLoaded:false,
          modalSyncVisible:false
        },function() {
          alert('Syncing Error.')
        })
      }
    }
    catch(err) {
      alert('Syncing Error.')
    }
  }

  //-- @render

  renderSyncProgress() {
    let loaded = this.state.dataLoaded
    if(loaded === true) {
      let items = JSON.parse(sessionStorage.getItem('paragon_item'));
      let person = JSON.parse(sessionStorage.getItem('paragon_salesperson'));
      return (
        <Grid>
          <Row>
            <p>Items and Sales Person data synced</p>
            <p>
              <strong>Items:</strong> {items.length}<br/>
              <strong>Sales Person:</strong> {person.length}
            </p>
          </Row>
        </Grid>
      );
    }
    else {
      return (
        <Grid>
          <Row>
            Please Wait... <i className="fa fa-spinner fa-spin"></i>
          </Row>
        </Grid>
      );
    }
  }

  //-- @output

  render() {
    switch(this.state.appFlow) {
      case 'default':
        return (
          <div>
            <ReportHeader/>
            <Grid>
              <h3>Welcome</h3>
              <hr/>
              <p>
                To get started, click one of the link above to start generate
                reports that you need.
              </p>
              <p>
                <span style={{color:appColor.grey}}>Version 1.0</span>
              </p>
            </Grid>
            <ReportFooter/>
            <Modal show={this.state.modalSyncVisible} onHide={()=>this.handleModalOnHide()}>
              <Modal.Header closeButton>
                <Modal.Title>Syncing Data</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {this.renderSyncProgress()}
              </Modal.Body>
            </Modal>
          </div>
        );

      case 'login':
        return (
          <div>
            <ReportHeader/>
            <Grid>
              <Col md={4}></Col>
              <Col md={4}>
                <Panel>
                  <Panel.Heading>Authentication Required</Panel.Heading>
                  <Panel.Body>
                    <form>
                    <FormGroup>
                      <FormControl
                        name='pin'
                        type='text'
                        value={this.state.pin}
                        placeholder='Enter Authentication Pin'
                        onChange={this.handleInputChange}
                        disabled={this.state.appBusy}
                      />
                      <br/>
                      <Button
                        block={true}
                        bsStyle='primary'
                        disabled={this.state.appBusy}
                        onClick={()=>this.verifyPin()}
                      >
                        Submit
                      </Button>
                    </FormGroup>
                    </form>
                  </Panel.Body>
                </Panel>
              </Col>
              <Col md={4}></Col>
            </Grid>
            <ReportFooter/>
            <Modal show={this.state.modalErrorVisible} onHide={()=>this.handleModalOnHide()}>
              <Modal.Header closeButton>
                <Modal.Title>Notice</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Invalid Pin.
              </Modal.Body>
            </Modal>
          </div>
        );

      case 'loading':
        return (
          <div>
            <ReportHeader/>
            <ReportLoading/>
            <ReportFooter/>
          </div>
        );

      case 'error':
        return (
          <div>
            <ReportHeader/>
            <ReportError/>
            <ReportFooter/>
          </div>
        );

      default:
        return (
          <div>
            <ReportHeader/>
            <p>App flow does not exists.</p>
            <ReportFooter/>
          </div>
        );
    }
  }
}
