import React, { Component } from 'react';
import moment from 'moment';
import {webAPI, appColor} from './Constant';

export function fSyncData() {
  console.log('checking for existing sessionStorage in orders:');
  console.log('paragon_item, paragon_salesperson');
  console.log('remove the values in those sessionStorage first');

  sessionStorage.removeItem('paragon_item');
  sessionStorage.removeItem('paragon_salesperson');

  fSyncItemData();
}

export function fVerifyPin(pin) {
  let api = webAPI + '?method=verifypin&pin=' + pin
  console.log('verifying pin...')
  console.log(api)

  return fetch(api)
  .then((resp) => resp.json())
  .then((resj) => {
    if(resj.success === true) {
      return 'OK';
    }
    else {
      return false;
    }
  })
  .catch((err) => {
    console.log('app err @fVerifyPin')
    console.log(err)
    return false;
  })
}

export function fSyncItemData() {
  let api = webAPI + 'stock.php?method=allitems';
  console.log('syncing allitems');
  console.log(api);

  return fetch(api)
  .then((resp) => resp.json())
  .then((resj) => {
    if(resj.success === true) {
      sessionStorage.setItem('paragon_item', JSON.stringify(resj.data))
      console.log('remote items fetched');
      return true
    }
    else {
      console.log('api err @fSyncItemData')
      return false;
    }
  })
  .catch((err) => {
    console.log('app err @fSyncItemData')
    console.log(err)
    return false;
  })
}

export function fSyncSalesPersonData() {
  let api = webAPI + 'salescom.php?method=salesperson';
  console.log('syncing salesperson');
  console.log(api);

  return fetch(api)
  .then((resp) => resp.json())
  .then((resj) => {
    if(resj.success === true) {
      sessionStorage.setItem('paragon_salesperson', JSON.stringify(resj.data))
      console.log('remote salesperson fetched');
      return true;
    }
    else {
      console.log('api err @fSyncSalesPersonData')
      return false;
    }
  })
  .catch((err) => {
    console.log('app err @fSyncSalesPersonData')
    console.log(err)
    return false;
  })
}

export function fInitApp() {
  console.log('checking connection with the api server...');
  let api = webAPI + '?method=status';
  return fetch(api)
  .then((resp) => resp.json())
  .then((resj) => {
    if(resj.success === true) {
      console.log('api server status ok');
      let session = fCheckSession();
      if(session === true) {
        return 'default';
      }
      else {
        return 'login';
      }
    }
    else {
      console.log('api error @fFetchApiStatus');
      console.log(resj)
      return 'login';
    }
  })
  .catch((err) => {
    console.log('app error @fFetchApiStatus')
    console.log(err)
    return 'error';
  })
}

export function fCheckSession() {
  console.log('check for session in sessionStorage');
  let session = sessionStorage.getItem('paragon_session')
  let payload = [];

  if(session === null) {
    console.log('session not exists');
    return false
  }
  else if(session === 'active') {
    console.log('session is active')
    return true
  }
  else {
    console.log('session seems undefined')
    return false
  }
}

export function fFetchStockReport(params) {
  let api = webAPI + 'stock.php?method=' + params.method
          + '&f=' + moment(params.dateFrom).format('YYYY-MM-DD')
          + '&t=' + moment(params.dateTo).format('YYYY-MM-DD')
          + '&o=' + params.outlet
          + '&name=' + params.itemcode

  console.log('exec fFetchStockReport with api')
  console.log(api)

  let payload = '';

  return fetch(api)
  .then((resp) => resp.json())
  .then((resj) => {
    if(resj.success === true) {
      payload = {success:true, data:resj}
      return payload;
    }
    else {
      console.log('api err fFetchStockReport')
      console.log(resj)
      payload = {success:false, data:null}
      return payload;
    }
  })
  .catch((err) => {
    console.log('app err fFetchStockReport')
    console.log(err)
    payload = {success:false, data:null}
    return payload;
  })
}

export function fFetchSalesComReport(params) {
  let api = webAPI + 'salescom.php?method=' + params.method
          + '&from=' + moment(params.dateFrom).format('YYYY-MM-DD')
          + '&to=' + moment(params.dateTo).format('YYYY-MM-DD')
          + '&name=' + params.name

  console.log('exec fFetchSalesComReport')
  console.log(api);

  let payload = '';

  return fetch(api)
  .then((resp) => resp.json())
  .then((resj) => {
    if(resj.success === true) {
      payload = {success:true, data:resj}
      return payload;
    }
    else {
      console.log('api err fFetchSalesComReport')
      console.log(resj)
      payload = {success:false, data:null}
      return payload;
    }
  })
  .catch((err) => {
    console.log('app err fFetchSalesComReport')
    console.log(err)
    payload = {success:false, data:null}
    return payload;
  })
}

/* @post sample
let api = webAPI + 'stock.php?method=' + params.method

let body = {
  f:moment(params.dateFrom).format('YYYY-MM-DD'),
  t:moment(params.dateTo).format('YYYY-MM-DD'),
  o:params.outlet,
  name:params.itemcode
}

console.log('exec fFetchStockReport with api')
console.log(api)

let payload = '';

return fetch(api, {
  method: 'POST',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({body})
})
.then((resp) => resp.json())
.then((resj) => {
  if(resj.success === true) {
    payload = {success:true, data:resj}
    return payload;
  }
  else {
    console.log('api err fFetchStockReport')
    console.log(resj)
    payload = {success:false, data:null}
    return payload;
  }
})
.catch((err) => {
  console.log('app err fFetchStockReport')
  console.log(err)
  payload = {success:false, data:null}
  return payload;
})
*/
