import React, { Component } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

import {
  Modal,
  Button,
  Grid,
  Col,
  Row,
  FormGroup,
  FormControl,
  ControlLabel
} from 'react-bootstrap';

import {ReportHeader, ReportFooter, ReportLoading, ReportError} from './Component';
import './Reports.css';
import {appColor} from './Constant';
import {fFetchSalesComReport, fInitApp} from './Function';

//---

export class ComReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appFlow:'loading',
      appBusy:false,
      loadingText:'Please wait...',
      modalVisible:false,
      dateFrom:null,
      dateTo:null,
      data:[],
      reportLoaded:false,
      reportLoading:false
    }

    this.setDateFrom = this.setDateFrom.bind(this);
    this.setDateTo = this.setDateTo.bind(this);
  }

  async componentDidMount() {
    await this.initComponent()
  }

  async initComponent() {
    this.setState({ appFlow:'loading' })
    try {
      const flow = await fInitApp()
      this.setState({appFlow:flow})
    }
    catch(err) {
      console.log('app err @initComponent')
      console.log(err)
      this.setState({appFlow:'error'})
    }
  }

  //-- @methods

  setDateFrom(date) {
    let formated = moment(date).format('YYYY-MM-DD')
    this.setState({dateFrom:date})
  }

  setDateTo(date) {
    this.setState({dateTo:date})
  }

  handleModalOnHide() {
    this.setState({
      modalVisible:false,
      appBusy:false,
    })
  }

  reviewData() {
    const {dateFrom, dateTo} = this.state;
    if(dateFrom === null
    || dateFrom === ''
    || dateTo === null
    || dateTo === '') {
      alert("Criteria was not set properly.")
    }
    else {
      this.setState({
        reportLoading:true,
      },function() {
        let params = {
          'dateFrom':dateFrom,
          'dateTo':dateTo,
          'method':'comreport',
          'name':''
        }
        this.fetchReport(params)
      })
    }
  }

  async fetchReport(params) {
    try {
      const payload = await fFetchSalesComReport(params);
      if(payload.success === true) {
        this.setState({
          data:payload.data,
          reportLoading:false,
          reportLoaded:true,
          appFlow:'default'
        })
      }
      else {
        this.setState({
          reportLoading:false,
          appFlow:'error'
        })
      }
    }
    catch(err) {
      console.log('app err @fetchReport')
      console.log(err)
    }
  }

  //-- @render

  renderForm() {
    return (
      <form>
      <Grid>
        <Row>
        <FormGroup>
          <Col md={3}>
            <ControlLabel>Date From</ControlLabel><br/>
            <DatePicker
              selected={this.state.dateFrom}
              onChange={this.setDateFrom}
              className="datepicker-select-repo"
              calendarClassName="datepicker-calendar"
            />
          </Col>
          <Col md={3}>
            <ControlLabel>Date To</ControlLabel><br/>
            <DatePicker
              selected={this.state.dateTo}
              onChange={this.setDateTo}
              className="datepicker-select-repo"
              calendarClassName="datepicker-calendar"
            />
          </Col>
          <Col md={3}>
            <ControlLabel>Submit</ControlLabel><br/>
            <Button
              bsStyle='primary'
              onClick={()=>this.reviewData()}
            >
              Generate Report
            </Button>
          </Col>
        </FormGroup>
        </Row>
      </Grid>
      </form>
    );
  }

  renderItems() {
    let data = this.state.data;
    let items = data.data;
    if(items.length > 0) {
      return items.map((item, index) => {
        return (
          <tr key={index}>
            <td style={{fontSize:14}}>{index+1}</td>
            <td style={{fontSize:14}}>{item.ref}</td>
            <td style={{fontSize:14}}>{item.name}</td>
            <td style={{fontSize:14}}>{item.entries}</td>
            <td style={{fontSize:14}}>{item.total.toFixed(2)}</td>
            <td style={{fontSize:14}}>{item.status}</td>
          </tr>
        );
      })
    }
    else {
      return (
        <tr>
          <td colSpan="8">
          No data found
          </td>
        </tr>
      );
    }
  }

  renderData() {
    let loading = this.state.reportLoading;
    let loaded = this.state.reportLoaded;

    if(loading === false && loaded === false) {
      return (
        <Grid>
          <h3>All Sales Person</h3>
          <hr/>
          <ul>
            <li>To get started, provide date range, <i>From</i> and <i>To</i></li>
            <li>Then, press <i>Submit</i></li>
          </ul>
        </Grid>
      );
    }
    else if(loading === true && loaded === false) {
      return (
        <div align="center" style={{marginTop:100}}>
          <ReportLoading text='Please wait, generating report...'/>
        </div>
      );
    }
    else if(loading === false && loaded === true) {
      let data = this.state.data
      return (
        <div>
          <Grid>
            <h3>All Sales Person</h3>
            <hr/>
            <table className="table table-striped table-condensed">
            <thead>
            <tr>
              <td><strong>#</strong></td>
              <td><strong>Ref</strong></td>
              <td><strong>Name</strong></td>
              <td><strong>Total Receipts</strong></td>
              <td><strong>Total Amount</strong></td>
              <td><strong>Status</strong></td>
            </tr>
            </thead>
            <tbody>
              {this.renderItems()}
            </tbody>
            </table>
          </Grid>
        </div>
      );
    }
  }

  //-- @output

  render() {
    switch(this.state.appFlow) {
      case 'default':
        return (
          <div>
            <ReportHeader/>
            {this.renderForm()}
            {this.renderData()}
            <ReportFooter/>
          </div>
        );

      case 'loading':
        return (
          <div>
            <ReportHeader/>
            <ReportLoading/>
            <ReportFooter/>
          </div>
        );

      case 'error':
        return (
          <div>
            <ReportHeader/>
            <ReportError/>
            <ReportFooter/>
          </div>
        );

      default:
        return (
          <div>
            <ReportHeader/>
            <Grid>
              <p>Session expired. Please <a href="/reports">Re-Login</a></p>
            </Grid>
            <ReportFooter/>
          </div>
        );
    }
  }
}

//------------------------------------------------------------------------------

export class ComReportSingle extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appFlow:'loading',
      appBusy:false,
      loadingText:'Please wait...',
      modalVisible:false,
      dateFrom:null,
      dateTo:null,
      name:'',
      data:[],
      reportLoaded:false,
      reportLoading:false,
      itemSearchValue:null,
      itemSearchLabel:'',
      selectedItem:null
    }

    this.setDateFrom = this.setDateFrom.bind(this);
    this.setDateTo = this.setDateTo.bind(this);
  }

  async componentDidMount() {
    await this.initComponent()
  }

  async initComponent() {
    this.setState({ appFlow:'loading' })
    try {
      const flow = await fInitApp()
      this.setState({appFlow:flow})
    }
    catch(err) {
      console.log('app err @initComponent')
      console.log(err)
      this.setState({appFlow:'error'})
    }
  }

  //-- @methods

  handleInputChange = (event) => {
    const {target:{name, value}} = event
    this.setState({
      [name]:value
    })
  }

  setDateFrom(date) {
    let formated = moment(date).format('YYYY-MM-DD')
    this.setState({dateFrom:date})
  }

  setDateTo(date) {
    this.setState({dateTo:date})
  }

  handleModalOnHide() {
    this.setState({
      modalVisible:false,
      appBusy:false,
    })
  }

  reviewData() {
    const {name, dateFrom, dateTo} = this.state;
    if(name === ''
    || dateFrom === null
    || dateFrom === ''
    || dateTo === null
    || dateTo === '') {
      alert("Criteria was not set properly.")
    }
    else {
      this.setState({
        reportLoading:true,
        reportLoaded:false
      },function() {
        this.setState({data:[]})
        let params = {
          'dateFrom':dateFrom,
          'dateTo':dateTo,
          'method':'comreportsingle',
          'name':name
        }
        this.fetchReport(params)
      })
    }
  }

  async fetchReport(params) {
    try {
      const payload = await fFetchSalesComReport(params);
      if(payload.success === true) {
        //console.log('payload received');
        //console.log(payload);

        this.setState({
          data:payload.data.data[0],
        },function() {
          this.setState({
            reportLoading:false,
            reportLoaded:true,
            appFlow:'default'
          })
        })
      }
      else {
        this.setState({
          reportLoading:false,
          appFlow:'error'
        })
      }
    }
    catch(err) {
      console.log('app err @fetchReport')
      console.log(err)
    }
  }

  //-- @render

  loadItemOption = (inputValue, callback) => {
    setTimeout(() => {
      callback(this.filterItem(inputValue));
    }, 1000);
  }

  filterItem = (inputValue: string) => {
    let found = JSON.parse(sessionStorage.getItem('paragon_salesperson')).filter(i =>
			i.label.toLowerCase().includes(inputValue.toLowerCase())
		)
		return found;
  }

  handleAsyncSelectItem = (newValue: string) => {
		this.setState({
			itemSearchValue: newValue
		},function(){
			return this.state.itemSearchValue
		})
	}

  handleChangeSelectItem = (selectedItem) => {
    this.setState({
      name:`${selectedItem.value}`,
      itemSearchValue:`${selectedItem.label}`,
      selectedItem:selectedItem,
    },function() {
      console.log('selected item:')
      console.log(this.state.itemcode)
      console.log('selected label')
      console.log(this.state.itemSearchValue)
    })
  }

  renderForm() {
    return (
      <form>
      <Grid>
        <FormGroup>
          <Row>
            <Col md={12}>
              <ControlLabel>Person Name</ControlLabel><br/>
              <AsyncSelect
                ref='asyncItem'
                value={this.state.selectedItem}
                loadOptions={this.loadItemOption}
                onInputChange={this.handleAsyncSelectItem}
                onChange={this.handleChangeSelectItem}
                placeholder='Type sales person name'
                autoFocus={true}
              />
            </Col>
          </Row>
          <br/>
          <Row>
          <Col md={3}>
            <ControlLabel>Date From</ControlLabel><br/>
            <DatePicker
              selected={this.state.dateFrom}
              onChange={this.setDateFrom}
              className="datepicker-select-repo"
              calendarClassName="datepicker-calendar"
            />
          </Col>
          <Col md={3}>
            <ControlLabel>Date To</ControlLabel><br/>
            <DatePicker
              selected={this.state.dateTo}
              onChange={this.setDateTo}
              className="datepicker-select-repo"
              calendarClassName="datepicker-calendar"
            />
          </Col>
          <Col md={3}>
            <ControlLabel>Submit</ControlLabel><br/>
            <Button
              bsStyle='primary'
              onClick={()=>this.reviewData()}
            >
              Generate Report
            </Button>
          </Col>
          </Row>
        </FormGroup>
      </Grid>
      </form>
    );
  }

  renderItems() {
    let data = this.state.data;
    let items = data.entries;
    if(items.length > 0) {
      return items.map((item, index) => {
        return (
          <tr key={index}>
            <td style={{fontSize:14}}>{index+1}</td>
            <td style={{fontSize:14}}>{item.receipt}</td>
            <td style={{fontSize:14}}>{parseFloat(item.value).toFixed(2)}</td>
            <td style={{fontSize:14}}>{item.datetime}</td>
            <td style={{fontSize:14}}>{item.cashier}</td>
            <td style={{fontSize:14}}>{item.outlet}</td>
          </tr>
        );
      })
    }
    else {
      return (
        <tr>
          <td colSpan="9">
          No data found
          </td>
        </tr>
      );
    }
  }

  renderData() {
    let loading = this.state.reportLoading;
    let loaded = this.state.reportLoaded;

    if(loading === false && loaded === false) {
      return (
        <Grid>
          <h3>Search By Person</h3>
          <hr/>
          <ul>
            <li>Provide the <i>Person Name</i></li>
            <li>Pick a <i>From Date</i> to <i>To Date</i></li>
            <li>Press <i>Generate Report</i> button.</li>
          </ul>
        </Grid>
      );
    }
    else if(loading === true && loaded === false) {
      return (
        <div align="center" style={{marginTop:100}}>
          <ReportLoading text='Please wait, generating report...'/>
        </div>
      );
    }
    else if(loading === false && loaded === true) {
      let data = this.state.data;
      //console.log(data)
      return (
        <div>
          <Grid>
          <h3>Search By Person</h3><hr/>
          <div>
            <strong>Name:</strong> {data.person.person}<br/>
            <strong>Total Receipts:</strong> {data.entries.length}<br/>
            <strong>Total Amount:</strong> {parseFloat(data.person.total).toFixed(2)}<br/>
          </div>
          <br/>
          <table className="table table-striped table-condensed">
          <thead>
          <tr>
            <td><strong>#</strong></td>
            <td><strong>Receipt</strong></td>
            <td><strong>Amount</strong></td>
            <td><strong>Date & Time</strong></td>
            <td><strong>Cashier</strong></td>
            <td><strong>Outlet</strong></td>
          </tr>
          </thead>
          <tbody>
            {this.renderItems()}
          </tbody>
          </table>
          </Grid>
        </div>
      );
    }
  }

  //-- @output

  render() {
    switch(this.state.appFlow) {
      case 'default':
        return (
          <div>
            <ReportHeader/>
            {this.renderForm()}
            {this.renderData()}
            <ReportFooter/>
          </div>
        );

      case 'loading':
        return (
          <div>
            <ReportHeader/>
            <ReportLoading/>
          </div>
        );

      case 'error':
        return (
          <div>
            <ReportHeader/>
            <ReportError/>
          </div>
        );

      default:
        return (
          <div>
            <ReportHeader/>
            <Grid>
              <p>Session expired. Please <a href="/reports">Re-Login</a></p>
            </Grid>
          </div>
        );
    }
  }
}
