import React, { Component } from 'react';
import moment from 'moment';
import './App.css';

//------------------------------------------------------------------------------

export default class PrintReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appFlow: 'loading',
      items: [],
      company: '',
      cashier: '',
      date: '',
      outlet: '',
      cash: 0.00,
			ret_cash: 0.00,
      creditcard: 0.00,
			ret_creditcard: 0.00,
      epayment: 0.00,
			ret_epayment: 0.00,
      boost:0.00,
      quinpay:0.00,
      splus:0.00,
      cheque: 0.00,
      total: 0.00,
			returns: 0.00,
      ready: false,
    }
  }

  componentDidMount() {
    this.setState({
      items: this.props.items,
      total: this.props.sum,
			returns: this.props.returns,
      cash: this.props.cash,
			ret_cash: this.props.ret_cash,
      creditcard: this.props.creditcard,
			ret_creditcard: this.props.ret_creditcard,
      epayment: this.props.epayment,
			ret_epayment: this.props.ret_epayment,
      boost:this.props.boost,
      quinpay:this.props.boost,
      splus:this.props.boost,
      cheque: this.props.cheque,
      cashier: this.props.cashier,
      company: this.props.company,
      outlet: this.props.outlet,
      date: this.props.date,
      sumQty: 0,
      sumTotal: 0.00
    },function() {
      let items = this.state.items;
      let qtys = [];
			let qty = 0;
      let add = (a,b) => a + b;

			if(items.length > 0) {
				for(let i=0;i<items.length;i++) {
	        qtys.push(items[i].qty);
	      }
	      qty = qtys.reduce(add);
			}

      this.setState({
        appFlow:'default',
        ready: true,
        sumQty: qty
      })
        //console.log('items @PrintReport');
    });
  }

  mapItems() {
    if(this.state.ready === true) {
      return this.state.items.map( (item, index) => {
        return (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{item.code} - {item.name}</td>
            <td align="center">{item.qty}</td>
            <td align="right">{(item.rate*item.qty).toFixed(2)}</td>
          </tr>
        )
      })
    }
  }

  render() {
    switch(this.state.appFlow) {
        case 'default':
            return (
                <div id="app-pos-modal">
                    <div className="receipt">

                        <table className="table">
                        <tbody>
                        <tr>
                            <td align="center" style={{border:'none'}}>
                                {/*<label>{this.state.company}</label><br/>*/}
                                Cashier: {this.state.cashier}<br/>
                                Date: {this.state.date}<br/>
																Time: {moment().format('h:mm:ss')}<br/>
                                Outlet: {this.state.outlet === 'Luyang 2' ? 'Likas' : this.state.outlet}
                            </td>
                        </tr>
                        </tbody>
                        </table>

                        <table className="table table-striped">
                        <thead>
                        <tr>
                            <td><label>#</label></td>
                            <td><label>Item</label></td>
                            <td align="center"><label>Qty</label></td>
                            <td align="right"><label>Amount</label></td>
                        </tr>
                        </thead>
                        <tbody>
                        {this.mapItems()}
                        <tr>
                            <td></td>
                            <td></td>
                            <td align="center"><label>{this.state.sumQty}</label></td>
                            <td align="right"></td>
                        </tr>
                        </tbody>
                        </table>

												<table className="table sale-sum-tbl">
												<tbody>
												<tr>
														<td>
																<strong>Cash Sales</strong><br/>
                                Cash Returns<br/><br/>

																<strong>Credit Card Sales</strong><br/>
                                Credit Card Returns<br/><br/>

																<strong>E-Payment Sales</strong><br/>
                                <strong>Boost Sales</strong><br/>
                                E-Payment Returns (+Boost)<br/><br/>

																<strong>Cheque Sales</strong><br/><br/>
														</td>
														<td style={{textAlign:'right'}}>
																<strong>{(this.state.cash).toFixed(2)}</strong><br/>
                                {this.state.ret_cash.toFixed(2)}<br/><br/>

																<strong>{(this.state.creditcard+this.state.ret_creditcard).toFixed(2)}</strong><br/>
                                {this.state.ret_creditcard.toFixed(2)}<br/><br/>

																<strong>{(this.state.epayment+this.state.ret_epayment).toFixed(2)}</strong><br/>
                                <strong>{this.state.boost.toFixed(2)}</strong><br/>
                                {this.state.ret_epayment.toFixed(2)}<br/><br/>

																<strong>{this.state.cheque.toFixed(2)}</strong><br/><br/>
														</td>
												</tr>
												</tbody>
												</table>

												<table className="table sale-sum-tbl">
                        <tbody>
                        <tr>
                            <td>
                                <label>Total Sales</label><br/>
                            </td>
                            <td style={{textAlign:'right'}}>
                                <label>
																{
																	(parseFloat(this.state.cash)
																	+ parseFloat(this.state.creditcard)
																	+ parseFloat(this.state.boost)
																	+ parseFloat(this.state.cheque)
																	+ parseFloat(this.state.epayment)).toFixed(2)
																}
																</label><br/>
                            </td>
                        </tr>
                        </tbody>
                        </table>

                    </div>
                </div>
            )

      case 'loading':
        return (
          <div id="app-pos-modal">
            <div align="center">
              Generating Report...
              <i className="fa fa-circle-o-notch fa-spin fa-fw app-spinner-color"></i>
            </div>
          </div>
        )

      default:
        return (
          <div id="app-pos-modal">
            <div align="center">
              ERROR: Unable to print report. Please check your internet connection.
            </div>
          </div>
        )
    }
  }
}
