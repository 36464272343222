import React from 'react';
import ReactDOM from 'react-dom';
import Main from './Main.js';
//import registerServiceWorker from './registerServiceWorker';
import 'font-awesome/css/font-awesome.min.css';

if(window.navigator && navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations()
  .then(function(registrations) {
    for(let registration of registrations) {
      registration.unregister();
    }
  });
}

ReactDOM.render(<Main />, document.getElementById('app'));
//registerServiceWorker();
