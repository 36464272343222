import React, { Component } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

import {Grid, Row, Modal, Nav, Navbar, Button, NavbarForm, NavDropdown, MenuItem} from 'react-bootstrap'

import './Reports.css';
import {appColor} from './Constant.js';
import {fSyncItemData, fSyncSalesPersonData} from './Function';

//---

export class ReportFooter extends Component {
  render() {
    return (
      <div style={{marginTop:100}}>
        <Grid>
          <hr/>
          <div className="pull-right">
            <p>Version 1.0</p>
          </div>
        </Grid>
      </div>
    );
  }
}

export class ReportHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      subtitle:'',
      dataLoaded:false,
      modalVisible:false,
      loggedIn:false
    }
  }

  componentDidMount() {
    let subtitle = this.props.subtitle;
    let session = sessionStorage.getItem('paragon_session');
    if(session === 'active') {
      this.setState({loggedIn:true},function(){
        if(subtitle != undefined) {
          this.setState({
            subtitle:subtitle
          })
        }
      })
    }
    else {
      if(subtitle != undefined) {
        this.setState({
          subtitle:subtitle
        })
      }
    }
  }

  //-- @methods

  handleModalOnHide() {
    this.setState({
      modalVisible:false
    })
  }

  async syncData() {
    console.log('syncing....');
    this.setState({
      dataLoaded:false,
      modalVisible:true
    })

    try {
      const itemfetched = await fSyncItemData();
      if(itemfetched === true) {
        const personsfetched = await fSyncSalesPersonData();
        if(personsfetched === true) {
          this.setState({
            dataLoaded:true
          })
        }
        else {
          this.setState({
            dataLoaded:false,
            modalVisible:false
          },function() {
            alert('Syncing Error.')
          })
        }
      }
      else {
        this.setState({
          dataLoaded:false,
          modalVisible:false
        },function() {
          alert('Syncing Error.')
        })
      }
    }
    catch(err) {
      alert('Syncing Error.')
    }
  }

  //-- @render

  renderSyncProgress() {
    let loaded = this.state.dataLoaded
    if(loaded === true) {
      let items = JSON.parse(sessionStorage.getItem('paragon_item'));
      let person = JSON.parse(sessionStorage.getItem('paragon_salesperson'));
      return (
        <Grid>
          <Row>
            <p>Items and Sales Person data synced</p>
            <p>
              <strong>Items:</strong> {items.length}<br/>
              <strong>Sales Person:</strong> {person.length}
            </p>
          </Row>
        </Grid>
      );
    }
    else {
      return (
        <Grid>
          <Row>
            Please Wait... <i className="fa fa-spinner fa-spin"></i>
          </Row>
        </Grid>
      );
    }
  }

  renderSyncButton() {
    if(this.state.loggedIn === true) {
      return (
        <Nav
          navbar={true}
          pullRight={true}
        >
        <React.Fragment>
        <Navbar.Form pullRight>
          <Button
            type="button"
            bsStyle='success'
            onClick={()=>this.syncData()}
          >
            <i className="fa fa-refresh" aria-hidden="true"></i> Sync Data
          </Button>
        </Navbar.Form>
        </React.Fragment>
        </Nav>
      );
    }
  }

  //-- @output

  render() {
    return (
      <div>
      <Navbar>
        <Navbar.Header>
          <Navbar.Brand>
            <span style={{color:'#333'}}>Paragon Pets Reports</span>
          </Navbar.Brand>
        </Navbar.Header>
        <Nav
          navbar={true}
          pullRight={false}
        >
          <NavDropdown eventKey={1} title="Stocks" id="basic-nav-dropdown">
            <MenuItem eventKey={1.1} href="/stock-card">Stock Card Movement</MenuItem>
            <MenuItem eventKey={1.2} href="/no-movement-stock">No Movement Stock</MenuItem>
          </NavDropdown>
          {
            /*
            <NavDropdown eventKey={2} title="Sales Commissions" id="basic-nav-dropdown">
              <MenuItem eventKey={2.1} href="/commission-report-overview">All Sales Person</MenuItem>
              <MenuItem eventKey={2.2} href="/commission-report-single">Search By Person</MenuItem>
            </NavDropdown>
            */
          }
        </Nav>
        {this.renderSyncButton()}
      </Navbar>
      <Modal show={this.state.modalVisible} onHide={()=>this.handleModalOnHide()}>
        <Modal.Header closeButton>
          <Modal.Title>Syncing Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderSyncProgress()}
        </Modal.Body>
      </Modal>
      </div>
    );
  }
}

export class ReportLoading extends Component {
  constructor(props) {
    super(props)
    this.state = {text:'Please Wait...'}
  }

  componentDidMount() {
    let text = this.props.text;
    if(text !== undefined) {
      this.setState({text:text})
    }
  }

  //---

  render() {
    return (
      <div align="center">
        <p>{this.state.text}</p>
        <i className="fa fa-spinner fa-spin fa-2x"></i>
      </div>
    );
  }
}

export class ReportError extends Component {
  constructor(props) {
    super(props)
    this.state = {text:'Make sure you have an active internet connection to use this app.'}
  }

  componentDidMount() {
    let text = this.props.text;
    if(text !== undefined) {
      this.setState({text:text})
    }
  }

  //---

  render() {
    return (
      <Grid>
        <h3>Application Error or Timed Out</h3>
        <hr/>
        <p>{this.state.text}</p>
      </Grid>
    );
  }
}
