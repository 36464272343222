import React from "react";
import { Route } from "react-router-dom";

import App from './App.js';
import Report from './Report.js';

import Reports from './reports/Reports.js';
import {LazyStock, StockCard} from './reports/StockReports.js';
import {ComReport, ComReportSingle} from './reports/ComReports.js';

const AppRouter = () => (
    <div>
        <Route path="/" exact={true} component={ App } />
        <Route path="/report" component={ Report } />
        <Route path="/reports" component={ Reports } />
        <Route path="/no-movement-stock" component={ LazyStock } />
        <Route path="/stock-card" component={ StockCard } />
        <Route path="/commission-report-overview" component={ ComReport } />
        <Route path="/commission-report-single" component={ ComReportSingle } />
    </div>
);
export default AppRouter;
