import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom'

import AppRouter from './Router.js';
//import AppNavigator from './Navigator.js';

import './App.css';

//------------------------------------------------------------------------------

export default class Main extends Component {
    render() {
        return (
            <Router>
                <AppRouter />
            </Router>
        )
    }
}
